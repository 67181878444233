/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { BsChevronDown } from "react-icons/bs";
import SubmittedModal from "./submitted-form";
import { toast } from "react-toastify";
import { sendSupportRequest } from "redux/actionCreators/auth";


const ContactSupportModal = (props: any) => {
  const [confirmPop, setConfirmPop] = useState(false);
  const [isdropdown, setIsdropdown] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const [issueType, setIssueType] = useState(null);
  const [description, setDescription] = useState(null);
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [imageType, setImageType] = useState(null);
  const [imageName, setImageName] = useState(null);


  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const init = async () => {
      // check if video already included in playlist
    };

    init();
  }, [props]);

  const handlePop = async () => {

      if(description === null || issueType === null) {

         toast.error('Please fill the required fields')

         return;
      }
    
      setSubmitting(true);

      const request = await sendSupportRequest({
         image,
         imageType,
         description,
         issueType,
         imageName
      });

      setFile(null);
      setImageType(null);
      setImage(null)
      setDescription(null);
      setIssueType(null);
      setImageName(null);

      setSubmitting(false);

      props.setContactSupport(false);
      setConfirmPop(true);
  };

  // ref
  const inputRef = React.useRef(null);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // handleFiles(e.dataTransfer.files);

      const file = e.target.files[0];

      toData(file);
    }
  };

  // triggers when file is selected with click
  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      // handleFiles(e.target.files);

      const file = e.target.files[0];

      toData(file);
    }
  };

  const toData = (file) => {
    console.log(file);

    if (
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "image/png"
    ) {
      setImageType(file.type);
      setImageName(file.name);

      setFile(file);

      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        setImage(reader.result);
      };
    } else {
      toast.error("Only JPG or Png files allowed");
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };
  const issues = ["Technical Issue", "Account Enquiry", "Production Request"];
  return (
    <>
      <SubmittedModal confirmPop={confirmPop} setConfirmPop={setConfirmPop} />
      <React.Fragment>
        <>
          <Modal
            isOpen={props.contactSupport}
            onRequestClose={() => props.setContactSupport(false)}
            style={{
              overlay: {
                position: "relative",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.75)",
                overflow: "hidden",
              },
            }}
          >
            <div>
              <div className="z-modal animate-fade-in bg-bg900 fixed left-0 top-0 flex flex-col items-stretch w-full h-screen bg-opacity-90 overflow-y-auto">
                <div className="flex flex-grow items-center justify-center py-4 w-full h-contact">
                  <div
                    role="dialog"
                    tabIndex={-1}
                    data-ismodal="true"
                    className="focus:outline-none"
                    style={{ width: 598 }}
                  >
                    <div className="bg-bg100 rounded-4xl relative py-2 px-4 flex flex-col ">
                      <div className="flex items-center justify-between w-full pb-2">
                        <p className="v-bs-h400 newColor">Contact Support</p>
                        <button
                          className=" lt-item-icon-box lt-item-box-sizing  "
                          type="button"
                          onClick={() => props.setContactSupport(false)}
                        >
                          <span className="flex items-center gap-4px">
                            <svg
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              xmlns="http://www.w3.org/2000/svg"
                              aria-hidden="true"
                              focusable="false"
                              role="presentation"
                              className="index-StyledSvg_cls2__nHIIG index-StyledSvg_cls1__3YcLt"
                              style={{ width: 24, height: 24 }}
                              width={24}
                              height={24}
                            >
                              <defs>
                                <symbol id="close" viewBox="0 0 24 24">
                                  <path d="M19.707 5.707a1 1 0 0 0-1.414-1.414L12 10.586 5.707 4.293a1 1 0 1 0-1.414 1.414L10.586 12l-6.293 6.292a1 1 0 1 0 1.414 1.415L12 13.414l6.293 6.293a1 1 0 0 0 1.414-1.415L13.414 12l6.293-6.293z" />
                                </symbol>
                              </defs>
                              <use xlinkHref="#close" fill="#222" />
                            </svg>
                          </span>
                        </button>
                      </div>
                      <p
                        className="text-text800 text-sm "
                        style={{ color: "#475467" }}
                      >
                        We're here to assist you every step of the way. If you
                        have any questions, concerns, or issues, our dedicated
                        support team is ready to help. Getting in touch with us
                        is quick and easy. Here’s how you can contact our
                        support team for assistance:
                      </p>
                      <p className="contact-label-title mt-3 ">Type of Issue</p>
                      <div className="relative flex team-field-control my-2 justify-between align-center cursor-pointer"  
                      onClick={() => setIsdropdown((prev) => !prev)}
                      >
                        <p className="contact-text">{issueType ? issueType : "Issue"}</p>
                        <button
                          type="button"
                          className="flex align-center gap-1 justify-center">
                          <p className=" flex items-center gap-4px choose-text">
                            Choose
                          </p>
                          <BsChevronDown className="iconChoose bg-900" />
                        </button>
                      </div>
                      <div className="col-span-full relative">
                        {/* textarea */}
                        <label
                          htmlFor="about"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          <p className="contact-label-title mt-3 ">Describe</p>
                        </label>
                        <div className="mt-1">
                          <textarea
                            style={{}}
                            id="about"
                            name="about"
                            rows={5}
                            cols={40}
                            placeholder="Enter comment here"
                            className="help_textarea"
                            onChange={(e: any) => setDescription(e.target.value)}
                          ></textarea>
                        </div>
                        {/* choose file */}
                        <form
                          id="form-file-upload"
                          onDragEnter={handleDrag}
                          onSubmit={(e) => e.preventDefault()}
                        >
                          <input
                            ref={inputRef}
                            type="file"
                            id="input-file-upload"
                            multiple={false}
                            onChange={handleChange}
                          />
                          <label
                            id="label-file-upload"
                            htmlFor="input-file-upload"
                            className={dragActive ? "drag-active" : ""}
                          >
                            <div>
                              {file ? (
                                <>
                                  <p
                                    className="text-text800 text-sm "
                                    style={{ color: "#475467" }}
                                  >
                                    {file.name}
                                  </p>
                                </>
                              ) : (
                                <>
                                  <p
                                    className="text-text800 text-sm "
                                    style={{ color: "#475467" }}
                                  >
                                    Drop a file to add or
                                    <button
                                      className="upload-button"
                                      onClick={onButtonClick}
                                      style={{
                                        fontWeight: "700",
                                        color: "#000",
                                      }}
                                    >
                                      Choose file
                                    </button>
                                    (optional)
                                  </p>
                                </>
                              )}
                            </div>
                          </label>
                          {dragActive && (
                            <div
                              id="drag-file-element"
                              onDragEnter={handleDrag}
                              onDragLeave={handleDrag}
                              onDragOver={handleDrag}
                              onDrop={handleDrop}
                            ></div>
                          )}
                        </form>
                        {/* <div className="col-span-full">
                          <div className="mt-2 flex justify-center rounded-lg  border-dashed border-2 border-900/25 px-6 py-5">
                         
                            <div className="text-center">
                              <div className=" flex text-sm leading-6 text-gray-600">
                                <p className="pl-1">
                                  Drop a file to add or
                                  <label
                                    htmlFor="file-upload"
                                    className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                  >
                                    <span> Choose File </span>
                                    <input
                                      id="file-upload"
                                      name="file-upload"
                                      type="file"
                                      className="sr-only"
                                    />
                                  </label>
                                  (optional)
                                </p>
                              </div>
                            </div>
                          </div>
                        </div> */}

                        <ul
                          className="dropdown_helper absolute top-0"
                          style={{ display: isdropdown ? "block" : "none" }}
                        >
                          <h4>Select type of issue</h4>
                          {issues?.map((item, index) => {
                            return <li key={index} className="cursor-pointer" onClick={() => {
                              setIssueType(item);
                              setIsdropdown(false);
                            }}>{item}</li>;
                          })}
                        </ul>
                      </div>

                      <button
                        type="button"
                        disabled={submitting}
                        className="update-card-vs-bs-btn self-center d-block mt-3 w-full "
                        onClick={handlePop}
                      >
                        <span className=" flex items-center gap-4px">
                          {submitting ? (
                            <>

                              <svg
                                className=" text-center w-3 h-3 mr-3 -ml-1 text-white animate-spin"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  stroke-width="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                            </>
                          ) : (
                            <>
                              Submit
                            </>
                          )}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </>
      </React.Fragment>
    </>
  );
};

export default ContactSupportModal;
