/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from 'react';
import PracticeTheme from 'components/theme';
import CardIcon from 'assets/img/card-shape.png';
import VisaIcon from 'assets/img/visa-black.png';
import VisaIconWhite from 'assets/img/visa-white.png';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import DetaultProfile from 'assets/img/team-member-profile.png';
import LoadingOverlay from 'react-loading-overlay';

import UpdateCardModal from 'components/modals/update-card';
import ApplyCouponModal from 'components/modals/apply-coupon';
import { ToastContainer, toast } from 'react-toastify';
import InvoiceModal from 'components/modals/invoice';
import PromptModal from 'components/modals/prompt';

import moment from 'moment';

import {
  getPracticeDetail,
  updateAddress,
  updatePracticeBasicInfo,
  updatePracticeContactInfo,
  uploadPracticeLogo,
} from 'redux/actionCreators/practice';
import { FileUploader } from 'react-drag-drop-files';
import Cookies from 'js-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  changePassword as changeUserAccountPassword,
  getUserPaymentInfo,
  resumeSubscription,
  subApplyCoupon,
} from 'redux/actionCreators/auth';
import NewTeamMemberModal from 'components/modals/new-team-member';
import SuspendSubscription from 'components/modals/suspend-subscription';
import UploadLogoModal from 'components/modals/upload-logo';
import ConnectDeviceModal from 'components/modals/connect-device-modal';
import { getAnncouncements } from 'services/user';
import AnnouncementModal from 'components/modals/announcement';
import PlaylistCard from 'components/dashboard/playlist-card';
import AttentionCard from 'components/dashboard/needs-attention';
import Announcements from 'components/dashboard/announcements';
import xmark from '../../assets/img/xmark.svg';
import masterIcon from '../../assets/img/mastercard.png';
import { supportImages } from 'util/helper';
import ContactSupportModal from 'components/modals/contact-support';
import SubmittedModal from 'components/modals/submitted-form';
import { ModalType, useModalActions } from 'components';
import {
  MemberDetails,
  MemberType,
  useMembersAction,
} from 'features/team-members';
import { OtherPhotos, UploadLogo } from 'features/other-photos';

interface RootState {
  auth: any;
  practice: any;
  videos: any;
}

const PracticeDashboard = () => {
  const state = useSelector((state: RootState) => state);

  console.log(' ==== STATE ==== ');

  console.log(state);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const fileTypes = ['JPG', 'PNG'];

  const [loading, setLoading] = useState(false);

  const [invoice, setInvoice] = useState(false);

  const [cancelDiscount, setCancelDiscount] = useState(false);

  const [suspendSubscription, setSuspendSubscription] = useState(false);

  const [invoiceData, setInvoiceData] = useState(null);

  const [openUploadLogoModal, setUploadLogoModal] = useState(false);

  const [openConnectDeviceModal, setConnectDeviceModal] = useState(false);

  const dispatch = useDispatch();

  const [practice]: any = useState([]);

  const [updatePassword, setUpdatePassword] = useState(false);

  const [editCard, setEditCard] = useState(false);

  const [applyCouponModal, setApplyCouponModal] = useState(false);

  const [confirmResume, setConfirmResume] = useState(false);

  const [announcements, setAnnouncements] = useState([]);

  const [announcement] = useState({});

  const [contactSupport, setContactSupport] = useState(false);

  const [confirmPop, setConfirmPop] = useState(false);

  const [loadingAnnouncements, setLoadingAnnouncements] = useState(false);

  const handleChange = async (file) => {
    setLoading(true);
    await uploadPracticeLogo(file);
    dispatch(getPracticeDetail());
    setLoading(false);
  };

  const [edit, setEdit] = useState(false);
  const [resuming, setResuming] = useState(false);
  const [teams, setTeams] = useState(true);
  const [library, setLibrary] = useState(false);
  const [changePassword, setChangePassword] = useState(false);

  const [editBasicInfo, setBasicInfo] = useState(false);

  const [contactTitle, setContactTitle] = useState(null);

  const [subTitle, setSubTitle] = useState(null);

  const [openAnnouncementModal, setAnnouncementModal] = useState(false);

  const removeDiscount = async () => {
    setCancelDiscount(false);

    await subApplyCoupon({
      subscription_id: state.auth.payment.subscription.id,
      coupon_id: '',
    });

    dispatch(
      getUserPaymentInfo({
        customer_id: state?.practice?.detail?.StripeID,
      })
    );

    toast.success(`Coupon cancelled successfully.`);
  };

  const [editContactInfo, setContactInfo] = useState(false);
  const [editPhysicalAddressInfo, setPhysicalAddressInfo] = useState(false);
  const [editPostalAddressInfo, setPostalAddressInfo] = useState(false);

  const [newTeamMemberModal, setNewTeamMemberModal] = useState(false);

  const [Initial_values_basic_info, setInitialBasicInfo] = useState({
    user_title: state?.practice?.detail?.SubscriberTitle || 'Mr',
    user_first_name: state?.practice?.detail?.SubscriberFirstName || '',
    user_last_name: state?.practice?.detail?.SubscriberLastName || '',
    user_position: state?.practice?.detail?.SubscriberPosition || '',
    practice_name: state?.practice?.detail?.PracticeName || '',
    practice_email: state?.practice?.detail?.Email || '',
    user_phone: state?.practice?.detail?.Telephone || '',
    corp_name: state?.practice?.detail?.CorporateName || '',
  });

  const [initial_contact_info_data, setInitialContactInfo] = useState({
    contact_title: state?.practice?.detail?.ContactTitle || '',
    contact_first_name: state?.practice?.detail?.ContactFirstName || '',
    contact_last_name: state?.practice?.detail?.ContactLastName || '',
    contact_email: state?.practice?.detail?.ContactEmail || '',
    contact_phone: state?.practice?.detail?.ContactTelephone || '',
  });

  const updateBasicInfo = async (values: any) => {
    setInitialBasicInfo({
      user_title: subTitle || Initial_values_basic_info.user_title,
      user_first_name: values.user_first_name || '-',
      user_last_name: values.user_last_name || '-',
      user_position: values.user_position || '-',
      practice_name: values.practice_name || '-',
      practice_email: values.practice_email || '-',
      user_phone: values.user_phone || '-',
      corp_name: values.corp_name || '-',
    });

    setBasicInfo(false);

    await updatePracticeBasicInfo(
      {
        subscriber_title: subTitle || Initial_values_basic_info.user_title,
        subscriber_first_name: values.user_first_name || '-',
        subscriber_last_name: values.user_last_name || '-',
        subscriber_position: values.user_position || '-',
        email: values.practice_email || '-',
        telephone: values.user_phone || '-',
        corp_name: values.corp_name || '-',
        practice_name: values.practice_name || '-',
      },
      practice?.ID
    );

    dispatch(getPracticeDetail());
  };
  const updateContactInfo = async (values: any) => {
    setContactInfo(false);

    setInitialContactInfo({
      contact_title: contactTitle || initial_contact_info_data.contact_title,
      contact_first_name: values.contact_first_name || '-',
      contact_last_name: values.contact_last_name || '-',
      contact_email: values.contact_email || '-',
      contact_phone: values.contact_phone || '-',
    });

    await updatePracticeContactInfo(
      {
        contact_title: contactTitle || initial_contact_info_data.contact_title,
        contact_first_name: values.contact_first_name || '-',
        contact_last_name: values.contact_last_name || '-',
        contact_email: values.contact_email || '-',
        contact_telephone: values.contact_phone || '-',
      },
      practice?.ID
    );
  };

  useEffect(() => {
    setInitialBasicInfo({
      user_title: state?.practice?.detail?.SubscriberTitle || 'Mr',
      user_first_name: state?.practice?.detail?.SubscriberFirstName || '',
      user_last_name: state?.practice?.detail?.SubscriberLastName || '',
      user_position: state?.practice?.detail?.SubscriberPosition || '',
      practice_name: state?.practice?.detail?.PracticeName || '',
      practice_email: state?.practice?.detail?.Email || '',
      user_phone: state?.practice?.detail?.Telephone || '',
      corp_name: state?.practice?.detail?.CorporateName || '',
    });

    setInitialContactInfo({
      contact_title: state?.practice?.detail?.ContactTitle || '',
      contact_first_name: state?.practice?.detail?.ContactFirstName || '',
      contact_last_name: state?.practice?.detail?.ContactLastName || '',
      contact_email: state?.practice?.detail?.ContactEmail || '',
      contact_phone: state?.practice?.detail?.ContactTelephone || '',
    });

    setInitialPhysicalAddress({
      country_1: state?.practice?.physical_address?.CountryName || '',
      zipcode_1: state?.practice?.physical_address?.Postcode || '',
      state_1: state?.practice?.physical_address?.State || '',
      address_1: state?.practice?.physical_address?.Street2 || '',
      city_1: state?.practice?.physical_address?.Suburb || '',
      street_1: state?.practice?.physical_address?.Street1 || '',
    });
  }, [state]);

  const resumeSubscriptionRequest = async () => {
    setResuming(true);

    const sub = await resumeSubscription({
      subscription_id: state.auth.payment.subscription.id,
    });

    setConfirmResume(false);

    setResuming(false);

    if (sub.data.error) {
      toast.error(sub.data.message);

      return;
    } else {
      dispatch(
        getUserPaymentInfo({
          customer_id: state?.practice?.detail?.StripeID,
        })
      );

      toast.success(`Subscription resumed successfully.`);
    }
  };

  const [physical_address_data, setInitialPhysicalAddress] = useState({
    country_1: state?.practice?.physical_address?.CountryName || '',
    zipcode_1: state?.practice?.physical_address?.Postcode || '',
    state_1: state?.practice?.physical_address?.State || '',
    address_1: state?.practice?.physical_address?.Street2 || '',
    city_1: state?.practice?.physical_address?.Suburb || '',
    street_1: state?.practice?.physical_address?.Street1 || '',
  });

  const [postal_address_data, setInitialPostalAddress] = useState({
    country_2: state?.practice?.postal_address?.CountryName,
    zipcode_2: state?.practice?.postal_address?.Postcode,
    state_2: state?.practice?.postal_address?.State,
    address_2: state?.practice?.postal_address?.Street2,
    city_2: state?.practice?.postal_address?.Suburb,
    street_2: state?.practice?.postal_address?.Street1,
  });

  const updatePhysicalAddress = async (values: any) => {
    setPhysicalAddressInfo(false);

    setInitialPhysicalAddress({
      ...values,
    });

    await updateAddress(
      {
        street2: values.address_1 || '-',
        street1: values.street_1 || '-',
        state: values.state_1 || '-',
        city: values.city_1 || '-',
        zipcode: values.zipcode_1 || '-',
      },
      state.practice.physical_address?.ID
    );
  };

  const updatePostalAddress = async (values: any) => {
    setPostalAddressInfo(false);

    setInitialPostalAddress({
      ...values,
    });

    await updateAddress(
      {
        street2: values.address_1 || '-',
        street1: values.street_2 || '-',
        state: values.state_2 || '-',
        city: values.city_2 || '-',
        zipcode: values.zipcode_2 || '-',
      },
      state.practice.postal_address?.ID
    );
  };

  const validationBasicInfoSchema = Yup.object({
    practice_email: Yup.string()
      .email('Email is invalid')
      .required('Email is required'),
    user_first_name: Yup.string().required('User first name is required '),
    user_last_name: Yup.string().required('User last name  is required '),
    user_position: Yup.string().required('User position   is required '),
    practice_name: Yup.string().required('Practice name   is required'),
    user_phone: Yup.string().required('Practice phone  is required '),
  });

  const [initial_account_data] = useState({
    current_password: '',
    new_password: '',
    retype_password: '',
  });

  const validationAccountPasswordSchema = Yup.object({
    current_password: Yup.string().required('Current password is required '),
    new_password: Yup.string().required('New password is required '),
    retype_password: Yup.string()
      .required('Please confirm new password')
      .oneOf([Yup.ref('new_password'), null], 'Passwords must match'),
  });

  const updateAccountPassword = async (values: any) => {
    setChangePassword(false);

    const change_password = await changeUserAccountPassword({
      new_password: values.new_password,
    });

    if (change_password.data.error === false) {
      toast.success(`Password was updated successfully.`);
    } else {
      toast.error(`Something went wrong.`);
    }

    setUpdatePassword(false);
  };

  const validationContactInfoSchema = Yup.object({
    contact_email: Yup.string()
      .email('Email is invalid')
      .required('Email is required'),
    contact_first_name: Yup.string().required('First name is required '),
    contact_last_name: Yup.string().required('Last name  is required '),
    contact_phone: Yup.string().required('Phone  is required '),
  });

  const validationPhysicalAddress = Yup.object({
    country_1: Yup.string().required('Country is required '),
    city_1: Yup.string().required('City is required '),
    state_1: Yup.string().required('State  is required '),
    zipcode_1: Yup.string().required('Zip code  is required '),
    address_1: Yup.string().required('Zip code  is required '),
  });

  useEffect(() => {
    if (
      pathname === '/practice/account' ||
      pathname === '/practice/payments' ||
      pathname === '/practice/details' ||
      pathname === '/practice/support'
    ) {
      dispatch(getPracticeDetail());
      setEdit(true);
    } else if (pathname === '/dashboard') {
      setEdit(false);
    }
    // eslint-disable-next-line
  }, [pathname]);

  const [settings, setSettings]: any = useState({});

  useEffect(() => {
    if (state.videos?.now_playing.length) {
      const opts = {
        width: '350px',
        height: ['80px', '80px'],
        layout: [1, 4],
        photos: state.videos?.now_playing.map((x: any) => {
          return {
            source: x.thumbnail_file || null,
          };
        }),
        showNumOfRemainingPhotos: true,
      };

      setSettings(opts);
    }
  }, [state]);

  useEffect(() => {
    const init = async () => {
      setLoadingAnnouncements(true);

      const {
        data: { results },
      } = await getAnncouncements({
        page: 1,
        per_page: 5,
      });

      setAnnouncements(results);
      setLoadingAnnouncements(false);
    };

    init();
  }, []);

  const { trigger } = useModalActions();
  const teamMemberModal: ModalType = {
    type: 'member',
    component: <MemberDetails />,
  };

  const { set } = useMembersAction();
  useEffect(() => {
    const members: MemberType[] = state?.practice?.team_members.map(
      ({ team_member_id, photo_thumbnail, MemberName, role, ShortName }) => {
        return {
          id: team_member_id,
          src: photo_thumbnail,
          name: MemberName,
          role: role,
          shortname: ShortName,
        };
      }
    );
    set(members);
  }, [state?.practice?.team_members, set]);

  return (
    <>
      <PracticeTheme
        setEdit={setEdit}
        teams={teams}
        setTeams={setTeams}
        library={library}
        setLibrary={setLibrary}
        practice={state.practice}
      >
        <div className='bg-[#F2F5F9]'>
          <div className='container mx-auto w-[1180px] py-[5px] pb-[0px]'>
            <div className='menuWrapper newcon flex pb-[24px]'>
              <div className='menu col1 w-[80%] inline-block'>
                <h1 className='text-[32px] pb-[18px] pt-[18px] font-[600]'>
                  {state.practice?.detail?.PracticeName}
                </h1>
                <p
                  className={`${
                    pathname === '/dashboard'
                      ? 'selectItem c cursor-pointer px-3 inline-block font-[500]  text-center text-[#212b36]'
                      : 'unselectItem px-3 inline-block text-center text-gray-500 cursor-pointer'
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate('/dashboard');
                  }}
                >
                  Dashboard
                  {pathname === '/dashboard' && (
                    <div className='selectItemDiv'></div>
                  )}
                </p>
                <p
                  className={`${
                    pathname === '/practice/details'
                      ? 'selectItem c cursor-pointer px-3 inline-block font-[500]  text-center text-[#212b36]'
                      : 'unselectItem px-3 inline-block text-center text-gray-500 cursor-pointer'
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(getPracticeDetail());
                    navigate('/practice/details');
                  }}
                >
                  Settings
                  {pathname === '/practice/details' && (
                    <div className='selectItemDiv'></div>
                  )}
                </p>

                <p
                  className={`${
                    pathname === '/practice/payments'
                      ? 'selectItem c cursor-pointer px-3 inline-block font-[500]  text-center text-[#212b36]'
                      : 'unselectItem px-3 inline-block text-center text-gray-500 cursor-pointer'
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate('/practice/payments');
                  }}
                >
                  Billing
                  {pathname === '/practice/payments' && (
                    <div className='selectItemDiv'></div>
                  )}
                </p>

                <p
                  className={`${
                    pathname === '/practice/account'
                      ? 'selectItem c cursor-pointer px-3 inline-block font-[500]  text-center text-[#212b36]'
                      : 'unselectItem px-3 inline-block text-center text-gray-500 cursor-pointer'
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate('/practice/account');
                  }}
                >
                  Account
                  {pathname === '/practice/account' && (
                    <div className='selectItemDiv'></div>
                  )}
                </p>
                <p
                  className={`${
                    pathname === '/practice/support'
                      ? 'selectItem c cursor-pointer px-3 inline-block font-[500]  text-center text-[#212b36]'
                      : 'unselectItem px-3 inline-block text-center text-gray-500 cursor-pointer'
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate('/practice/support');
                  }}
                >
                  Help
                  {pathname === '/practice/support' && (
                    <div className='selectItemDiv'></div>
                  )}
                </p>
              </div>

              <div className='mt-3 mb-1'>
                {state.practice?.detail?.FilePath ? (
                  <>
                    <div className='w-[19%] inline-block text-right cursor-pointer'>
                      <div className='w-[165px] h-[92px] inline-flex justify-center flex-col items-center rounded-[12px]'>
                        <img alt='' src={state.practice?.detail?.FilePath} />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <FileUploader
                      name='file'
                      handleChange={handleChange}
                      types={fileTypes}
                    >
                      <div className='uploadLogoWrapper w-[19%] inline-block text-right cursor-pointer'>
                        <div className='w-[165px] h-[92px] inline-flex justify-center flex-col items-center border-dashed border-2 border-[#000] rounded-[12px]'>
                          <input
                            className="video w-[20px] leading-[0px] text-[30px] cursor-pointer before:content-[url('https://cartronicsautorepair.com/staging/wp-content/uploads/2023/07/upload.svg')]"
                            type='file'
                            disabled
                          />
                          <p className='text-[12px] font-[400] pt-[4px] text-[#637381]'>
                            Upload logo here
                          </p>
                        </div>
                      </div>
                    </FileUploader>
                  </>
                )}
              </div>
            </div>
            {edit === true ? (
              <>
                {pathname === '/practice/details' && (
                  <div className='con flex dashboardFirstRowWrapper'>
                    <div className='playlistCardWrapper mb-[200px]'>
                      <div className='flex flex-col gap-y-5 col-span-4'>
                        <div className='gap-12px  flex justify-self-start'>
                          <div className='practice-logo-display'>
                            <LoadingOverlay active={loading} spinner>
                              <div
                                className='practive-box-holder practive-box-holder-size'
                                style={{
                                  borderRadius: '16px',
                                  backgroundSize: 'contain',
                                  backgroundRepeat: 'no-repeat',
                                  backgroundImage: `url(${state?.practice?.detail?.FilePath})`,
                                }}
                              ></div>
                            </LoadingOverlay>
                          </div>

                          <div className='flex flex-col  justify-between'>
                            <div className='gap-4px flex flex-col'>
                              <span className='v-bs-h400 text-text900 w-60'>
                                Practice logo
                              </span>
                              <span className='v-bs-initial200 text-text500'></span>
                            </div>
                            <div className='logo lt-item-icon-box  box-shadow-focus edit-practice-btn btn-layout-style btn-layout-border w-3/5'>
                              <span className=' flex items-center gap-4px cursor-pointer'>
                                <FileUploader
                                  name='file'
                                  handleChange={handleChange}
                                  types={fileTypes}
                                  className='cursor-pointer logo'
                                >
                                  {' '}
                                  update logo
                                </FileUploader>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className='flex flex-col gap-3'>
                          {editBasicInfo === false && (
                            <div
                              className='flex flex-col gap-y-2 '
                              style={{ width: '300px' }}
                            >
                              <div className='practice-settings-section-title'>
                                <span>Basic info</span>
                                <span>
                                  <button
                                    className='edit-btn-setting'
                                    onClick={() => {
                                      setContactInfo(false);
                                      setPhysicalAddressInfo(false);
                                      setPostalAddressInfo(false);
                                      setBasicInfo(true);
                                    }}
                                  >
                                    <span className=' flex items-center gap-4px'>
                                      <svg
                                        xmlnsXlink='http://www.w3.org/1999/xlink'
                                        xmlns='http://www.w3.org/2000/svg'
                                        aria-hidden='true'
                                        focusable='false'
                                        role='presentation'
                                        className='index-StyledSvg_cls2__nHIIG index-StyledSvg_cls1__3YcLt'
                                        width='24'
                                        height='24'
                                      >
                                        <defs>
                                          <symbol
                                            id='pencil'
                                            viewBox='0 0 24 24'
                                          >
                                            <path
                                              fillRule='evenodd'
                                              d='M7.5 22.5h-6v-6L15.62 2.379a3 3 0 0 1 4.243 0l1.758 1.757a3 3 0 0 1 0 4.243L7.5 22.499zm-2.49-5.268 1.757 1.758 8.857-8.857-1.758-1.757-8.856 8.856zM19.5 6.257l-1.755 1.755-1.758-1.757L17.742 4.5 19.5 6.257z'
                                              clipRule='evenodd'
                                            ></path>
                                          </symbol>
                                        </defs>
                                        <use
                                          xlinkHref='#pencil'
                                          fill='#626262'
                                        ></use>
                                      </svg>
                                      Edit{' '}
                                    </span>
                                  </button>
                                </span>
                              </div>
                              <div className='grid grid-cols-2 items-center justify-start'>
                                <span className='v-bs-input-label300 text-text500'>
                                  User title
                                </span>
                                <span className='v-bs-input-value200 text-text900'>
                                  {Initial_values_basic_info.user_title}
                                </span>
                              </div>
                              <div className='grid grid-cols-2 items-center justify-start'>
                                <span className='v-bs-input-label300 text-text500'>
                                  User first name
                                </span>
                                <span className='v-bs-input-value200 text-text900'>
                                  {Initial_values_basic_info?.user_first_name}
                                </span>
                              </div>
                              <div className='grid grid-cols-2 items-center justify-start'>
                                <span className='v-bs-input-label300 text-text500'>
                                  User last name
                                </span>
                                <span className='v-bs-input-value200 text-text900'>
                                  {Initial_values_basic_info?.user_last_name}
                                </span>
                              </div>
                              <div className='grid grid-cols-2 items-center justify-start'>
                                <span className='v-bs-input-label300 text-text500'>
                                  User position
                                </span>
                                <span className='v-bs-input-value200 text-text900'>
                                  {Initial_values_basic_info.user_position}
                                </span>
                              </div>
                              <div className='grid grid-cols-2 items-center justify-start'>
                                <span className='v-bs-input-label300 text-text500'>
                                  Practice name
                                </span>
                                <span className='v-bs-input-value200 text-text900'>
                                  {Initial_values_basic_info.practice_name}
                                </span>
                              </div>
                              <div className='grid grid-cols-2 items-center justify-start'>
                                <span className='v-bs-input-label300 text-text500'>
                                  Practice E-mail
                                </span>
                                <span className='v-bs-input-value200 text-text900'>
                                  {Initial_values_basic_info.practice_email}
                                </span>
                              </div>
                              <div className='grid grid-cols-2 items-center justify-start'>
                                <span className='v-bs-input-label300 text-text500'>
                                  Phone
                                </span>
                                <span className='v-bs-input-value200 text-text900'>
                                  {Initial_values_basic_info.user_phone}
                                </span>
                              </div>
                              <div className='grid grid-cols-2 items-center justify-start'>
                                <span className='v-bs-input-label300 text-text500'>
                                  Name of corporation
                                </span>
                                <span className='v-bs-input-value200 text-text900'>
                                  {Initial_values_basic_info.corp_name}
                                </span>
                              </div>
                            </div>
                          )}

                          {/* Basic Info Form   */}

                          {editBasicInfo && (
                            <Formik
                              initialValues={Initial_values_basic_info}
                              validationSchema={validationBasicInfoSchema}
                              onSubmit={updateBasicInfo}
                              enableReinitialize
                            >
                              <Form>
                                <div className='c-form'>
                                  <div className='team-field-group'>
                                    <label className='photo-label-title'>
                                      User title
                                    </label>

                                    <div className='relative flex'>
                                      <input
                                        type='radio'
                                        name='user_title'
                                        value='Dr'
                                        onChange={(e) =>
                                          setSubTitle(e.target.value)
                                        }
                                        defaultChecked={
                                          Initial_values_basic_info.user_title ===
                                          'Dr'
                                            ? true
                                            : false
                                        }
                                      />
                                      <label>Dr</label>

                                      <input
                                        type='radio'
                                        name='user_title'
                                        value='Mr'
                                        onChange={(e) =>
                                          setSubTitle(e.target.value)
                                        }
                                        defaultChecked={
                                          Initial_values_basic_info.user_title ===
                                          'Mr'
                                            ? true
                                            : false
                                        }
                                      />
                                      <label>Mr</label>

                                      <input
                                        type='radio'
                                        name='user_title'
                                        value='Ms'
                                        onChange={(e) =>
                                          setSubTitle(e.target.value)
                                        }
                                        defaultChecked={
                                          Initial_values_basic_info.user_title ===
                                          'Ms'
                                            ? true
                                            : false
                                        }
                                      />
                                      <label>Ms</label>

                                      <input
                                        type='radio'
                                        name='user_title'
                                        value='Mrs'
                                        onChange={(e) =>
                                          setSubTitle(e.target.value)
                                        }
                                        defaultChecked={
                                          Initial_values_basic_info.user_title ===
                                          'Mrs'
                                            ? true
                                            : false
                                        }
                                      />
                                      <label>Mrs</label>

                                      <input
                                        type='radio'
                                        name='user_title'
                                        value='Miss'
                                        onChange={(e) =>
                                          setSubTitle(e.target.value)
                                        }
                                        defaultChecked={
                                          Initial_values_basic_info.user_title ===
                                          'Miss'
                                            ? true
                                            : false
                                        }
                                      />
                                      <label>Miss</label>
                                    </div>
                                  </div>

                                  <div className='team-field-group margin-10'>
                                    <label className='photo-label-title'>
                                      User first name
                                    </label>
                                    <div className='relative flex'>
                                      <Field
                                        className='team-field-control'
                                        id='user_first_name'
                                        name='user_first_name'
                                        placeholder='First name'
                                      />
                                    </div>

                                    <ErrorMessage
                                      component='a'
                                      className='error-msg'
                                      name='user_first_name'
                                    />
                                  </div>

                                  <div className='team-field-group margin-10'>
                                    <label className='photo-label-title'>
                                      User Last name
                                    </label>
                                    <div className='relative flex'>
                                      <Field
                                        className='team-field-control'
                                        id='user_last_name'
                                        name='user_last_name'
                                        placeholder='Last name'
                                      />
                                    </div>

                                    <ErrorMessage
                                      component='a'
                                      className='error-msg'
                                      name='user_last_name'
                                    />
                                  </div>

                                  <div className='team-field-group margin-10'>
                                    <label className='photo-label-title'>
                                      User Position
                                    </label>
                                    <div className='relative flex'>
                                      <Field
                                        className='team-field-control'
                                        id='user_position'
                                        name='user_position'
                                        placeholder='User postion'
                                      />
                                    </div>

                                    <ErrorMessage
                                      component='a'
                                      className='error-msg'
                                      name='user_position'
                                    />
                                  </div>

                                  <div className='team-field-group margin-10'>
                                    <label className='photo-label-title'>
                                      Practice Name
                                    </label>
                                    <div className='relative flex'>
                                      <Field
                                        className='team-field-control'
                                        id='practice_name'
                                        name='practice_name'
                                        placeholder='Practice name'
                                      />
                                    </div>

                                    <ErrorMessage
                                      component='a'
                                      className='error-msg'
                                      name='practice_name'
                                    />
                                  </div>

                                  <div className='team-field-group margin-10'>
                                    <label className='photo-label-title'>
                                      Practice Email
                                    </label>
                                    <div className='relative flex'>
                                      <Field
                                        className='team-field-control'
                                        id='practice_email'
                                        name='practice_email'
                                        placeholder='Practice email'
                                      />
                                    </div>

                                    <ErrorMessage
                                      component='a'
                                      className='error-msg'
                                      name='practice_email'
                                    />
                                  </div>

                                  <div className='team-field-group margin-10'>
                                    <label className='photo-label-title'>
                                      Phone
                                    </label>
                                    <div className='relative flex'>
                                      <Field
                                        className='team-field-control'
                                        id='user_phone'
                                        name='user_phone'
                                        placeholder='Phone'
                                      />
                                    </div>

                                    <ErrorMessage
                                      component='a'
                                      className='error-msg'
                                      name='user_phone'
                                    />
                                  </div>

                                  <div className='team-field-group margin-10'>
                                    <label className='photo-label-title'>
                                      Name of corporation
                                    </label>
                                    <div className='relative flex'>
                                      <Field
                                        className='team-field-control'
                                        id='corp_name'
                                        name='corp_name'
                                        placeholder='Name of corporation'
                                      />
                                    </div>

                                    <ErrorMessage
                                      component='a'
                                      className='error-msg'
                                      name='corp_name'
                                    />
                                  </div>
                                </div>

                                <div className='flex-1 gap-2 w-1/2'>
                                  <button
                                    onClick={() => setBasicInfo(false)}
                                    type='button'
                                    className='index-StyledButton_cls2__1Drrf index-StyledButton_cls1__1qkPE index-StyledButton_isFocusable__aUCeS index-StyledButton_size-small__EOSry index-StyledButton_variant-ghost__1dOzQ'
                                  >
                                    <span className=' flex items-center gap-4px'>
                                      Cancel
                                    </span>
                                  </button>
                                  <button
                                    type='submit'
                                    className='index-StyledButton_cls2__1Drrf index-StyledButton_cls1__1qkPE index-StyledButton_isFocusable__aUCeS index-StyledButton_size-small__EOSry index-StyledButton_variant-primary__xNYe7'
                                  >
                                    <span className=' flex items-center gap-4px'>
                                      Save changes
                                    </span>
                                  </button>
                                </div>
                              </Form>
                            </Formik>
                          )}

                          {/* End  Basic Info Form   */}

                          {editContactInfo === false && (
                            <div className='flex flex-col gap-y-2'>
                              <div className='practice-settings-section-title '>
                                <span>Contact info</span>
                                <span>
                                  <button
                                    className='edit-btn-setting'
                                    onClick={() => {
                                      setPhysicalAddressInfo(false);
                                      setPostalAddressInfo(false);
                                      setBasicInfo(false);
                                      setContactInfo(true);
                                    }}
                                  >
                                    <span className=' flex items-center gap-4px'>
                                      <svg
                                        xmlnsXlink='http://www.w3.org/1999/xlink'
                                        xmlns='http://www.w3.org/2000/svg'
                                        aria-hidden='true'
                                        focusable='false'
                                        role='presentation'
                                        className='index-StyledSvg_cls2__nHIIG index-StyledSvg_cls1__3YcLt'
                                        width='24'
                                        height='24'
                                      >
                                        <defs>
                                          <symbol
                                            id='pencil'
                                            viewBox='0 0 24 24'
                                          >
                                            <path
                                              fillRule='evenodd'
                                              d='M7.5 22.5h-6v-6L15.62 2.379a3 3 0 0 1 4.243 0l1.758 1.757a3 3 0 0 1 0 4.243L7.5 22.499zm-2.49-5.268 1.757 1.758 8.857-8.857-1.758-1.757-8.856 8.856zM19.5 6.257l-1.755 1.755-1.758-1.757L17.742 4.5 19.5 6.257z'
                                              clipRule='evenodd'
                                            ></path>
                                          </symbol>
                                        </defs>
                                        <use
                                          xlinkHref='#pencil'
                                          fill='#626262'
                                        ></use>
                                      </svg>
                                      Edit{' '}
                                    </span>
                                  </button>
                                </span>
                              </div>
                              <div className='grid grid-cols-2 items-center justify-start'>
                                <span className='v-bs-input-label300 text-text500'>
                                  Title
                                </span>
                                <span className='v-bs-input-value200 text-text900'>
                                  {initial_contact_info_data.contact_title ||
                                    '-'}
                                </span>
                              </div>
                              <div className='grid grid-cols-2 items-center justify-start'>
                                <span className='v-bs-input-label300 text-text500'>
                                  First name
                                </span>
                                <span className='v-bs-input-value200 text-text900'>
                                  {initial_contact_info_data.contact_first_name}
                                </span>
                              </div>
                              <div className='grid grid-cols-2 items-center justify-start'>
                                <span className='v-bs-input-label300 text-text500'>
                                  Last name
                                </span>
                                <span className='v-bs-input-value200 text-text900'>
                                  {initial_contact_info_data.contact_last_name}
                                </span>
                              </div>
                              <div className='grid grid-cols-2 items-center justify-start'>
                                <span className='v-bs-input-label300 text-text500'>
                                  E-mail
                                </span>
                                <span className='v-bs-input-value200 text-text900'>
                                  {initial_contact_info_data.contact_email}
                                </span>
                              </div>
                              <div className='grid grid-cols-2 items-center justify-start'>
                                <span className='v-bs-input-label300 text-text500'>
                                  Phone
                                </span>
                                <span className='v-bs-input-value200 text-text900'>
                                  {initial_contact_info_data.contact_phone}
                                </span>
                              </div>
                            </div>
                          )}

                          {/* Contact Info Form   */}

                          {editContactInfo && (
                            <Formik
                              initialValues={initial_contact_info_data}
                              validationSchema={validationContactInfoSchema}
                              onSubmit={updateContactInfo}
                              enableReinitialize
                            >
                              <Form>
                                <div className='c-form'>
                                  <div className='team-field-group'>
                                    <label className='photo-label-title'>
                                      Contact title
                                    </label>

                                    <div className='relative flex'>
                                      <input
                                        type='radio'
                                        name='contact_title'
                                        value='Dr'
                                        onChange={(e) =>
                                          setContactTitle(e.target.value)
                                        }
                                        defaultChecked={
                                          initial_contact_info_data.contact_title ===
                                          'Dr'
                                            ? true
                                            : false
                                        }
                                      />
                                      <label>
                                        <span></span>Dr
                                      </label>

                                      <input
                                        type='radio'
                                        name='contactr_title'
                                        value='Mr'
                                        onChange={(e) =>
                                          setContactTitle(e.target.value)
                                        }
                                        defaultChecked={
                                          initial_contact_info_data.contact_title ===
                                          'Mr'
                                            ? true
                                            : false
                                        }
                                      />
                                      <label>
                                        <span></span>Mr
                                      </label>

                                      <input
                                        type='radio'
                                        name='contact_title'
                                        value='Ms'
                                        onChange={(e) =>
                                          setContactTitle(e.target.value)
                                        }
                                        defaultChecked={
                                          initial_contact_info_data.contact_title ===
                                          'Ms'
                                            ? true
                                            : false
                                        }
                                      />
                                      <label>
                                        <span></span>Ms
                                      </label>

                                      <input
                                        type='radio'
                                        name='contact_title'
                                        value='Mrs'
                                        onChange={(e) =>
                                          setContactTitle(e.target.value)
                                        }
                                        defaultChecked={
                                          initial_contact_info_data.contact_title ===
                                          'Mrs'
                                            ? true
                                            : false
                                        }
                                      />
                                      <label>
                                        <span></span>Mrs
                                      </label>

                                      <input
                                        type='radio'
                                        name='contact_title'
                                        value='Miss'
                                        onChange={(e) =>
                                          setContactTitle(e.target.value)
                                        }
                                        defaultChecked={
                                          initial_contact_info_data.contact_title ===
                                          'Miss'
                                            ? true
                                            : false
                                        }
                                      />
                                      <label>
                                        <span></span>Miss
                                      </label>
                                    </div>
                                  </div>

                                  <div className='team-field-group margin-10'>
                                    <label className='photo-label-title'>
                                      Contact first name
                                    </label>
                                    <div className='relative flex'>
                                      <Field
                                        className='team-field-control'
                                        id='contact_first_name'
                                        name='contact_first_name'
                                        placeholder='Contact first name'
                                      />
                                    </div>

                                    <ErrorMessage
                                      component='a'
                                      className='error-msg'
                                      name='contact_first_name'
                                    />
                                  </div>

                                  <div className='team-field-group margin-10'>
                                    <label className='photo-label-title'>
                                      Contact Last name
                                    </label>
                                    <div className='relative flex'>
                                      <Field
                                        className='team-field-control'
                                        id='contact_last_name'
                                        name='contact_last_name'
                                        placeholder='Contact last name'
                                      />
                                    </div>

                                    <ErrorMessage
                                      component='a'
                                      className='error-msg'
                                      name='contact_last_name'
                                    />
                                  </div>

                                  <div className='team-field-group margin-10'>
                                    <label className='photo-label-title'>
                                      Contact E-mail
                                    </label>
                                    <div className='relative flex'>
                                      <Field
                                        className='team-field-control'
                                        id='contact_email'
                                        name='contact_email'
                                        placeholder='Practice email'
                                      />
                                    </div>

                                    <ErrorMessage
                                      component='a'
                                      className='error-msg'
                                      name='contact_email'
                                    />
                                  </div>

                                  <div className='team-field-group margin-10'>
                                    <label className='photo-label-title'>
                                      Contact Phone
                                    </label>
                                    <div className='relative flex'>
                                      <Field
                                        className='team-field-control'
                                        id='contact_phone'
                                        name='contact_phone'
                                        placeholder='Contact phone'
                                      />
                                    </div>

                                    <ErrorMessage
                                      component='a'
                                      className='error-msg'
                                      name='contact_phone'
                                    />
                                  </div>
                                </div>

                                <div className='flex-1 gap-2 w-1/2'>
                                  <button
                                    onClick={() => setContactInfo(false)}
                                    type='button'
                                    className='index-StyledButton_cls2__1Drrf index-StyledButton_cls1__1qkPE index-StyledButton_isFocusable__aUCeS index-StyledButton_size-small__EOSry index-StyledButton_variant-ghost__1dOzQ'
                                  >
                                    <span className=' flex items-center gap-4px'>
                                      Cancel
                                    </span>
                                  </button>
                                  <button
                                    disabled={false}
                                    type='submit'
                                    className='index-StyledButton_cls2__1Drrf index-StyledButton_cls1__1qkPE index-StyledButton_isFocusable__aUCeS index-StyledButton_size-small__EOSry index-StyledButton_variant-primary__xNYe7'
                                  >
                                    <span className=' flex items-center gap-4px'>
                                      Save changes
                                    </span>
                                  </button>
                                </div>
                              </Form>
                            </Formik>
                          )}

                          {/* End  Contact Info Form   */}

                          {editPhysicalAddressInfo === false && (
                            <div className='flex flex-col gap-y-2 mb-10'>
                              <div className='practice-settings-section-title '>
                                <span>Physical address</span>
                                <span>
                                  <button
                                    className='edit-btn-setting'
                                    onClick={() => {
                                      setContactInfo(false);
                                      setPostalAddressInfo(false);
                                      setBasicInfo(false);
                                      setPhysicalAddressInfo(true);
                                    }}
                                  >
                                    <span className=' flex items-center gap-4px'>
                                      <svg
                                        xmlnsXlink='http://www.w3.org/1999/xlink'
                                        xmlns='http://www.w3.org/2000/svg'
                                        aria-hidden='true'
                                        focusable='false'
                                        role='presentation'
                                        className='index-StyledSvg_cls2__nHIIG index-StyledSvg_cls1__3YcLt'
                                        width='24'
                                        height='24'
                                      >
                                        <defs>
                                          <symbol
                                            id='pencil'
                                            viewBox='0 0 24 24'
                                          >
                                            <path
                                              fillRule='evenodd'
                                              d='M7.5 22.5h-6v-6L15.62 2.379a3 3 0 0 1 4.243 0l1.758 1.757a3 3 0 0 1 0 4.243L7.5 22.499zm-2.49-5.268 1.757 1.758 8.857-8.857-1.758-1.757-8.856 8.856zM19.5 6.257l-1.755 1.755-1.758-1.757L17.742 4.5 19.5 6.257z'
                                              clipRule='evenodd'
                                            ></path>
                                          </symbol>
                                        </defs>
                                        <use
                                          xlinkHref='#pencil'
                                          fill='#626262'
                                        ></use>
                                      </svg>
                                      Edit{' '}
                                    </span>
                                  </button>
                                </span>
                              </div>
                              <div className='grid grid-cols-2 items-center justify-start'>
                                <span className='v-bs-input-label300 text-text500'>
                                  Country
                                </span>
                                <span className='v-bs-input-value200 text-text900'>
                                  {physical_address_data.country_1}
                                </span>
                              </div>
                              <div className='grid grid-cols-2 items-center justify-start'>
                                <span className='v-bs-input-label300 text-text500'>
                                  State or region
                                </span>
                                <span className='v-bs-input-value200 text-text900'>
                                  {physical_address_data.state_1}
                                </span>
                              </div>
                              <div className='grid grid-cols-2 items-center justify-start'>
                                <span className='v-bs-input-label300 text-text500'>
                                  Zip code
                                </span>
                                <span className='v-bs-input-value200 text-text900'>
                                  {physical_address_data.zipcode_1}
                                </span>
                              </div>
                              <div className='grid grid-cols-2 items-center justify-start'>
                                <span className='v-bs-input-label300 text-text500'>
                                  City
                                </span>
                                <span className='v-bs-input-value200 text-text900'>
                                  {physical_address_data.city_1}
                                </span>
                              </div>
                              <div className='grid grid-cols-2 items-center justify-start'>
                                <span className='v-bs-input-label300 text-text500'>
                                  Street
                                </span>
                                <span className='v-bs-input-value200 text-text900'>
                                  {physical_address_data.street_1}
                                </span>
                              </div>
                              <div className='grid grid-cols-2 items-center justify-start'>
                                <span className='v-bs-input-label300 text-text500'>
                                  Apartment, Suite, Floor, etc
                                </span>
                                <span className='v-bs-input-value200 text-text900'>
                                  {physical_address_data.address_1}
                                </span>
                              </div>
                            </div>
                          )}

                          {/* Physical Address Form   */}

                          {editPhysicalAddressInfo && (
                            <Formik
                              initialValues={physical_address_data}
                              validationSchema={validationPhysicalAddress}
                              onSubmit={updatePhysicalAddress}
                              enableReinitialize
                            >
                              <Form>
                                <div className='c-form mb-10'>
                                  <div className='team-field-group margin-10'>
                                    <label className='photo-label-title'>
                                      Country
                                    </label>
                                    <div className='relative flex'>
                                      <Field
                                        className='team-field-control'
                                        id='country_1'
                                        name='country_1'
                                        placeholder='Country'
                                        disabled
                                      />
                                    </div>

                                    <ErrorMessage
                                      component='a'
                                      className='error-msg'
                                      name='country_1'
                                    />
                                  </div>

                                  <div className='team-field-group margin-10'>
                                    <label className='photo-label-title'>
                                      State or region
                                    </label>
                                    <div className='relative flex'>
                                      <Field
                                        className='team-field-control'
                                        id='state_1'
                                        name='state_1'
                                        placeholder='State'
                                      />
                                    </div>

                                    <ErrorMessage
                                      component='a'
                                      className='error-msg'
                                      name='state_1'
                                    />
                                  </div>

                                  <div className='team-field-group margin-10'>
                                    <label className='photo-label-title'>
                                      Zip code
                                    </label>
                                    <div className='relative flex'>
                                      <Field
                                        className='team-field-control'
                                        id='zipcode_1'
                                        name='zipcode_1'
                                        placeholder='Zip code'
                                      />
                                    </div>

                                    <ErrorMessage
                                      component='a'
                                      className='error-msg'
                                      name='zipcode_1'
                                    />
                                  </div>

                                  <div className='team-field-group margin-10'>
                                    <label className='photo-label-title'>
                                      City
                                    </label>
                                    <div className='relative flex'>
                                      <Field
                                        className='team-field-control'
                                        id='city_1'
                                        name='city_1'
                                        placeholder='City'
                                      />
                                    </div>

                                    <ErrorMessage
                                      component='a'
                                      className='error-msg'
                                      name='city_1'
                                    />
                                  </div>

                                  <div className='team-field-group margin-10'>
                                    <label className='photo-label-title'>
                                      Street
                                    </label>
                                    <div className='relative flex'>
                                      <Field
                                        className='team-field-control'
                                        id='street_1'
                                        name='street_1'
                                        placeholder='Street'
                                      />
                                    </div>

                                    <ErrorMessage
                                      component='a'
                                      className='error-msg'
                                      name='street_1'
                                    />
                                  </div>

                                  <div className='team-field-group margin-10'>
                                    <label className='photo-label-title'>
                                      Apartment, Suite, Floor, etc
                                    </label>
                                    <div className='relative flex'>
                                      <Field
                                        className='team-field-control'
                                        id='address_1'
                                        name='address_1'
                                        placeholder='Apartment, Suite, Floor, etc'
                                      />
                                    </div>

                                    <ErrorMessage
                                      component='a'
                                      className='error-msg'
                                      name='address_1'
                                    />
                                  </div>
                                </div>

                                <div className='flex-1 gap-2 w-1/2'>
                                  <button
                                    onClick={() =>
                                      setPhysicalAddressInfo(false)
                                    }
                                    type='button'
                                    className='index-StyledButton_cls2__1Drrf index-StyledButton_cls1__1qkPE index-StyledButton_isFocusable__aUCeS index-StyledButton_size-small__EOSry index-StyledButton_variant-ghost__1dOzQ'
                                  >
                                    <span className=' flex items-center gap-4px'>
                                      Cancel
                                    </span>
                                  </button>
                                  <button
                                    disabled={false}
                                    type='submit'
                                    className='index-StyledButton_cls2__1Drrf index-StyledButton_cls1__1qkPE index-StyledButton_isFocusable__aUCeS index-StyledButton_size-small__EOSry index-StyledButton_variant-primary__xNYe7'
                                  >
                                    <span className=' flex items-center gap-4px'>
                                      Save changes
                                    </span>
                                  </button>
                                </div>
                              </Form>
                            </Formik>
                          )}

                          {/* End  Physical Address Form   */}

                          {/* {editPostalAddressInfo === false && (
                          <div className="flex flex-col gap-y-2">
                            <div className="practice-settings-section-title ">
                              <span>Postal address</span>
                              <span>
                                <button
                                  className="edit-btn-setting"
                                  onClick={() => {
                                    setContactInfo(false);
                                    setBasicInfo(false);
                                    setPhysicalAddressInfo(false);
                                    setPostalAddressInfo(true);
                                  }}
                                >
                                  <span className=" flex items-center gap-4px">
                                    <svg
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      xmlns="http://www.w3.org/2000/svg"
                                      aria-hidden="true"
                                      focusable="false"
                                      role="presentation"
                                      className="index-StyledSvg_cls2__nHIIG index-StyledSvg_cls1__3YcLt"
                                      width="24"
                                      height="24"
                                    >
                                      <defs>
                                        <symbol id="pencil" viewBox="0 0 24 24">
                                          <path
                                            fillRule="evenodd"
                                            d="M7.5 22.5h-6v-6L15.62 2.379a3 3 0 0 1 4.243 0l1.758 1.757a3 3 0 0 1 0 4.243L7.5 22.499zm-2.49-5.268 1.757 1.758 8.857-8.857-1.758-1.757-8.856 8.856zM19.5 6.257l-1.755 1.755-1.758-1.757L17.742 4.5 19.5 6.257z"
                                            clipRule="evenodd"
                                          ></path>
                                        </symbol>
                                      </defs>
                                      <use
                                        xlinkHref="#pencil"
                                        fill="#626262"
                                      ></use>
                                    </svg>
                                    Edit{" "}
                                  </span>
                                </button>
                              </span>
                            </div>
                            <div className="grid grid-cols-2 items-center justify-start">
                              <span className="v-bs-input-label300 text-text500">
                                Country
                              </span>
                              <span className="v-bs-input-value200 text-text900">
                                {postal_address_data.country_2}
                              </span>
                            </div>
                            <div className="grid grid-cols-2 items-center justify-start">
                              <span className="v-bs-input-label300 text-text500">
                                State or region
                              </span>
                              <span className="v-bs-input-value200 text-text900">
                                {postal_address_data.state_2}
                              </span>
                            </div>
                            <div className="grid grid-cols-2 items-center justify-start">
                              <span className="v-bs-input-label300 text-text500">
                                Zip code
                              </span>
                              <span className="v-bs-input-value200 text-text900">
                                {postal_address_data.zipcode_2}
                              </span>
                            </div>
                            <div className="grid grid-cols-2 items-center justify-start">
                              <span className="v-bs-input-label300 text-text500">
                                City
                              </span>
                              <span className="v-bs-input-value200 text-text900">
                                {postal_address_data.city_2}
                              </span>
                            </div>
                            <div className="grid grid-cols-2 items-center justify-start">
                              <span className="v-bs-input-label300 text-text500">
                                Street
                              </span>
                              <span className="v-bs-input-value200 text-text900">
                                {postal_address_data.street_2}
                              </span>
                            </div>
                            <div className="grid grid-cols-2 items-center justify-start">
                              <span className="v-bs-input-label300 text-text500">
                                Apartment, Suite, Floor, etc
                              </span>
                              <span className="v-bs-input-value200 text-text900">
                                {postal_address_data.address_2}
                              </span>
                            </div>
                          </div>
                        )} */}

                          {/* Postal Address Form   */}

                          {editPostalAddressInfo && (
                            <Formik
                              initialValues={postal_address_data}
                              // validationSchema={validationPostalAddress}
                              onSubmit={updatePostalAddress}
                              enableReinitialize
                            >
                              <Form>
                                <div className='c-form'>
                                  <div className='team-field-group margin-10'>
                                    <label className='photo-label-title'>
                                      Country
                                    </label>
                                    <div className='relative flex'>
                                      <Field
                                        className='team-field-control'
                                        id='country_2'
                                        name='country_2'
                                        placeholder='Country'
                                        disabled
                                      />
                                    </div>

                                    <ErrorMessage
                                      component='a'
                                      className='error-msg'
                                      name='country_2'
                                    />
                                  </div>

                                  <div className='team-field-group margin-10'>
                                    <label className='photo-label-title'>
                                      State or region
                                    </label>
                                    <div className='relative flex'>
                                      <Field
                                        className='team-field-control'
                                        id='state_2'
                                        name='state_2'
                                        placeholder='State'
                                      />
                                    </div>

                                    <ErrorMessage
                                      component='a'
                                      className='error-msg'
                                      name='state_2'
                                    />
                                  </div>

                                  <div className='team-field-group margin-10'>
                                    <label className='photo-label-title'>
                                      Zip code
                                    </label>
                                    <div className='relative flex'>
                                      <Field
                                        className='team-field-control'
                                        id='zipcode_2'
                                        name='zipcode_2'
                                        placeholder='Zip code'
                                      />
                                    </div>

                                    <ErrorMessage
                                      component='a'
                                      className='error-msg'
                                      name='zipcode_2'
                                    />
                                  </div>

                                  <div className='team-field-group margin-10'>
                                    <label className='photo-label-title'>
                                      City
                                    </label>
                                    <div className='relative flex'>
                                      <Field
                                        className='team-field-control'
                                        id='city_2'
                                        name='city_2'
                                        placeholder='City'
                                      />
                                    </div>

                                    <ErrorMessage
                                      component='a'
                                      className='error-msg'
                                      name='city_2'
                                    />
                                  </div>

                                  <div className='team-field-group margin-10'>
                                    <label className='photo-label-title'>
                                      Street
                                    </label>
                                    <div className='relative flex'>
                                      <Field
                                        className='team-field-control'
                                        id='street_2'
                                        name='street_2'
                                        placeholder='Street'
                                      />
                                    </div>

                                    <ErrorMessage
                                      component='a'
                                      className='error-msg'
                                      name='street_2'
                                    />
                                  </div>

                                  <div className='team-field-group margin-10'>
                                    <label className='photo-label-title'>
                                      Apartment, Suite, Floor, etc
                                    </label>
                                    <div className='relative flex'>
                                      <Field
                                        className='team-field-control'
                                        id='address_2'
                                        name='address_2'
                                        placeholder='Apartment, Suite, Floor, etc'
                                      />
                                    </div>

                                    <ErrorMessage
                                      component='a'
                                      className='error-msg'
                                      name='address_2'
                                    />
                                  </div>
                                </div>

                                <div className='flex-1 gap-2 w-1/2'>
                                  <button
                                    onClick={() => setPostalAddressInfo(false)}
                                    type='button'
                                    className='index-StyledButton_cls2__1Drrf index-StyledButton_cls1__1qkPE index-StyledButton_isFocusable__aUCeS index-StyledButton_size-small__EOSry index-StyledButton_variant-ghost__1dOzQ'
                                  >
                                    <span className=' flex items-center gap-4px'>
                                      Cancel
                                    </span>
                                  </button>
                                  <button
                                    disabled={false}
                                    type='submit'
                                    className='index-StyledButton_cls2__1Drrf index-StyledButton_cls1__1qkPE index-StyledButton_isFocusable__aUCeS index-StyledButton_size-small__EOSry index-StyledButton_variant-primary__xNYe7'
                                  >
                                    <span className=' flex items-center gap-4px'>
                                      Save changes
                                    </span>
                                  </button>
                                </div>
                              </Form>
                            </Formik>
                          )}

                          {/* End  Postal Address Form   */}
                        </div>
                      </div>
                    </div>
                    <div style={{ width: '38%' }}>
                      <AttentionCard
                        state={state}
                        setUploadLogoModal={setUploadLogoModal}
                        setNewTeamMemberModal={setNewTeamMemberModal}
                        setConnectDeviceModal={setConnectDeviceModal}
                        customWidth='100%'
                        thumbnail={true}
                      />
                      <Announcements
                        announcements={announcements}
                        loadingAnnouncements={loadingAnnouncements}
                        setAnnouncementModal={setAnnouncementModal}
                      />
                    </div>
                  </div>
                )}

                {pathname === '/practice/payments' && (
                  <div className='con flex dashboardFirstRowWrapper'>
                    <div className='playlistCardWrapper'>
                      <div className='flex flex-col gap-y-5 col-span-5 bg-black p-10 paymentCardWrapper '>
                        <div className='gap-12px flex '>
                          <div
                            className='credit-card-wrap'
                            style={{
                              backgroundImage: 'url(' + CardIcon + ')',
                            }}
                          >
                            <img
                              alt='brand'
                              src={VisaIconWhite}
                              className='credit-card-brand-img'
                            />
                            <div className='text-text100 flex gap-1 items-center mb-3'>
                              <div className='gap-4px flex'>
                                <span className='numberdot-icon'></span>
                                <span className='numberdot-icon'></span>
                                <span className='numberdot-icon'></span>
                                <span className='numberdot-icon'></span>
                              </div>
                              <div className='gap-4px flex'>
                                <span className='numberdot-icon'></span>
                                <span className='numberdot-icon'></span>
                                <span className='numberdot-icon'></span>
                                <span className='numberdot-icon'></span>
                              </div>
                              <div className='gap-4px flex'>
                                <span className='numberdot-icon'></span>
                                <span className='numberdot-icon'></span>
                                <span className='numberdot-icon'></span>
                                <span className='numberdot-icon'></span>
                              </div>
                              <p>{state.auth?.payment?.card_last4}</p>
                            </div>
                          </div>
                          <div className='flex flex-col justify-between'>
                            <div>
                              <p className='v-bs-h400 text-text900'>
                                Credit or debit card
                              </p>
                              <p className='v-bs-p200 text-text500'>
                                We use stripe online payment system. It is
                                secure and you can cancel anytime
                              </p>
                            </div>
                            <span>
                              <button
                                onClick={() => setEditCard(true)}
                                className='shadow-shadow200 lt-item-icon-box box-shadow-focus btn-layout-style btn-layout-border'
                              >
                                <span className=' flex items-center gap-4px'>
                                  Edit card
                                </span>
                              </button>
                            </span>
                          </div>
                        </div>
                        <div
                          className={`v-bs-p300 notification-box ${
                            typeof state.auth?.payment?.subscription.id ===
                            'undefined'
                              ? 'canceled'
                              : ''
                          }`}
                        >
                          {state?.auth?.payment?.subscription.id === null ? (
                            <>
                              Your subscription has been canceled
                              <button
                                disabled={resuming}
                                className='btn suspend-sus resume-btn'
                                onClick={() => setConfirmResume(true)}
                              >
                                {resuming === true ? (
                                  <>
                                    <svg
                                      className=' text-center w-3 h-3 mr-5 ml-10 text-white animate-spin'
                                      xmlns='http://www.w3.org/2000/svg'
                                      fill='none'
                                      viewBox='0 0 24 24'
                                    >
                                      <circle
                                        className='opacity-25'
                                        cx='12'
                                        cy='12'
                                        r='10'
                                        stroke='currentColor'
                                        stroke-width='4'
                                      ></circle>
                                      <path
                                        className='opacity-75'
                                        fill='currentColor'
                                        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                                      ></path>
                                    </svg>
                                  </>
                                ) : (
                                  <>
                                    <span className=' flex items-center gap-4px'>
                                      Resume subscription
                                    </span>
                                  </>
                                )}
                              </button>
                            </>
                          ) : (
                            <>
                              {state?.auth?.payment?.subscription
                                ?.pause_collection?.behavior === 'void' ? (
                                <>
                                  Your subscription is paused until&nbsp;
                                  {moment(
                                    parseInt(
                                      state.auth.payment?.subscription
                                        .pause_collection.resumes_at
                                    )
                                  ).format('MMM, DD YYYY')}
                                  <button
                                    className='suspend-sus resume-btn'
                                    onClick={() => setConfirmResume(true)}
                                  >
                                    {resuming ? (
                                      <>
                                        <svg
                                          className=' text-center w-3 h-3 mr-5 ml-10 text-white animate-spin'
                                          xmlns='http://www.w3.org/2000/svg'
                                          fill='none'
                                          viewBox='0 0 24 24'
                                        >
                                          <circle
                                            className='opacity-25'
                                            cx='12'
                                            cy='12'
                                            r='10'
                                            stroke='currentColor'
                                            stroke-width='4'
                                          ></circle>
                                          <path
                                            className='opacity-75'
                                            fill='currentColor'
                                            d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                                          ></path>
                                        </svg>
                                      </>
                                    ) : (
                                      <>
                                        <span className=' flex items-center gap-4px'>
                                          Resume subscription
                                        </span>
                                      </>
                                    )}
                                  </button>
                                </>
                              ) : (
                                <>
                                  The next payment{' '}
                                  {state.auth.payment?.subscription?.plan
                                    ?.amount || '$218.90'}{' '}
                                  will be made automatically on{' '}
                                  {moment(
                                    parseInt(
                                      state.auth.payment?.subscription
                                        ?.current_period_end
                                    ) * 1000
                                  ).format('MMM, DD YYYY')}
                                  <button
                                    className='suspend-sus'
                                    onClick={() => setSuspendSubscription(true)}
                                  >
                                    <span className=' flex items-center gap-4px'>
                                      Suspend subscription
                                    </span>
                                  </button>
                                </>
                              )}
                            </>
                          )}
                        </div>
                        {/* <button
                          onClick={() => setApplyCouponModal(true)}
                          className="apply-coupan-btn"
                        >
                          <span className=" flex items-center gap-4px">
                            Apply coupon
                          </span>
                        </button> */}

                        {state.auth.payment?.subscription?.discount && (
                          <>
                            <div className='bg-bg200 v-bs-label500 flex flex-col gap-y-2 p-2 rounded-2xl'>
                              <div className='flex justify-between'>
                                <span className='v-bs-h400'>
                                  Active coupon:{' '}
                                  {
                                    state.auth.payment?.subscription?.discount
                                      ?.coupon?.id
                                  }
                                </span>
                                <button
                                  onClick={() => setCancelDiscount(true)}
                                  className='index-StyledButton_cls2__1Drrf index-StyledButton_cls1__1qkPE index-StyledButton_isFocusable__aUCeS index-StyledButton_variant-link__1TBC0'
                                >
                                  <span className=' flex items-center gap-4px'>
                                    Cancel coupon
                                  </span>
                                </button>
                              </div>
                              <div className='gap-x-12px flex'>
                                <span className='v-bs-table-value100 flex'>
                                  Discount:{' '}
                                  {
                                    state.auth.payment?.subscription?.discount
                                      ?.coupon?.percent_off
                                  }
                                  %
                                </span>
                                <div className='Separator_cls2__89WVX Separator_cls1__g22QC Separator_noMargin__3zi12 Separator_orientation-vertical__16IVi'></div>
                                <span className='v-bs-table-value100'>
                                  Discount time:{' '}
                                  {
                                    state.auth.payment?.subscription?.discount
                                      ?.coupon?.duration
                                  }
                                </span>
                                <div className='Separator_cls2__89WVX Separator_cls1__g22QC Separator_noMargin__3zi12 Separator_orientation-vertical__16IVi'></div>
                                <span className='v-bs-table-value100 flex'>
                                  Date applied:{' '}
                                  {moment(
                                    parseInt(
                                      state.auth.payment?.subscription?.discount
                                        ?.coupon?.created
                                    ) * 1000
                                  ).format('MMM, DD YYYY')}
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                        <div className='coupon-1Wrapper'>
                          <p className='v-bs-h300 text-text900 historyText'>
                            Payment History
                          </p>
                          <div className='coupon-1'>
                            <div>
                              <div className='practice-payments-list-header'>
                                <div>Invoice date</div>
                                <div>Total</div>
                                <div>Status</div>
                                <div>Card</div>
                              </div>
                            </div>
                            <div className='flex flex-col card-1'>
                              <div
                                data-index='0'
                                data-known-size='52'
                                data-item-index='0'
                              >
                                {state.auth.transactions &&
                                  state.auth?.transactions?.map((x) => {
                                    return (
                                      <>
                                        <div className='pb-4px'>
                                          <div
                                            role='button'
                                            className='payment-list-item'
                                          >
                                            <div>
                                              {moment(
                                                parseInt(x?.createdOn) * 1000
                                              ).format('MMM, DD YYYY')}
                                            </div>
                                            <div>{x?.amount}</div>
                                            <div className='capitalize'>
                                              {x?.status === 'succeeded' ? (
                                                <svg
                                                  xmlnsXlink='http://www.w3.org/1999/xlink'
                                                  xmlns='http://www.w3.org/2000/svg'
                                                  aria-hidden='true'
                                                  focusable='false'
                                                  role='presentation'
                                                  className='text-status100 index-StyledSvg_cls2__nHIIG index-StyledSvg_cls1__3YcLt'
                                                  width='24'
                                                  height='24'
                                                >
                                                  <defs>
                                                    <symbol
                                                      id='check'
                                                      viewBox='0 0 24 24'
                                                    >
                                                      <path
                                                        fillRule='evenodd'
                                                        d='M9.875 17c-.406 0-.813-.167-1.137-.5l-3.25-3.333a1.64 1.64 0 0 1 0-2.334c.65-.666 1.706-.666 2.274 0L9.876 13l5.363-5.5c.65-.667 1.625-.667 2.274 0a1.64 1.64 0 0 1 0 2.333l-6.5 6.667c-.325.333-.73.5-1.137.5z'
                                                        clipRule='evenodd'
                                                      ></path>
                                                    </symbol>
                                                  </defs>
                                                  <use
                                                    xlinkHref='#check'
                                                    fill='#4DC7BF'
                                                  ></use>
                                                </svg>
                                              ) : (
                                                <img
                                                  src={xmark}
                                                  className='mr-1'
                                                />
                                              )}

                                              {x?.status}
                                            </div>
                                            <div className='gap-4px flex items-center'>
                                              <img
                                                alt='brand'
                                                src={
                                                  x?.status === 'succeeded'
                                                    ? VisaIcon
                                                    : masterIcon
                                                }
                                                className='index-BrandImage_cls2__3pJZt index-BrandImage_cls1__3rkeR index-BrandImage_size-sm__3Yd_x card_Icon'
                                              />
                                              <div className='gap-4px flex'>
                                                <p
                                                  style={{
                                                    fontSize: '10px',
                                                    lineHeight: '17px',
                                                  }}
                                                >
                                                  {' '}
                                                  &#9679; &#9679; &#9679;
                                                  &#9679;
                                                </p>
                                                {/* <span className="visaDot_icons "></span>
                                              <span className="visaDot_icons"></span>
                                              <span className="visaDot_icons"></span>
                                              <span className="visaDot_icons"></span> */}
                                                {
                                                  state.auth?.payment
                                                    ?.card_last4
                                                }
                                              </div>
                                            </div>
                                            <div>
                                              <svg
                                                xmlnsXlink='http://www.w3.org/1999/xlink'
                                                xmlns='http://www.w3.org/2000/svg'
                                                aria-hidden='true'
                                                focusable='false'
                                                role='presentation'
                                                className='index-StyledSvg_cls2__nHIIG index-StyledSvg_cls1__3YcLt'
                                                width='24'
                                                height='24'
                                                onClick={() => {
                                                  setInvoiceData(x);
                                                  setInvoice(true);
                                                }}
                                              >
                                                <defs>
                                                  <symbol
                                                    id='dropdown-right'
                                                    viewBox='0 0 24 24'
                                                  >
                                                    <g clipPath='url(#a)'>
                                                      <path
                                                        fillRule='evenodd'
                                                        d='M8.469 17.56a1.435 1.435 0 0 1 0-2.12L12.137 12 8.47 8.56a1.435 1.435 0 0 1 0-2.12 1.676 1.676 0 0 1 2.262 0l4.8 4.5a1.435 1.435 0 0 1 0 2.12l-4.8 4.5a1.677 1.677 0 0 1-2.262 0z'
                                                        clipRule='evenodd'
                                                      ></path>
                                                    </g>
                                                    <defs>
                                                      <clipPath id='a'>
                                                        <path
                                                          d='M0 0H8V12H0z'
                                                          transform='translate(8 6)'
                                                        ></path>
                                                      </clipPath>
                                                    </defs>
                                                  </symbol>
                                                </defs>
                                                <use xlinkHref='#dropdown-right'></use>
                                              </svg>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{ width: '38%' }}>
                      <AttentionCard
                        state={state}
                        setUploadLogoModal={setUploadLogoModal}
                        setNewTeamMemberModal={setNewTeamMemberModal}
                        setConnectDeviceModal={setConnectDeviceModal}
                        customWidth='100%'
                        thumbnail={true}
                      />
                      <Announcements
                        announcements={announcements}
                        loadingAnnouncements={loadingAnnouncements}
                        setAnnouncementModal={setAnnouncementModal}
                      />
                    </div>
                  </div>
                )}

                {pathname === '/practice/account' && (
                  <div className='con flex dashboardFirstRowWrapper'>
                    <div className='playlistCardWrapper'>
                      <div
                        className='flex flex-col gap-y-5 col-span-4'
                        style={{ width: '350px' }}
                      >
                        <div className='v-bs-h400 text-text900 grid grid-rows-2 items-center justify-start'>
                          <span>Login</span>
                          <span className='v-bs-input-label300 text-text500'>
                            {Cookies.get('email')}
                          </span>
                        </div>
                        <div className='flex flex-col gap-y-2'>
                          <div className='practice-settings-section-title'>
                            <span>Password</span>
                            <span>
                              {changePassword === false && (
                                <button
                                  className='edit-btn-setting'
                                  onClick={() => setChangePassword(true)}
                                >
                                  <span className=' flex items-center gap-4px'>
                                    <svg
                                      xmlnsXlink='http://www.w3.org/1999/xlink'
                                      xmlns='http://www.w3.org/2000/svg'
                                      aria-hidden='true'
                                      focusable='false'
                                      role='presentation'
                                      className='index-StyledSvg_cls2__nHIIG index-StyledSvg_cls1__3YcLt'
                                      width='24'
                                      height='24'
                                    >
                                      <defs>
                                        <symbol id='pencil' viewBox='0 0 24 24'>
                                          <path
                                            fillRule='evenodd'
                                            d='M7.5 22.5h-6v-6L15.62 2.379a3 3 0 0 1 4.243 0l1.758 1.757a3 3 0 0 1 0 4.243L7.5 22.499zm-2.49-5.268 1.757 1.758 8.857-8.857-1.758-1.757-8.856 8.856zM19.5 6.257l-1.755 1.755-1.758-1.757L17.742 4.5 19.5 6.257z'
                                            clipRule='evenodd'
                                          ></path>
                                        </symbol>
                                      </defs>
                                      <use
                                        xlinkHref='#pencil'
                                        fill='#626262'
                                      ></use>
                                    </svg>
                                    Edit{' '}
                                  </span>
                                </button>
                              )}
                            </span>
                          </div>
                          {changePassword === false && (
                            <div className='flex gap-1'>
                              <span className='practice-account-password-Password'></span>
                              <span className='practice-account-password-Password'></span>
                              <span className='practice-account-password-Password'></span>
                              <span className='practice-account-password-Password'></span>
                              <span className='practice-account-password-Password'></span>
                              <span className='practice-account-password-Password'></span>
                              <span className='practice-account-password-Password'></span>
                            </div>
                          )}
                          {changePassword && (
                            <>
                              <Formik
                                initialValues={initial_account_data}
                                validationSchema={
                                  validationAccountPasswordSchema
                                }
                                onSubmit={updateAccountPassword}
                                enableReinitialize
                              >
                                <Form>
                                  <div className='team-field-group margin-10'>
                                    <label className='photo-label-title'>
                                      Current Password
                                    </label>
                                    <div className='relative flex'>
                                      <Field
                                        className='team-field-control'
                                        id='current_password'
                                        name='current_password'
                                        placeholder='Enter your current password'
                                        type='password'
                                      />
                                    </div>

                                    <ErrorMessage
                                      component='a'
                                      className='error-msg'
                                      name='current_password'
                                    />
                                  </div>

                                  <div className='team-field-group mt-3'>
                                    <label className='photo-label-title'>
                                      New Password
                                    </label>
                                    <div className='relative flex'>
                                      <Field
                                        type='password'
                                        autoCapitalize='false'
                                        autoComplete='false'
                                        autoCorrect='off'
                                        name='new_password'
                                        placeholder='Create your new password'
                                        className='team-field-control'
                                      />
                                    </div>
                                    <ErrorMessage
                                      component='a'
                                      className='error-msg'
                                      name='new_password'
                                    />
                                  </div>

                                  <div className='team-field-group mt-3 mb-3'>
                                    <label className='photo-label-title'>
                                      Retype new Password
                                    </label>
                                    <div className='relative flex'>
                                      <Field
                                        autoCapitalize='false'
                                        autoComplete='false'
                                        autoCorrect='off'
                                        name='retype_password'
                                        placeholder='Enter your new password again'
                                        className='team-field-control'
                                        type='password'
                                      />
                                    </div>
                                    <ErrorMessage
                                      component='a'
                                      className='error-msg mb-3'
                                      name='retype_password'
                                    />
                                  </div>

                                  <div className='flex-1 gap-2 w-1/2 mt-3'>
                                    <button
                                      onClick={() => setChangePassword(false)}
                                      type='button'
                                      className='index-StyledButton_cls2__1Drrf index-StyledButton_cls1__1qkPE index-StyledButton_isFocusable__aUCeS index-StyledButton_size-small__EOSry index-StyledButton_variant-ghost__1dOzQ'
                                    >
                                      <span className=' flex items-center gap-4px'>
                                        Cancel
                                      </span>
                                    </button>
                                    <button
                                      disabled={updatePassword}
                                      type='submit'
                                      className='index-StyledButton_cls2__1Drrf index-StyledButton_cls1__1qkPE index-StyledButton_isFocusable__aUCeS index-StyledButton_size-small__EOSry index-StyledButton_variant-primary__xNYe7'
                                    >
                                      <span className=' flex items-center gap-4px'>
                                        Change password
                                      </span>
                                    </button>
                                  </div>
                                </Form>
                              </Formik>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div style={{ width: '38%' }}>
                      <AttentionCard
                        state={state}
                        setUploadLogoModal={setUploadLogoModal}
                        setNewTeamMemberModal={setNewTeamMemberModal}
                        setConnectDeviceModal={setConnectDeviceModal}
                        thumbnail={true}
                        customWidth='100%'
                      />
                      <Announcements
                        announcements={announcements}
                        loadingAnnouncements={loadingAnnouncements}
                        setAnnouncementModal={setAnnouncementModal}
                      />
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className='con flex dashboardFirstRowWrapper'>
                  <div className='playlistCardNewWrap'>
                    <PlaylistCard
                      state={state}
                      onPreviewClick={() => navigate('/library/office')}
                      code={state?.practice?.detail?.ChannelKey}
                      settings={settings}
                      setConnectDeviceModal={setConnectDeviceModal}
                    />
                    <div className=' container min-h-fit inline-block   bg-[#fff] px-[35px] py-[26px] mb-5 rounded-[20px]'>
                      <div className=''>
                        <div className=''>
                          <div className=''>
                            <div className='flex flex-col'>
                              <h5 className='text-[20px] font-[600] text-[#212b36] mb-5'>
                                Team members
                              </h5>

                              <div className='flex flex-wrap gap-4 teamMembersBox'>
                                <div className='flex flex-col items-center'>
                                  <div
                                    className='dottedAddTeamMemberWrapper'
                                    onClick={() => {
                                      setNewTeamMemberModal(true);
                                    }}
                                  >
                                    <div className='dottedAddTeamMemberCircle'>
                                      <span>+</span>
                                      <div>Add new</div>
                                    </div>
                                    <div className='dottedAddTeamMemberText'>
                                      Name here
                                    </div>
                                    <div className='dottedAddTeamMemberText2'>
                                      Title here
                                    </div>
                                  </div>
                                  {/* <button
                                  className="team-btn-box team-btn-box-border"
                                  onClick={() => {
                                    setNewTeamMemberModal(true);
                                  }}
                                >
                                  <svg
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    focusable="false"
                                    role="presentation"
                                    className="svg-icon-24 index-StyledSvg_cls2__nHIIG index-StyledSvg_cls1__3YcLt"
                                    style={{ width: "24px", height: "24px" }}
                                    width="24"
                                    height="24"
                                  >
                                    <defs>
                                      <symbol id="plus" viewBox="0 0 24 24">
                                        <path
                                          fillRule="evenodd"
                                          d="M13 4c0-.555-.448-1-1-1-.556 0-1 .448-1 1v7H4c-.555 0-1 .448-1 1 0 .556.448 1 1 1h7v7c0 .555.448 1 1 1 .556 0 1-.448 1-1v-7h7c.555 0 1-.448 1-1 0-.556-.448-1-1-1h-7V4z"
                                          clipRule="evenodd"
                                        ></path>
                                      </symbol>
                                    </defs>
                                    <use xlinkHref="#plus"></use>
                                  </svg>
                                </button> */}
                                  {/* <span className="team-btn-content">
                                  Add team member
                                </span> */}
                                </div>
                                {state.practice?.team_members && (
                                  <>
                                    {state?.practice?.team_members.map((m) => {
                                      return (
                                        <button
                                          className='flex flex-col gap-1 items-center bg-transparent border-none focus:outline-none'
                                          type='button'
                                          onClick={() => {
                                            teamMemberModal.component = (
                                              <MemberDetails
                                                id={m.team_member_id}
                                              />
                                            );
                                            trigger(teamMemberModal);
                                          }}
                                        >
                                          <div className='relative'>
                                            <div className='absolute bottom-1/2 left-0 w-1/2 h-0 transform origin-right -rotate-45'>
                                              <div className='absolute rounded-full transform -translate-x-1/2 -translate-y-1/2 rotate-45'>
                                                <span className='stars-counter-box stars-counter-box-bg'>
                                                  {m.included_in_videos}
                                                </span>
                                              </div>
                                            </div>
                                            <div
                                              className='practive-box-holder  practive-box-holder-size practice-box-holder-border'
                                              style={{
                                                backgroundImage: `url(${
                                                  m.photo_thumbnail
                                                    ? m.photo_thumbnail
                                                    : DetaultProfile
                                                })`,
                                              }}
                                            ></div>
                                          </div>
                                          <div className='team-member-list-item'>
                                            {m.MemberName}
                                          </div>
                                        </button>
                                      );
                                    })}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=' container min-h-fit inline-block  bg-[#fff] px-[35px] py-[26px] rounded-[20px] mb-5'>
                      <div className=''>
                        <div className=''>
                          <div className=''>
                            <div className='flex flex-col'>
                              <h5 className='text-[20px] font-[600] text-[#212b36] mb-5'>
                                Other Photos
                              </h5>

                              <div className='flex flex-wrap gap-4 teamMembersBox'>
                                <UploadLogo />
                                <OtherPhotos />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ width: '38%' }}>
                    <AttentionCard
                      state={state}
                      setUploadLogoModal={setUploadLogoModal}
                      setNewTeamMemberModal={setNewTeamMemberModal}
                      setConnectDeviceModal={setConnectDeviceModal}
                      customWidth='100%'
                      thumbnail={true}
                    />
                    <Announcements
                      announcements={announcements}
                      loadingAnnouncements={loadingAnnouncements}
                      setAnnouncementModal={setAnnouncementModal}
                    />
                  </div>
                </div>
              </>
            )}
            {/* <div className="container relative bottom-[90px] inline-block w-[694px] h-[275px] bg-[#fff] px-[35px] py-[26px] rounded-[20px]">
              <p className="text-[20px] font-[600] text-[#212b36]">
                Other Photos
              </p>
              <div className="container">
                <div className="inner-container w-[625px] rounded-[6px] px-[26px] mt-[20px] py-[20px] h-[178px] inline-block bg-[#FCFCFC]">
                  <div className="w-[97px] h-[97px] inline-flex justify-center flex-col items-center border-dashed border-2 border-[#000] rounded-[48px]">
                    <input
                      className="video w-[20px] leading-[20px] text-[30px] cursor-pointer before:content-['+']"
                      type="file"
                      accept="video/*"
                    />
                    <p className="text-[12px] font-[400] pt-[4px] text-[#637381]">
                      Add New
                    </p>
                  </div>
                  <div className="mt-[12px] px-3">
                    <p className="text-[14px] text-center font-[400] text-[#637381]">
                      Name here
                    </p>
                    <p className="text-[12px] text-center font-[400] text-[#212b36]">
                      Title here
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
            {pathname === '/practice/support' && (
              <div className='con flex dashboardFirstRowWrapper'>
                <div className='supportCardWrapper'>
                  <div className='flex flex-col  justify-between'>
                    <div className='gap-4 flex'>
                      <div className=''>
                        <p className='v-bs-h400 text-text900 text-xl w-60'>
                          We are here to help
                        </p>
                        <p className='v-bs-p200 text-xl text-text500 mt-2'>
                          We are here to help Browse our range of help articles
                          below. If you need to request support, click the
                          request support button.
                        </p>
                      </div>
                      <div className='buttonWrapper'>
                        <button
                          onClick={() => setContactSupport(true)}
                          type='submit'
                          className='index-StyledButton_cls2__1Drrf index-StyledButton_cls1__1qkPE index-StyledButton_isFocusable__aUCeS index-StyledButton_size-small__EOSry index-StyledButton_variant-primary__xNYe7'
                        >
                          <span className=' flex items-center gap-4px'>
                            Contact Support
                          </span>
                        </button>
                      </div>
                    </div>
                    <div className='cardsWrapper'>
                      {supportImages?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className='supportCard cursor-pointer'
                            onClick={() => {
                              if (item.link) {
                                window.open(item.link, '_blank');
                              }
                            }}
                          >
                            {item?.img && (
                              <img
                                src={item?.img}
                                className='cardImageSupport'
                              />
                            )}
                            <p>{item?.title}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div style={{ width: '38%' }}>
                  <AttentionCard
                    state={state}
                    setUploadLogoModal={setUploadLogoModal}
                    setNewTeamMemberModal={setNewTeamMemberModal}
                    setConnectDeviceModal={setConnectDeviceModal}
                    thumbnail={true}
                    customWidth='100%'
                  />
                  <Announcements
                    announcements={announcements}
                    loadingAnnouncements={loadingAnnouncements}
                    setAnnouncementModal={setAnnouncementModal}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </PracticeTheme>

      <UploadLogoModal
        openUploadLogoModal={openUploadLogoModal}
        setUploadLogoModal={setUploadLogoModal}
        title='Add logo'
      />

      <InvoiceModal
        invoice={invoice}
        setInvoice={setInvoice}
        data={invoiceData}
      />

      <SuspendSubscription
        suspendSubscription={suspendSubscription}
        setSuspendSubscription={setSuspendSubscription}
      />

      {/*  Add Team Member Modal    */}

      <ApplyCouponModal
        applyCouponModal={applyCouponModal}
        setApplyCouponModal={setApplyCouponModal}
      />

      <UpdateCardModal
        setUpdateCardModal={setEditCard}
        updateCardModal={editCard}
      />

      <NewTeamMemberModal
        newTeamMemberModal={newTeamMemberModal}
        setNewTeamMemberModal={setNewTeamMemberModal}
      />

      <ContactSupportModal
        setContactSupport={setContactSupport}
        contactSupport={contactSupport}
      />

      <SubmittedModal confirmPop={confirmPop} setConfirmPop={setConfirmPop} />
      <ToastContainer
        position='bottom-center'
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme='dark'
      />

      <PromptModal
        promptModal={confirmResume}
        setPromptModal={setConfirmResume}
        btnText='Yes'
        btnTextCancel='No'
        title={`Resume Subscription`}
        description='Are you sure you want to resume your subscription?'
        onSuccess={resumeSubscriptionRequest}
      />

      <PromptModal
        promptModal={cancelDiscount}
        setPromptModal={setCancelDiscount}
        btnText='Delete'
        btnTextCancel='Cancel'
        title={`Cancel coupon?`}
        description='The discount code will be cancelled'
        onSuccess={removeDiscount}
      />

      <ConnectDeviceModal
        code={state?.practice?.detail?.ChannelKey}
        openConnectDeviceModal={openConnectDeviceModal}
        setConnectDeviceModal={setConnectDeviceModal}
      />

      <AnnouncementModal
        openAnnouncementModal={openAnnouncementModal}
        setAnnouncementModal={setAnnouncementModal}
        announcement={announcement}
      />

      <AnnouncementModal
        openAnnouncementModal={openAnnouncementModal}
        setAnnouncementModal={setAnnouncementModal}
        announcement={announcement}
      />
    </>
  );
};

export default PracticeDashboard;
