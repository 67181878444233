import axios from 'axios';
import { ActionType } from 'redux/actionTypes/index';
import { Dispatch } from 'redux';
import config from 'config/config';

export function getPracticeDetail() {
  return async function (dispatch: Dispatch) {
    try {
      await axios
        .get(`${config.defaults.api_url}/practices/detail`)
        .then((res: any) => {
          console.log(res);

          dispatch({
            type: ActionType.GET_PRACTICE_DETAIL,
            payload: res,
          });
        });
    } catch (error) {
      console.log(error);

      dispatch({
        type: ActionType.GET_PRACTICE_DETAIL_ERROR,
        payload: {
          message: 'Unable to get practice detail',
        },
      });
    }
  };
}

export function getPracticeAddress(id, type) {
  return async function (dispatch: Dispatch) {
    try {
      await axios
        .get(`${config.defaults.api_url}/practices/address/${id}`)
        .then((res: any) => {
          console.log(res);

          if (type === 'postal') {
            dispatch({
              type: ActionType.GET_PRACTICE_POSTAL_ADDRESS,
              payload: res,
            });
          } else if (type === 'physical') {
            dispatch({
              type: ActionType.GET_PRACTICE_PHYSICAL_ADDRESS,
              payload: res,
            });
          }
        });
    } catch (error) {
      console.log(error);

      if (type === 'postal') {
        dispatch({
          type: ActionType.GET_PRACTICE_POSTAL_ADDRESS_ERROR,
          payload: {
            message: 'Unable to get practice postal address',
          },
        });
      } else if (type === 'physical') {
        dispatch({
          type: ActionType.GET_PRACTICE_DETAIL_ERROR,
          payload: {
            message: 'Unable to get practice physical address',
          },
        });
      }
    }
  };
}

export async function updateAddress(data: any, id: any) {
  return axios.put(`${config.defaults.api_url}/practices/address/${id}`, data);
}

export async function updatePracticeBasicInfo(data: any, id: any) {
  return axios.put(`${config.defaults.api_url}/practices/basic/info`, data);
}

export async function updatePracticeContactInfo(data: any, id: any) {
  return axios.put(`${config.defaults.api_url}/practices/contact/info`, data);
}

export async function getTeamMemberVideos(id: any) {
  return axios.get(`${config.defaults.api_url}/practices/member/${id}/videos`);
}

export async function uploadPracticeLogo(file) {
  const body = new FormData();

  body.append('image', file);

  return axios({
    method: 'post',
    url: `${config.defaults.api_url}/practices/upload/practice/logo`,
    data: body,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export async function createPracticeTeamMember(params: any) {
  return axios.post(
    `${config.defaults.api_url}/practices/team-members/new`,
    params
  );
}

export async function updatePracticeTeamMember(params: any, id: any) {
  return axios.post(
    `${config.defaults.api_url}/practices/team-members/update/${id}`,
    params
  );
}

export async function deletePracticeTeamMember(id: any) {
  return axios({
    method: 'post',
    url: `${config.defaults.api_url}/practices/team-members/delete/${id}`,
  });
}

export async function createInvoice(params: any) {
  return axios.post(
    `${config.defaults.api_url}/practices/create/invoice`,
    params
  );
}

export function getTeamMembers() {
  return async function (dispatch: Dispatch) {
    try {
      await axios
        .get(`${config.defaults.api_url}/practices/team-members`)
        .then((res: any) => {
          dispatch({
            type: ActionType.GET_TEAM_MEMBERS,
            payload: res,
          });
        });
    } catch (error) {
      console.log(error);

      dispatch({
        type: ActionType.GET_TEAM_MEMBERS,
        payload: {
          message: 'Unable to get team members',
        },
      });
    }
  };
}

export function getOtherPhotos() {
  return async function (dispatch: Dispatch) {
    try {
      await axios
        .get(`${config.defaults.api_url}/practices/other/photos`)
        .then((res: any) => {
          dispatch({
            type: ActionType.GET_OTHER_PHOTOS,
            payload: res,
          });
        });
    } catch (error) {
      console.log(error);

      dispatch({
        type: ActionType.GET_OTHER_PHOTOS,
        payload: {
          message: 'Unable to get other photos',
        },
      });
    }
  };
}
