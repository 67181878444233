import { ImageSkeleton, Skeleton } from 'components';

const VideoSkeleton = () => {
  return (
    <ul className='mt-[32px] space-y-[24px]'>
      {Array.from({ length: 2 }, (_, index) => (
        <li
          className='bg-white rounded-[10px] px-[18px] py-[24px] shadow-video flex items-center gap-[20px]'
          key={index}
        >
          <ImageSkeleton className='h-[103px] w-[189px] rounded-[10px]' />
          <div className='flex-grow self-start text-start space-y-[7px]'>
            <Skeleton className='mt-[7px] max-w-[100px]' />
            <Skeleton className='mt-[7px] max-w-[150px]' />
          </div>
          <Skeleton className='max-w-[32px] h-[32px] rounded-full' />
        </li>
      ))}
    </ul>
  );
};

export default VideoSkeleton;
