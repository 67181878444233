import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers/combine';

const OtherPhotos = () => {
  const state = useSelector((state: RootState) => state);

  if (state?.practice?.other_photos?.length > 0)
    return state?.practice?.other_photos?.map(({ image }, index) => (
      <img
        key={index}
        src={image}
        alt='other'
        className='w-[97px] h-[97px] object-cover rounded-full'
      />
    ));
};

export default OtherPhotos;
