/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';

interface RootState {
  practice: any;
  videos: any;
}
const PromptModal = (props: any) => {
  const state = useSelector((state: RootState) => state);

  useEffect(() => {
    const init = async () => {
      // check if video already included in playlist
    };

    init();
  }, [props]);

  return (
    <React.Fragment>
      <>
        <Modal
          isOpen={props.promptModal}
          onRequestClose={() => props.setPromptModal(false)}
          style={{
            overlay: {
              position: 'relative',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(255, 255, 255, 0.75)',
              overflow: 'hidden',
            },
          }}
        >
          <div className='z-confirm animate-fade-in bg-bg900 fixed left-0 top-0 flex flex-col items-center justify-center w-full h-screen bg-opacity-90 overflow-y-auto'>
            <div className='confirm-modal-StyledConfirm_cls2__2TkMw confirm-modal-StyledConfirm_cls1__3hxBv'>
              <h5 className='font-karla font-bold text-[28px] leading-[32.48px] text-black'>
                {props.title}
              </h5>
              <p className='font-karla text-[16px] leading-[20.48px] text-[#3F3F3F]'>
                {props.description}
              </p>
              <div className='gap-12px grid grid-cols-2 mt-[16px]'>
                <button
                  className='cancel-btn-rm-vs'
                  onClick={() => props.setPromptModal(false)}
                >
                  <span className='flex items-center gap-4px'>
                    {props.btnTextCancel ? props.btnTextCancel : 'Cancel'}
                  </span>
                </button>
                <button
                  className='resume-btn-rm-vs'
                  onClick={() => {
                    props.setPromptModal(false);
                    props.onSuccess();
                  }}
                >
                  <span className='flex items-center gap-4px'>
                    {props.btnText}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    </React.Fragment>
  );
};

export default PromptModal;
