import ProfilePage from "pages/profile";
import IRoute from "../interfaces/route";
import LoginPage from "../pages/login";
import PracticeDashboard from "pages/practice";
import TvPage from "pages/practice/tv";
import PlaylistPage from "pages/practice/playlist";
import Library from "pages/practice/library";
import AnnouncementsPage from "pages/practice/announcements";
import ThankyouPage from "pages/thank-you";
import ForgotPasswordPage from "pages/forgot-password";
import ResetPasswordPage from "pages/reset-password";
const routes: IRoute[] = [
  {
    path: "/",
    name: "Login",
    component: LoginPage,
  },
  {
    path: "/profile",
    name: "Profile",
    component: ProfilePage,
  },

  {
    path: "/tv",
    name: "Tv",
    component: TvPage,
  },
  {
    path: "/dashboard",
    name: "Practice Dashboard",
    component: PracticeDashboard,
  },

  {
    path: "/practice/payments",
    name: "Practice Payments",
    component: PracticeDashboard,
  },

  {
    path: "/practice/account",
    name: "Account",
    component: PracticeDashboard,
  },
  {
    path: "/practice/support",
    name: "Support",
    component: PracticeDashboard,
  },

  {
    path: "/announcements",
    name: "Announcements",
    component: AnnouncementsPage,
  },

  {
    path: "/practice/details",
    name: "Practice Details",
    component: PracticeDashboard,
  },

  {
    path: "/playlist/office",
    name: "Playlist Office",
    component: PlaylistPage,
  },

  {
    path: "/thank-you",
    name: "Channeld",
    component: ThankyouPage,
  },

  {
    path: "/library/office",
    name: "Library Office",
    component: Library,
  },

  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: ForgotPasswordPage,
  },

  {
    path: "/reset-password/:id",
    name: "Reset Password",
    component: ResetPasswordPage,
  },
];

export default routes;
