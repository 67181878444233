import { ReactNode, useState } from 'react';

type PropsType = {
  children?: ReactNode;
};

const Tooltip = ({ children }: PropsType) => {
  const [show, setShow] = useState(false);

  return (
    <div
      className='relative'
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <img
        alt=''
        src={require('../../assets/img/infoIcon.png')}
        className='infoIconImg'
      />
      {show ? (
        <div className='absolute top-1/2 -translate-y-1/2 left-[20px] pl-[13px]'>
          <div className='text-black bg-white rounded-[20px] border border-[#ccc] text-xs p-2.5 w-[278px] leading-[18px] relative text-start z-[1000000]'>
            <div className='absolute -left-[7px] top-1/2 -translate-y-1/2 w-[12px] h-[12px] bg-white border-l border-t -rotate-45 border-[#ccc] ' />
            {children}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Tooltip;
