import { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import { CloudArrowUpIcon } from '@heroicons/react/24/outline';
import {
  Body,
  Button,
  Headline,
  Input,
  Label,
  ModalType,
  useModalActions,
} from 'components';
import { DeleteMember } from 'features/team-members';
import {
  getTeamMembers,
  updatePracticeTeamMember,
} from 'redux/actionCreators/practice';

type PropsType = {
  id?: number;
  src?: string;
  name?: string;
  role?: string;
  shortname?: string;
};

const EditMember = ({ id, src, name, role, shortname }: PropsType) => {
  const [file, setFile] = useState(null);

  const [previewUrl, setPreviewUrl] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [imageType, setImageType] = useState(null);

  const fileTypes = ['JPG', 'PNG', 'JPEG'];

  const handleChange = (file) => {
    let img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      if (img.width < 960 && img.height < 960) {
        toast.error(`Please upload a photo larger than 960 x 960px”`);
      } else {
        setPreviewUrl(URL.createObjectURL(file));
        setFile(file);
        setImageName(file.name);
        setImageType(file.type);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          setImage(reader.result);
        };
      }
    };
  };

  const initial_values = {
    name: name ? name : '',
    informal_name: shortname ? shortname : '',
    position: role ? role : '',
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Full name is required'),
    informal_name: Yup.string().required('Informal name is required'),
    position: Yup.string().required('Postition required'),
  });

  const dispatch = useDispatch();
  const { close, trigger } = useModalActions();
  const saveChanges = async (values: any) => {
    setIsLoading(true);
    await updatePracticeTeamMember(
      {
        ...values,
        image,
        imageType,
        imageName,
      },
      id
    );
    setIsLoading(false);
    dispatch(getTeamMembers());
    close();
    toast.success(`Member was updated successfully.`);
  };

  const deleteMemberModal: ModalType = {
    type: 'prompt',
    component: <DeleteMember id={id} name={name} />,
  };

  return (
    <div className='grid grid-cols-[auto_352px] divide-x divide-[#E7E7E7]'>
      <div className='p-[32px] pt-[37px] pr-[19px]'>
        <Headline size='lg'>{name}</Headline>
        <div className='mt-[40px] space-y-[24px]'>
          <div>
            <Label htmlFor='file'>Photo</Label>
            <div className='team-photo-upload-box mt-[17px]'>
              <FileUploader
                name='file'
                handleChange={handleChange}
                types={fileTypes}
                className='practive-box-holder practive-box-holder-size'
                maxSize={25}
              >
                <div className='flex flex-col items-center'>
                  {previewUrl && (
                    <img
                      className='rounded-xl'
                      src={previewUrl}
                      alt=''
                      width={128}
                      height={128}
                    />
                  )}
                  {previewUrl === '' && src ? (
                    <>
                      <img
                        className='rounded-xl w-[128px] h-[128px] object-cover'
                        src={src}
                        alt={name}
                      />

                      <p className='v-bs-btn100 text-text900 mt-2'>
                        {file ? file.name : ''}
                      </p>
                    </>
                  ) : (
                    <>
                      {previewUrl === '' && (
                        <CloudArrowUpIcon className='w-[40px] h-[40px] text-tailgrids-black' />
                      )}
                      <p className='text-tailgrids-black font-karla mt-1'>
                        {file
                          ? file.name
                          : 'Drag and drop here or choose photo'}
                      </p>
                    </>
                  )}
                </div>
              </FileUploader>
            </div>
          </div>
          <Formik
            initialValues={initial_values}
            validationSchema={validationSchema}
            onSubmit={saveChanges}
          >
            <Form>
              <div className='team-field-group mb-3'>
                <Label htmlFor='name'>Name</Label>
                <Input
                  className='mt-[4px]'
                  id='name'
                  name='name'
                  placeholder='Team member name'
                />
                <ErrorMessage component='a' className='error-msg' name='name' />
              </div>
              <div className='team-field-group mb-3'>
                <Label htmlFor='informal_name'>Informal Name</Label>
                <Input
                  className='mt-[4px]'
                  id='informal_name'
                  name='informal_name'
                  placeholder='Name displayed in the videos'
                />
                <ErrorMessage
                  component='a'
                  className='error-msg'
                  name='informal_name'
                />
              </div>
              <div className='team-field-group mb-3'>
                <Label htmlFor='position'>Position</Label>
                <Input
                  className='mt-[4px]'
                  id='position'
                  name='position'
                  placeholder='Team member role or position'
                />
                <ErrorMessage
                  component='a'
                  className='error-msg'
                  name='position'
                />
              </div>
              <div className='space-y-[16px]'>
                <Button className='w-full' isLoading={isLoading} type='submit'>
                  Save Changes
                </Button>
                <Button
                  className='w-full bg-[#FF4A4A] hover:bg-[#FF4A4A]/80 hover:text-white focus:bg-[#FF4A4A] focus:text-white'
                  onClick={() => trigger(deleteMemberModal)}
                >
                  Delete Member
                </Button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
      <div className='py-[32px] px-[48px] space-y-[16px]'>
        <div className='space-y-[12px]'>
          <Headline className='text-black' size='sm'>
            Instruction
          </Headline>
          <Body className='text-base text-black' size='regular'>
            Upload a headshot like the example below.
          </Body>
        </div>
        <img
          src={require('../../assets/img/team-member-profile.png')}
          alt='thumbnail'
          className='w-[255px] aspect-square rounded-3xl'
        />
        <Body className='text-base text-black' size='regular'>
          Min resolution: 1000x1000px Max size: 25mb
        </Body>
        <Body className='text-base text-black' size='regular'>
          If you don’t have a photo of your team member on hand, you can always
          upload one later.
        </Body>
      </div>
    </div>
  );
};

export default EditMember;
