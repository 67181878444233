import { cn } from 'util/cn';

type PropsType = {
  className?: string;
};

const Skeleton = ({ className }: PropsType) => {
  return (
    <div
      className={cn(
        'h-2.5 bg-gray-200 rounded-full w-full max-w-xs',
        className
      )}
    />
  );
};

export default Skeleton;
