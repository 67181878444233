/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import 'assets/css/dashboard.scss';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SideCaughtUp from './SideCaughtUp';
const AttentionCard = (props) => {
  const navigate = useNavigate();

  const [count, setCount] = useState(0);

  const [completed, setCompleted] = useState({
    upload_logo: false,
    connect_device: false,
    create_playlist: false,
    add_members: false,
  });

  const cards = [
    {
      title: 'Add your logo to add in all custom videos',
      name: 'upload-logo',
      action: () => props?.setUploadLogoModal(true),
      id: 'upload_logo',
      completed: props.state.practice?.detail?.FilePath ? true : false,
    },
    {
      title: 'Add team members into your practice',
      name: 'add-team-members',
      action: () => props.setNewTeamMemberModal(true),
      id: 'add_members',
      completed: props.state?.practice?.team_members.length > 0 ? true : false,
    },
    {
      title: 'Create playlist for team members',
      name: 'create-playlist',
      action: () => navigate('/library/office'),
      id: 'create_playlist',
      completed: props.state.videos?.now_playing.length > 0 ? true : false,
    },
    {
      title: 'Connect device and practice playlist',
      name: 'connect-device',
      action: () => props?.setConnectDeviceModal(true),
      id: 'connect_device',
      completed: props.state.auth?.user?.device_connected ? true : false,
    },
  ];
  useEffect(() => {
    const count = cards.filter((x) => x.completed === false).length;

    setCount(count);
  }, [props]);

  if (count === 0) {
    return <SideCaughtUp />;
  }

  return (
    <div
      className='attentionCardWrapper'
      style={{ width: props?.customWidth || '38%' }}
    >
      <div className='flex gap-[8px] items-center font-karla font-bold text-[20px] text-tailgrids-black'>
        {count !== 0 && (
          <div className='text-white font-karla font-bold text-sm bg-black w-[32px] aspect-square rounded-full flex items-center justify-center'>
            {count}
          </div>
        )}
        {count === 0 ? 'Everything is good!' : 'Needs your attention'}
      </div>

      <div
        className='attentionCardWrapperContent'
        style={{ marginTop: 15, padding: 0 }}
      >
        {count === 0 ? (
          <>
            <p className='caughtupText'>You're all caught up</p>
          </>
        ) : (
          <></>
        )}

        {cards.map((card: any) => {
          return (
            <>
              {card.completed === false && (
                <>
                  <div className='w-full cursor-pointe mb-3'>
                    <div className='helper_attention_content  flex w-full align-center py-1 px-2 rounded-xl justify-between'>
                      <div className='w-auto p-1' style={{ fontWeight: '700' }}>
                        {card.title}
                      </div>
                      <div className='AttentionInnerCardContentBtnArrowIcon self-center'>
                        <button className='flex gap-1' onClick={card.action}>
                          <p style={{ fontWeight: '400' }}>Do This</p>
                          <div>
                            <img
                              alt=''
                              src={require('assets/img/rightarrow.png')}
                              className='rightarrowImg'
                            />
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default AttentionCard;
