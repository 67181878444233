import { ReactPhotoCollage } from 'react-photo-collage';
import moment from 'moment';
import { ModalType, useModalActions } from 'components';
import Tooltip from './tooltip';
import { Preview } from 'features/playlist-card';
import 'assets/css/dashboard.scss';

const PlaylistCard = (props) => {
  const { trigger } = useModalActions();
  const previewModal: ModalType = {
    type: 'preview',
    component: <Preview state={props.state} />,
  };
  return (
    <>
      <div className='playlistCardInside'>
        <div className='title'>Your Playlists</div>
        <div className='contentRow'>
          <div className='contentCol1'>
            {/* header start here */}
            <div className='contentCol1HeaderRow'>
              <div className='contentCol1HeaderRowCol1'>
                <div className='inOfficeIcon'>
                  <img
                    alt=''
                    src={require('../../assets/img/inOfficeIcon.png')}
                    className='inOfficeIconImg'
                  />
                </div>
                <div className='inofficeText'>In Office</div>
              </div>
              <div className='contentCol1HeaderRowCol2'>
                <div className='Col2IdNumber'>{props.code}</div>
                <Tooltip>
                  Use this code to stream your playlist on your tv/s with the
                  Channel D app.{' '}
                  <button
                    className='outline-none focus:outline-none text-[#00f]'
                    onClick={() => props.setConnectDeviceModal(true)}
                  >
                    Click here
                  </button>
                </Tooltip>
              </div>
            </div>
            {/* header ends here */}
            {Object.keys(props.settings).length > 0 &&
            props.state.videos.now_playing.length > 0 ? (
              <>
                <div className='photo-collage pointer-events-none'>
                  <ReactPhotoCollage
                    layout={[1, 4]}
                    photos={props.settings.photos}
                    {...props.settings}
                    width='100%'
                  />
                </div>
              </>
            ) : (
              <>
                <div
                  className='playlistGradientCardWrapper'
                  onClick={props.onPreviewClick}
                ></div>
              </>
            )}
            <div className='cardBottomWrapper w-[400px]'>
              {props.state.auth?.user?.device_connected ? (
                <>
                  <div className='cardBottomDevicesTextConnected mb-2'>
                    <p></p>
                    <div>
                      Device connected! &nbsp;
                      <span>
                        {' '}
                        Last login at{' '}
                        {moment(
                          props.state.auth?.user?.device_connected
                        ).format('MMM, DD YYYY hh:mm A')}
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className='cardBottomDevicesText'>
                    <p></p>
                    <div>No device connected!</div>
                  </div>
                </>
              )}
              <div className='cardBottomButtonsWrapper'>
                <div
                  className='cardBottomButton1'
                  onClick={props.onPreviewClick}
                >
                  <span>Go to library</span>
                </div>

                {props.state.videos?.now_playing.length > 0 ? (
                  <>
                    <div className='tooltip'>
                      <div
                        className='cardBottomButtonActive'
                        onClick={() => {
                          trigger(previewModal);
                        }}
                      >
                        <span>Preview</span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='tooltip'>
                      <div className='cardBottomButtonDisabled'>
                        <span>Preview</span>
                      </div>
                      <span className='tooltiptext'>
                        You need to add videos to your playlist before you can
                        preview it
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          {/* contentCol1 ends here */}

          <div className='contentCol2'>
            <div className='contentCol2SmallText'>Coming Soon!</div>
            <div className='flex items-center gap-[8px]'>
              <div className='contentCol2LargeText'>Digital Signage</div>
              <Tooltip>
                Unlike your in office playlist, which is made for your waiting
                room and treatment rooms, digital signage will be made for the
                outside of your practice. We're working on a playlist now.
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlaylistCard;
