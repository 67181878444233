/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import TeamMemberIcon from 'assets/img/team-member-profile.png';
import TeamMemberBlankIcon from 'assets/img/team-blank-img.svg';
import Modal from 'react-modal';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { FileUploader } from 'react-drag-drop-files';
import {
  createPracticeTeamMember,
  deletePracticeTeamMember,
  getTeamMembers,
  updatePracticeTeamMember,
} from 'redux/actionCreators/practice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PromptModal from './prompt';

const NewTeamMemberModal = (props: any) => {
  const [file, setFile] = useState(null);

  const [promptModal, setPromptModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const [previewUrl, setPreviewUrl] = useState('');

  const dispatch = useDispatch();

  const [isRemoving, setIsRemoving] = useState(false);

  const [isSaving, setIsSaving] = useState(false);

  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [imageType, setImageType] = useState(null);

  const fileTypes = ['JPG', 'PNG', 'JPEG'];

  const handleChange = (file) => {
    let img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      if (img.width < 960 && img.height < 960) {
        toast.error(`Please upload a photo larger than 960 x 960px”`);
      } else {
        setPreviewUrl(URL.createObjectURL(file));
        setFile(file);

        setImageName(file.name);
        setImageType(file.type);

        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = () => {
          setImage(reader.result);
        };
      }
    };
  };

  const removeTeamMember = async () => {
    if (props.edit) {
      setPromptModal(false);

      setIsRemoving(true);

      await deletePracticeTeamMember(props.member.team_member_id);

      dispatch(getTeamMembers());

      props.setNewTeamMemberModal(false);

      props.setEditTeamMemberModal(false);

      toast.success(`Member was removed from the team.`);

      setIsRemoving(false);
    }
  };

  const initial_values = {
    name: props?.member?.MemberName ? props.member.MemberName : '',
    informal_name: props?.member?.ShortName ? props.member.ShortName : '',
    position: props?.member?.role ? props.member.role : '',
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Full name is required'),
    informal_name: Yup.string().required('Informal name is required'),
    position: Yup.string().required('Postition required'),
  });

  const createMember = async (values: any) => {
    if (props.edit) {
      setIsSaving(true);

      await updatePracticeTeamMember(
        {
          ...values,
          image,
          imageType,
          imageName,
        },
        props.member.team_member_id
      );

      setIsSaving(false);

      dispatch(getTeamMembers());

      props.setNewTeamMemberModal(false);

      props.setEditTeamMemberModal(false);

      toast.success(`Member was updated successfully.`);
    } else {
      setLoading(true);

      const member = await createPracticeTeamMember({
        ...values,
        image,
        imageType,
        imageName,
      });

      setLoading(false);

      dispatch(getTeamMembers());

      props.setNewTeamMemberModal(false);

      setFile(null);
      setPreviewUrl('');

      toast.success(`${values.name} was added into the team`);

      console.log(member);
    }
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={props.newTeamMemberModal}
        onAfterOpen={() => console.log('after open team member moal')}
        onRequestClose={() => props.setNewTeamMemberModal(false)}
        style={{
          overlay: {
            position: 'relative',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.75)',
            overflow: 'hidden',
          },
        }}
      >
        <div className='z-modal animate-fade-in bg-bg900 fixed left-0 top-0 flex flex-col items-stretch w-full h-screen bg-opacity-90 overflow-y-auto'>
          <span></span>
          <div className='flex flex-grow items-center justify-center py-4 w-full'>
            <div role='dialog' className='focus:outline-none'>
              <div className='bg-bg100 rounded-4xl relative py-0 px-6'>
                <button
                  className='absolute z-30 right-3 top-3 lt-item-icon-box lt-item-box-sizing lt-item-box-bg  close-popup-practice'
                  type='button'
                  onClick={() => {
                    props.setNewTeamMemberModal(false);
                  }}
                >
                  <span className=' flex items-center gap-4px'>
                    <svg
                      xmlnsXlink='http://www.w3.org/1999/xlink'
                      xmlns='http://www.w3.org/2000/svg'
                      aria-hidden='true'
                      focusable='false'
                      role='presentation'
                      className='index-StyledSvg_cls2__nHIIG index-StyledSvg_cls1__3YcLt'
                      width='24'
                      height='24'
                    >
                      <defs>
                        <symbol id='close' viewBox='0 0 24 24'>
                          <path d='M19.707 5.707a1 1 0 0 0-1.414-1.414L12 10.586 5.707 4.293a1 1 0 1 0-1.414 1.414L10.586 12l-6.293 6.292a1 1 0 1 0 1.414 1.415L12 13.414l6.293 6.293a1 1 0 0 0 1.414-1.415L13.414 12l6.293-6.293z'></path>
                        </symbol>
                      </defs>
                      <use xlinkHref='#close' fill='#FFFFFF'></use>
                    </svg>
                  </span>
                </button>
                <div className='flex items-center justify-between w-full'>
                  <p className='v-bs-h600 absolute top-6'>
                    {props.edit ? 'Edit team member' : 'New team member'}
                  </p>
                </div>
                <div className='flex'>
                  <div className='grid gap-2 mb-5 pt-12 grid-team-popup'>
                    <div>
                      <label className='photo-label-title'>Photo</label>
                      <div className='team-photo-upload-box'>
                        <FileUploader
                          name='file'
                          handleChange={handleChange}
                          types={fileTypes}
                          className='practive-box-holder practive-box-holder-size'
                          maxSize={25}
                        >
                          <div className='flex flex-col items-center'>
                            {previewUrl && (
                              <img
                                className='rounded-xl'
                                src={previewUrl}
                                alt=''
                                width={128}
                                height={128}
                              />
                            )}

                            {props.edit && previewUrl === '' ? (
                              <>
                                <img
                                  className='rounded-xl'
                                  src={props.member.photo_thumbnail}
                                  alt=''
                                  width={128}
                                  height={128}
                                />

                                <p className='v-bs-btn100 text-text900 mt-2'>
                                  {file ? file.name : ''}
                                </p>
                              </>
                            ) : (
                              <>
                                {previewUrl === '' && (
                                  <svg
                                    xmlnsXlink='http://www.w3.org/1999/xlink'
                                    xmlns='http://www.w3.org/2000/svg'
                                    aria-hidden='true'
                                    focusable='false'
                                    role='presentation'
                                    className='index-StyledSvg_cls2__nHIIG index-StyledSvg_cls1__3YcLt'
                                    width='24'
                                    height='24'
                                  >
                                    <defs>
                                      <symbol id='upload' viewBox='0 0 24 24'>
                                        <path
                                          fillRule='evenodd'
                                          d='M23 11.5a4.5 4.5 0 0 1-4.495 4.5H16a1 1 0 1 1 0-2h2.505a2.5 2.5 0 0 0 0-5H17.07l-.14-.835a5.001 5.001 0 0 0-9.862 0L6.93 9H5.495a2.5 2.5 0 0 0 0 5H8a1 1 0 1 1 0 2H5.495a4.5 4.5 0 0 1-.188-8.996C5.763 4.672 8.568 2 12 2a7.004 7.004 0 0 1 6.72 5.033c2.205 0 4.28 1.985 4.28 4.467zM8.468 12.542c.39.39 1.026.388 1.418-.003l1.118-1.118v9.57a1 1 0 1 0 2 0v-9.57l1.118 1.118a1 1 0 1 0 1.414-1.414l-2.822-2.823a.997.997 0 0 0-.71-.294.987.987 0 0 0-.707.291L8.47 11.125a1 1 0 0 0-.003 1.417z'
                                          clipRule='evenodd'
                                        ></path>
                                      </symbol>
                                    </defs>
                                    <use xlinkHref='#upload'></use>
                                  </svg>
                                )}
                                <p className='v-bs-btn100 text-text900 mt-2'>
                                  {file
                                    ? file.name
                                    : 'Drag and drop here or choose photo'}
                                </p>
                              </>
                            )}
                          </div>
                        </FileUploader>
                      </div>
                    </div>

                    <Formik
                      initialValues={initial_values}
                      validationSchema={validationSchema}
                      onSubmit={createMember}
                    >
                      <Form>
                        <div className='team-field-group mb-3'>
                          <label className='photo-label-title'>Name</label>
                          <div className='relative flex'>
                            <Field
                              className='team-field-control'
                              id='name'
                              name='name'
                              placeholder='Team member name'
                            />
                          </div>

                          <ErrorMessage
                            component='a'
                            className='error-msg'
                            name='name'
                          />
                        </div>
                        <div className='team-field-group mb-3'>
                          <label className='photo-label-title'>
                            Informal Name
                          </label>
                          <div className='relative flex'>
                            <Field
                              className='team-field-control'
                              id='informal_name'
                              name='informal_name'
                              placeholder='Name displayed in the videos'
                            />
                          </div>

                          <ErrorMessage
                            component='a'
                            className='error-msg'
                            name='informal_name'
                          />
                        </div>
                        <div className='team-field-group mb-3'>
                          <label className='photo-label-title'>Position</label>
                          <div className='relative flex'>
                            <Field
                              className='team-field-control'
                              id='position'
                              name='position'
                              placeholder='Team member role or position'
                            />
                          </div>

                          <ErrorMessage
                            component='a'
                            className='error-msg'
                            name='position'
                          />
                        </div>
                        {props.edit ? (
                          <>
                            <button
                              type='submit'
                              className='shadow-shadow200 mt-5 text-center w-full  lt-item-icon-box  box-shadow-focus btn-layout-style btn-layout-border'
                            >
                              {isSaving ? (
                                <>
                                  <svg
                                    className=' text-center w-3 h-3 mr-3 -ml-1 text-white animate-spin'
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'
                                  >
                                    <circle
                                      className='opacity-25'
                                      cx='12'
                                      cy='12'
                                      r='10'
                                      stroke='currentColor'
                                      stroke-width='4'
                                    ></circle>
                                    <path
                                      className='opacity-75'
                                      fill='currentColor'
                                      d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                                    ></path>
                                  </svg>
                                </>
                              ) : (
                                <>
                                  <span className=' flex items-center gap-4px'>
                                    Save changes
                                  </span>
                                </>
                              )}
                            </button>

                            <button
                              type='button'
                              onClick={() => {
                                setPromptModal(true);
                              }}
                              className=' mt-5 text-center w-full  lt-item-icon-box  remove-btn'
                            >
                              {isRemoving ? (
                                <>
                                  <svg
                                    className=' text-center w-3 h-3 mr-3 -ml-1 text-black animate-spin'
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'
                                  >
                                    <circle
                                      className='opacity-25'
                                      cx='12'
                                      cy='12'
                                      r='10'
                                      stroke='currentColor'
                                      stroke-width='4'
                                    ></circle>
                                    <path
                                      className='opacity-75'
                                      fill='currentColor'
                                      d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                                    ></path>
                                  </svg>
                                </>
                              ) : (
                                <>
                                  <span className=' flex items-center gap-4px'>
                                    Remove team member
                                  </span>
                                </>
                              )}
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              disabled={loading}
                              type='submit'
                              className='shadow-shadow200 mt-5 text-center w-full  lt-item-icon-box  box-shadow-focus btn-layout-style btn-layout-border'
                            >
                              {loading ? (
                                <>
                                  <svg
                                    className=' text-center w-3 h-3 mr-3 -ml-1 text-white animate-spin'
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'
                                  >
                                    <circle
                                      className='opacity-25'
                                      cx='12'
                                      cy='12'
                                      r='10'
                                      stroke='currentColor'
                                      stroke-width='4'
                                    ></circle>
                                    <path
                                      className='opacity-75'
                                      fill='currentColor'
                                      d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                                    ></path>
                                  </svg>
                                </>
                              ) : (
                                <>
                                  <span className=' flex items-center gap-4px'>
                                    Add new team member
                                  </span>
                                </>
                              )}
                            </button>
                          </>
                        )}
                      </Form>
                    </Formik>
                  </div>

                  <div className='Separator_column'></div>
                  <div className='flex flex-col gap-y-2 py-6 grid-team-popup-lh'>
                    <h4 className='v-bs-h400'>Instruction</h4>
                    <p className='v-bs-p200'>
                      Upload a headshot like the example below.
                    </p>
                    <div className='team-photo-instruciton'>
                      <div className='team-photo-instruciton-body'>
                        <img
                          className='team-photo-instruciton-body-img'
                          alt=''
                          src={TeamMemberBlankIcon}
                        />
                      </div>
                      <img
                        src={TeamMemberIcon}
                        className='rounded-large team-member-thumb-example'
                        alt=''
                      />
                    </div>
                    <p className='v-bs-p200'>
                      Min resolution: 960x960px
                      <br />
                      Max size: 25mb
                    </p>
                    <p className='v-bs-p200'>
                      If you don’t have a photo of your team member on hand, you
                      can always upload one later.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {props.edit && (
        <PromptModal
          promptModal={promptModal}
          setPromptModal={setPromptModal}
          btnText='Remove'
          title={`Remove ${props.member.MemberName}?`}
          description='This action will permanently remove the member from the team. The videos assigned to the member will remain in your playlist but will be unassigned. Are you sure you want to proceed?'
          onSuccess={removeTeamMember}
        />
      )}
    </React.Fragment>
  );
};

export default NewTeamMemberModal;
