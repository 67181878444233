import { ProgressSpinner } from 'icons';
import { ReactNode } from 'react';
import { cn } from 'util/cn';

type PropsType = {
  children?: ReactNode;
  className?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
  isPill?: boolean;
  variant?: 'primary' | 'secondary';
  onClick?: () => void;
  type?: 'button' | 'submit';
};

const Button = ({
  children,
  className,
  isLoading = false,
  isDisabled = false,
  size = 'md',
  isPill = true,
  variant = 'primary',
  onClick,
  type = 'button',
}: PropsType) => {
  const sizeClass = {
    sm: 'h-[36px] px-[16px]',
    md: 'h-[48px] px-[24px]',
    lg: 'h-[56px] px-[32px]',
  };

  const variantClass = {
    primary:
      'text-white bg-black hover:bg-bg-800 hover:text-text-200 focus:bg-bg-800 focus:text-text-200',
    secondary:
      'text-black bg-transparent border-2 border-black hover:text-text-800 hover:border-outline-700 focus:text-black focus:border-transparent',
  };

  const variantLoadingClass = {
    primary: 'cursor-not-allowed hover:bg-black hover:text-white',
    secondary: 'cursor-not-allowed hover:text-black hover:border-black',
  };

  const spinnerVariantClass = {
    primary: 'text-white',
    secondary: 'text-black',
  };

  const disabledVariantClass = {
    primary:
      'cursor-not-allowed bg-bg-700 text-text-200 hover:bg-bg-700 hover:text-text-200',
    secondary:
      'cursor-not-allowed text-text-200 border-outline-200 hover:text-text-200 hover:border-outline-200',
  };
  return (
    <button
      className={cn(
        'text-center font-karla text-[15px] font-medium leading-[20.4px] focus:outline-none focus:ring-4 focus:ring-outline-800 relative inline-flex items-center justify-center transition-all duration-200',
        isPill ? 'rounded-full' : 'rounded-[8px]',
        sizeClass[size],
        variantClass[variant],
        isLoading && variantLoadingClass[variant],
        isDisabled && disabledVariantClass[variant],
        className
      )}
      disabled={isLoading || isDisabled}
      onClick={onClick}
      type={type}
    >
      <ProgressSpinner
        className={cn(
          'absolute',
          !isLoading && 'invisible',
          spinnerVariantClass[variant]
        )}
      />
      <span className={isLoading && 'invisible'}>{children}</span>
    </button>
  );
};

export default Button;
