import { Body, ModalType, Title, useModalActions } from 'components';
import { AnnouncementModal } from 'features/announcements';
import { cn } from 'util/cn';

type PropsType = {
  title: string;
  description?: string;
  src?: string;
  className?: string;
  size?: 'md' | 'lg';
};

const AnnouncementItem = ({
  title,
  description,
  src,
  className,
  size = 'md',
}: PropsType) => {
  const { trigger } = useModalActions();
  const announcementModal: ModalType = {
    type: 'announcement',
    component: (
      <AnnouncementModal title={title} description={description} src={src} />
    ),
  };
  const BODY_LIMIT = 158;
  return (
    <button
      className={cn(
        'flex gap-[20px] py-[24px] bg-[#FCFCFC] text-start w-full outline-none focus:outline-none',
        size === 'md' ? 'px-[18px] rounded-[10px]' : 'px-[24px] rounded-2xl',
        className
      )}
      onClick={() => trigger(announcementModal)}
    >
      {src && (
        <div className='self-center'>
          <img
            src={src}
            alt='banner'
            className='w-[189px] min-w-[189px] aspect-video object-cover rounded-[10px]'
          />
        </div>
      )}
      <div className={cn(size === 'md' ? 'space-y-[7px]' : 'space-y-[5px]')}>
        <Title size={size}>{title}</Title>
        <Body
          size='regular'
          className={cn(
            'text-black w-fill',
            size === 'md' && 'text-xs leading-[18px]'
          )}
        >
          {description.length > BODY_LIMIT && size === 'md'
            ? `${description.slice(0, BODY_LIMIT)} read more...`
            : description}
        </Body>
      </div>
    </button>
  );
};

export default AnnouncementItem;
