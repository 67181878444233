/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import cookie from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import NotificationAvatar from 'assets/img/notification-avatar.png';
import { useSelector } from 'react-redux';
import ContactSupportModal from 'components/modals/contact-support';
import Tooltip from '../dashboard/tooltip';
import ConnectDeviceModal from 'components/modals/connect-device-modal';

interface RootState {
  auth: any;
  practice: any;
  videos: any;
}

const PracticeHeader = ({ practice }) => {
  const navigate = useNavigate();
  const state = useSelector((state: RootState) => state);
  const [openConnectDeviceModal, setConnectDeviceModal] = useState(false);

  const [modal, setModal] = useState(false);
  const [menu, setMenu] = useState(false);
  const [contactSupport, setContactSupport] = useState(false);

  const signout = () => {
    cookie.remove('token');
    cookie.remove('admin');
    navigate('/');
    return;
  };

  return (
    <>
      <section
        aria-label='Main'
        className='bg-bg100 sticky z-30 top-0  pl-2 flex flex-shrink-0 items-center justify-between h-12 w-[89.5vw]'
        role='navigation'
      >
        <div className='flex gap-4 justify-center'>
          <span className='flex gap-[8px] items-center'>
            <h4 className='title v-bs-list200 mt-[4px]'>
              Playlist ID: {state?.practice?.detail?.ChannelKey}
            </h4>
            <Tooltip>
              Use this code to stream your playlist on your tv/s with the
              Channel D app.{' '}
              <button
                className='outline-none focus:outline-none text-[#00f]'
                onClick={() => setConnectDeviceModal(true)}
              >
                Click here
              </button>
            </Tooltip>
          </span>
        </div>

        <div className='flex gap-2 '>
          <div className='relative inline-block'>
            <button
              onClick={() => setMenu(!menu)}
              id='react-aria-0-1'
              aria-haspopup='true'
              aria-expanded='false'
              type='button'
              className='lt-item-icon-box  lt-item-box-sizing box-shadow-focus lt-item-box-bg btn-layout-border'
            >
              <span className=' flex items-center gap-4px'>
                <svg
                  xmlnsXlink='http://www.w3.org/1999/xlink'
                  xmlns='http://www.w3.org/2000/svg'
                  aria-hidden='true'
                  focusable='false'
                  role='presentation'
                  className='svg-icon-24 index-StyledSvg_cls2__nHIIG index-StyledSvg_cls1__3YcLt'
                  width='24'
                  height='24'
                >
                  <defs>
                    <symbol id='more' viewBox='0 0 24 24'>
                      <path
                        fillRule='evenodd'
                        d='M6 12c0-1.65-1.35-3-3-3s-3 1.35-3 3 1.35 3 3 3 3-1.35 3-3zm3 0c0 1.65 1.35 3 3 3s3-1.35 3-3-1.35-3-3-3-3 1.35-3 3zm12 3c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z'
                        clipRule='evenodd'
                      ></path>
                    </symbol>
                  </defs>
                  <use xlinkHref='#more'></use>
                </svg>
              </span>
            </button>

            {menu && (
              <div className='flex justify-center'>
                <ul role='menu' className='StyledMenu_cls2__1JOU8'>
                  <li className='index-StyledMenuItem_cls2__34lgK'>
                    <button
                      onClick={() => setContactSupport(true)}
                      className='outline-none focus:outline-none'
                    >
                      Contact
                    </button>
                  </li>
                  <li className='index-StyledMenuItem_cls2__34lgK'>
                    <a
                      href='https://dashboard.channeld.com/terms.pdf'
                      target='_blank'
                    >
                      Terms and conditions{' '}
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <div className='relative'>
            <button
              aria-expanded='false'
              onClick={() => {
                setModal(!modal);
              }}
              type='button'
              className='lt-item-icon-box  lt-item-box-sizing box-shadow-focus btn-layout-style btn-layout-border'
            >
              <span className=' flex items-center gap-4px'>
                <svg
                  xmlnsXlink='http://www.w3.org/1999/xlink'
                  xmlns='http://www.w3.org/2000/svg'
                  aria-hidden='true'
                  focusable='false'
                  role='presentation'
                  className='svg-icon-24 index-StyledSvg_cls2__nHIIG index-StyledSvg_cls1__3YcLt'
                  width='24'
                  height='24'
                >
                  <defs>
                    <symbol id='bell' viewBox='0 0 24 24'>
                      <path
                        fillRule='evenodd'
                        d='M12 3a6 6 0 0 0-6 6v5a4 4 0 0 1-.536 2h13.072A4 4 0 0 1 18 14V9a6 6 0 0 0-6-6zm10 13a2 2 0 0 1-2-2V9A8 8 0 0 0 4 9v5a2 2 0 0 1-2 2 1 1 0 1 0 0 2h20s1 0 1-1-1-1-1-1zM9.768 20.135a1 1 0 0 1 1.367.363 1 1 0 0 0 1.73 0 1 1 0 0 1 1.73 1.004 3 3 0 0 1-5.19 0 1 1 0 0 1 .363-1.367z'
                        clipRule='evenodd'
                      ></path>
                    </symbol>
                  </defs>
                  <use xlinkHref='#bell' fill='#FFFFFF'></use>
                </svg>
              </span>
            </button>
          </div>
        </div>

        {modal && (
          <>
            <div
              id='notification-model'
              className='notifications-popup-wrap notifications-popup-position'
            >
              <h4 className='border-outline100 v-bs-h400 text-text900 px-2 py-3 border-b'>
                Notifications
              </h4>
              <div className='scrollbar-thumb-rounded-lg scrollbar scrollbar-border scrollbar-thumb-bg700 scrollbar-thin scrollbar-track-transparent w-full flex-grow notifications-popup-inner'>
                <div className='notifications-popup-content'>
                  <div className='flex flex-col flex-grow notifications-popup-content-style'>
                    <div>
                      <div className='flex gap-2 px-2 py-2'>
                        <div
                          className='practive-box-holder  notification-p-logo practice-box-holder-border notification-p-border'
                          style={{
                            backgroundImage: 'url(' + NotificationAvatar + ')',
                          }}
                        ></div>
                        <div className='flex gap-2'>
                          <div className='flex flex-col justify-between'>
                            <p className='v-bs-h300'>
                              Your payment is successful
                            </p>
                            <p className='v-bs-p200 text-text500'>
                              A monthly payment of $218.9 was made successfully
                            </p>
                            <p className='v-bs-label300 text-text500 mt-12px'>
                              Sep 25 01:04 PM
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className='flex gap-2 px-2 py-2'>
                        <div
                          className='practive-box-holder  notification-p-logo practice-box-holder-border notification-p-border'
                          style={{
                            backgroundImage: 'url(' + NotificationAvatar + ')',
                          }}
                        ></div>
                        <div className='flex gap-2'>
                          <div className='flex flex-col justify-between'>
                            <p className='v-bs-h300'>
                              Channel D uploaded new video
                            </p>
                            <p className='v-bs-p200 text-text500'>
                              Red Flag Alert_Airway Problems in Children to
                              Breathing Disorders category
                            </p>
                            <p className='v-bs-label300 text-text500 mt-12px'>
                              Sep 13 01:22 PM
                            </p>
                          </div>
                          <div className='w-14'>
                            <div className='aspect-w-16 aspect-h-9'>
                              <img
                                alt='Channel D uploaded new video'
                                className='rounded-2xl'
                                src='https://channeldstor.blob.core.windows.net/prod-files/ca047272-2f24-47c2-a657-66d6c573e73f.jpg'
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className='flex gap-2 px-2 py-2'>
                        <div
                          className='practive-box-holder notification-p-logo practice-box-holder-border notification-p-border'
                          style={{
                            backgroundImage: 'url(' + NotificationAvatar + ')',
                          }}
                        ></div>
                        <div className='flex gap-2'>
                          <div className='flex flex-col justify-between'>
                            <p className='v-bs-h300'>
                              Please upload required photos
                            </p>
                            <p className='v-bs-p200 text-text500'></p>
                            <p className='v-bs-label300 text-text500 mt-12px'>
                              Aug 31 07:33 AM
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className='flex gap-2 px-2 py-2'>
                        <div
                          className='practive-box-holder  notification-p-logo practice-box-holder-border notification-p-border'
                          style={{
                            backgroundImage: 'url(' + NotificationAvatar + ')',
                          }}
                        ></div>
                        <div className='flex gap-2'>
                          <div className='flex flex-col justify-between'>
                            <p className='v-bs-h300'>
                              Please upload required photos
                            </p>
                            <p className='v-bs-p200 text-text500'></p>
                            <p className='v-bs-label300 text-text500 mt-12px'>
                              Aug 31 07:18 AM
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
                <div className='notifition-earlier-wrap'>
                  <div className='flex flex-col flex-grow'>
                    <div style={{ position: 'sticky', zIndex: 1 }}>
                      <div className='v-bs-h200 px-2 pt-2 false false'>
                        Earlier
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='border-outline100 flex justify-center border-t'>
                <a className='text-text500 v-bs-btn100 p-3' href='/'>
                  Notification settings
                </a>
              </div>
            </div>
          </>
        )}
      </section>

      <ContactSupportModal
        setContactSupport={setContactSupport}
        contactSupport={contactSupport}
      />

      <ConnectDeviceModal
        code={state?.practice?.detail?.ChannelKey}
        openConnectDeviceModal={openConnectDeviceModal}
        setConnectDeviceModal={setConnectDeviceModal}
      />
    </>
  );
};

export default PracticeHeader;
