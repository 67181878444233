/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import NotificationAvatar from 'assets/img/notification-avatar.png';
import PracticeTheme from 'components/theme';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import DetaultProfile from 'assets/img/team-member-profile.png';
import { ActionType } from 'redux/actionTypes/index';
import { FileUploader } from 'react-drag-drop-files';
import Cookies from 'js-cookie';
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import NotFoundSearch from 'assets/img/not-found-search.png';
import LoaderGif from 'assets/img/loader.gif';
import useDebounce from 'hooks/useDebounce';
import Tooltip from 'react-simple-tooltip';
import { css } from 'styled-components';

import {
  addToPlaylist,
  getLibraryStats,
  getLibraryVideosAvailable,
  getPlaylist,
  removeFromPlaylist,
} from 'redux/actionCreators/videos';
import VideoModal from 'components/modals/video';
import PromptModal from 'components/modals/prompt';
import { toast, ToastContainer } from 'react-toastify';

interface RootState {
  practice: any;
  videos: any;
}

const Library = () => {
  const state = useSelector((state: RootState) => state);

  const [menu, setMenu] = useState(false);

  console.log('==== Library ====');

  console.log(state);

  console.log(state?.videos?.now_playing.filter((e) => e.video_id === 95594));

  const [videoModal, setVideoModal] = useState(false);
  const [currentModalVideo, setCurrentModalVideo]: any = useState({});
  const [category, setCategory] = useState(0);
  const [search, setSearch] = useState(null);
  const [isSearch, setIsSearch] = useState(false);
  const [sorting, setSorting] = useState(1);
  const [practiceVideoId, setPracticeVideoId] = useState(0);
  const [edit, setEdit] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [isRemoveVideo, setIsRemoveVideo] = useState(false);

  const [promptModal, setPromptModal] = useState(false);

  const [isRemoving, setIsRemoving] = useState(false);

  const removeVideoFromPlaylist = async () => {
    setPromptModal(false);

    setIsRemoving(true);

    const is_now_playing = state?.videos?.now_playing.find(
      (e) => e.video_id === currentModalVideo.video_id
    );

    const needs_customization = state?.videos?.needs_customization.find(
      (e) => e.video_id === currentModalVideo.video_id
    );

    const in_production = state?.videos?.in_production.find(
      (e) => e.video_id === currentModalVideo.video_id
    );

    if (is_now_playing) {
      await removeFromPlaylist(
        is_now_playing.practice_video_id,
        is_now_playing.customization_id
      );
    } else if (needs_customization) {
      await removeFromPlaylist(
        needs_customization.video_id,
        needs_customization.customization_id
      );
    } else if (in_production) {
      await removeFromPlaylist(
        in_production.video_id,
        in_production.customization_id
      );
    }

    dispatch(getLibraryStats());

    dispatch(
      getPlaylist({
        type: 'NOW_PLAYING',
      })
    );

    dispatch(
      getPlaylist({
        type: 'IN_PRODUCTION',
      })
    );

    dispatch(
      getPlaylist({
        type: 'NEEDS_CUSTOMIZATION',
      })
    );

    setIsRemoving(false);

    toast.success(
      `Video "${currentModalVideo.title}" was removed from playlist.`
    );
  };

  useDebounce(
    () => {
      dispatch(
        getLibraryVideosAvailable({
          search,
        })
      );
    },
    [search],
    800
  );

  const [playlist, setPlaylist] = useState([]);

  const dispatch = useDispatch();

  const [videos, setVideos] = useState({
    available: [],
    playlist: [],
  });

  const [tabs, setTabs] = useState({
    playlist: false,
    available: false,
    all: true,
  });

  useEffect(() => {}, []);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <>
      <PracticeTheme library={true}>
        <>
          <div className='flex flex-col gap-3 w-full'>
            <div className='flex items-center'>
              <h1 className='w-sidebar v-bs-h500 mr-3 pl-2'>Library</h1>
              <div className='flex flex-grow justify-between'>
                {isSearch ? (
                  <>
                    <div className='flex flex-grow justify-between'>
                      <div className='relative flex-grow'>
                        <div className='relative flex'>
                          <input
                            onChange={(e) => {
                              console.log(e.target.value);
                              setSearch(e.target.value);
                            }}
                            autoCapitalize='false'
                            autoComplete='false'
                            autoCorrect='off'
                            name='search'
                            type='text'
                            placeholder='Search'
                            className='search-libray-show'
                          />
                          <button
                            onClick={() => setIsSearch(false)}
                            className='absolute right-0 top-1/2 transform -translate-y-1/2 search-libray-show-btn'
                            type='button'
                          >
                            <span className=' flex items-center gap-4px'>
                              <svg
                                xmlnsXlink='http://www.w3.org/1999/xlink'
                                xmlns='http://www.w3.org/2000/svg'
                                aria-hidden='true'
                                focusable='false'
                                role='presentation'
                                className='index-StyledSvg_cls2__nHIIG index-StyledSvg_cls1__3YcLt'
                                width='24'
                                height='24'
                              >
                                <defs>
                                  <symbol id='close' viewBox='0 0 24 24'>
                                    <path d='M19.707 5.707a1 1 0 0 0-1.414-1.414L12 10.586 5.707 4.293a1 1 0 1 0-1.414 1.414L10.586 12l-6.293 6.292a1 1 0 1 0 1.414 1.415L12 13.414l6.293 6.293a1 1 0 0 0 1.414-1.415L13.414 12l6.293-6.293z'></path>
                                  </symbol>
                                </defs>
                                <use xlinkHref='#close'></use>
                              </svg>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='flex gap-x-5 items-center'>
                      <div className='flex box-border'>
                        <button
                          className={`setting-taps-holder ${
                            tabs.all ? 'setting-taps-active' : ''
                          }`}
                          onClick={() => {
                            setTabs({
                              playlist: false,
                              available: false,
                              all: true,
                            });
                          }}
                        >
                          All
                        </button>
                        <button
                          className={`setting-taps-holder ${
                            tabs.available ? 'setting-taps-active' : ''
                          }`}
                          onClick={() => {
                            setTabs({
                              playlist: false,
                              available: true,
                              all: false,
                            });
                          }}
                        >
                          Available
                        </button>
                        <button
                          className={`setting-taps-holder ${
                            tabs.playlist ? 'setting-taps-active' : ''
                          }`}
                          onClick={() => {
                            setTabs({
                              available: false,
                              playlist: true,
                              all: false,
                            });
                          }}
                        >
                          In playlist
                        </button>
                      </div>
                    </div>

                    <div className='flex gap-3 items-center'>
                      <div className='gap-4px flex items-center'>
                        <div aria-hidden='true' className='lib-1'>
                          <input type='text' style={{ fontSize: '16px' }} />
                          <label>
                            Sorting by
                            <select name='sort'>
                              <option></option>
                              <option value='CreatedOn'>Newest</option>
                              <option value='CategoryName'>Category A-Z</option>
                            </select>
                          </label>
                        </div>
                        <span className='v-bs-input-value100 text-text500 font-bold'>
                          Sorting by:
                        </span>
                        <div className='relative inline-block'>
                          <button
                            onClick={() => setMenu(!menu)}
                            id='react-aria-0-17'
                            aria-labelledby='react-aria-0-18 react-aria-0-19'
                            aria-haspopup='listbox'
                            aria-expanded='false'
                            type='button'
                            className='SelectValue_cls2__34ypU SelectValue_cls1__1PDGY'
                          >
                            {sorting === 1 ? 'Newest' : 'Category A-Z'}

                            <svg
                              xmlnsXlink='http://www.w3.org/1999/xlink'
                              xmlns='http://www.w3.org/2000/svg'
                              aria-hidden='true'
                              focusable='false'
                              role='presentation'
                              className='transform transition  index-StyledSvg_cls2__nHIIG index-StyledSvg_cls1__3YcLt'
                              width='24'
                              height='24'
                            >
                              <defs>
                                {menu ? (
                                  <>
                                    <symbol
                                      id='dropdown-arrow'
                                      viewBox='0 0 24 24'
                                    >
                                      <path
                                        fillRule='evenodd'
                                        d='M17.56 15.531a1.435 1.435 0 0 1-2.12 0L12 11.863 8.56 15.53a1.435 1.435 0 0 1-2.12 0 1.676 1.676 0 0 1 0-2.262l4.5-4.8a1.435 1.435 0 0 1 2.12 0l4.5 4.8a1.677 1.677 0 0 1 0 2.262z'
                                        clipRule='evenodd'
                                      />
                                    </symbol>
                                  </>
                                ) : (
                                  <>
                                    <symbol
                                      id='dropdown-arrow'
                                      viewBox='0 0 24 24'
                                    >
                                      <path
                                        fillRule='evenodd'
                                        d='M6.44 8.469a1.435 1.435 0 0 1 2.12 0L12 12.137l3.44-3.668a1.435 1.435 0 0 1 2.12 0 1.677 1.677 0 0 1 0 2.262l-4.5 4.8a1.435 1.435 0 0 1-2.12 0l-4.5-4.8a1.676 1.676 0 0 1 0-2.262z'
                                        clipRule='evenodd'
                                      ></path>
                                    </symbol>
                                  </>
                                )}
                              </defs>
                              <use xlinkHref='#dropdown-arrow'></use>
                            </svg>
                          </button>
                          {menu && (
                            <>
                              <div className='flex justify-center'>
                                <ul
                                  role='menu'
                                  className='StyledMenu_cls2__1JOU8'
                                >
                                  <li className='index-StyledMenuItem_cls2__34lgK'>
                                    <a
                                      onClick={() => {
                                        dispatch(
                                          getLibraryVideosAvailable({
                                            sorting: 1,
                                          })
                                        );

                                        setSorting(1);
                                        setMenu(false);
                                      }}
                                    >
                                      Newest
                                    </a>
                                  </li>
                                  <li className='index-StyledMenuItem_cls2__34lgK'>
                                    <a
                                      onClick={() => {
                                        dispatch(
                                          getLibraryVideosAvailable({
                                            sorting: 2,
                                          })
                                        );

                                        setSorting(2);
                                        setMenu(false);
                                      }}
                                    >
                                      Category A-Z{' '}
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </>
                          )}
                        </div>
                      </div>

                      <button
                        type='button'
                        className='search-btn index-StyledButton_cls2__1Drrf index-StyledButton_cls1__1qkPE index-StyledButton_icon__iX7wQ index-StyledButton_variant-tertiary__3Zb9A index-StyledButton_isRounded__17XD9'
                      >
                        <span className='flex items-center gap-4px'>
                          <svg
                            xmlnsXlink='http://www.w3.org/1999/xlink'
                            xmlns='http://www.w3.org/2000/svg'
                            aria-hidden='true'
                            focusable='false'
                            role='presentation'
                            className='index-StyledSvg_cls2__nHIIG index-StyledSvg_cls1__3YcLt'
                            width='32'
                            height='32'
                            onClick={() => setIsSearch(true)}
                          >
                            <defs>
                              <symbol id='search' viewBox='0 0 24 24'>
                                <path
                                  fillRule='evenodd'
                                  d='M21.392 19.978a.995.995 0 0 1-.003 1.411.997.997 0 0 1-1.411.003l-5.429-5.429a7.5 7.5 0 1 1 1.414-1.414l5.43 5.429zM10 15.5a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z'
                                  clipRule='evenodd'
                                ></path>
                              </symbol>
                            </defs>
                            <use xlinkHref='#search'></use>
                          </svg>
                        </span>
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className='flex gap-3 h-full'>
              <div className='w-sidebar flex flex-col flex-shrink-0'>
                <div>
                  <div className='flex flex-col gap-2'>
                    <a href='/library/office' className='page-nav-item'>
                      <svg
                        xmlnsXlink='http://www.w3.org/1999/xlink'
                        xmlns='http://www.w3.org/2000/svg'
                        aria-hidden='true'
                        focusable='false'
                        role='presentation'
                        className='index-StyledSvg_cls2__nHIIG index-StyledSvg_cls1__3YcLt'
                        width='24'
                        height='24'
                      >
                        <defs>
                          <symbol id='open-folder' viewBox='0 0 24 24'>
                            <path
                              fillRule='evenodd'
                              d='M22.311 10.007c1.275 0 2.022 1.156 1.505 2.32L20.627 19.5c-.383.864-1.375 1.507-2.317 1.506L1.997 21A1.997 1.997 0 0 1 0 19.005V4.995A2 2 0 0 1 1.995 3h6.043c.554 0 1.247.374 1.55.836L11.003 6h6.992A2 2 0 0 1 20 8v2.006l2.311.001zm-18.63 1.5C4.064 10.642 5.056 10 5.998 10L18 10.005V8h-6.996a2 2 0 0 1-1.674-.905L8 5.005 1.995 5 2 15.288l1.68-3.782zM5.997 12c-.152 0-.426.178-.489.319L2.5 19l15.811.007c.152 0 .426-.177.489-.318L21.765 12H5.997z'
                              clipRule='evenodd'
                            ></path>
                          </symbol>
                        </defs>
                        <use xlinkHref='#open-folder'></use>
                      </svg>
                      <span className='flex flex-col'>
                        <span className='title v-bs-list200 pb-4px'>
                          In-office videos
                        </span>
                        <span className='caption v-bs-label200'>
                          {state.videos?.available_total} available ,{' '}
                          {state.videos?.playlist_total} in playlist
                        </span>
                      </span>
                    </a>

                    <Tooltip
                      arrow={12}
                      background='#FFFFFF'
                      border='#CCC'
                      color='black'
                      content='Coming Soon.'
                      fontFamily='inherit'
                      fontSize='14px'
                      padding={10}
                      placement='bottom'
                      radius={20}
                      zIndex={1}
                      customCss={css`
                        width: nowrap;
                      `}
                    >
                      <div className='relative flex justify-start'>
                        <span>
                          <a
                            href='/library/street'
                            className='page-nav-item page-nav-item-disable'
                          >
                            <svg
                              xmlnsXlink='http://www.w3.org/1999/xlink'
                              xmlns='http://www.w3.org/2000/svg'
                              aria-hidden='true'
                              focusable='false'
                              role='presentation'
                              className='index-StyledSvg_cls2__nHIIG index-StyledSvg_cls1__3YcLt'
                              width='24'
                              height='24'
                              // onClick={() => setIsSearch(true)}
                            >
                              <defs>
                                <symbol id='open-folder' viewBox='0 0 24 24'>
                                  <path
                                    fillRule='evenodd'
                                    d='M22.311 10.007c1.275 0 2.022 1.156 1.505 2.32L20.627 19.5c-.383.864-1.375 1.507-2.317 1.506L1.997 21A1.997 1.997 0 0 1 0 19.005V4.995A2 2 0 0 1 1.995 3h6.043c.554 0 1.247.374 1.55.836L11.003 6h6.992A2 2 0 0 1 20 8v2.006l2.311.001zm-18.63 1.5C4.064 10.642 5.056 10 5.998 10L18 10.005V8h-6.996a2 2 0 0 1-1.674-.905L8 5.005 1.995 5 2 15.288l1.68-3.782zM5.997 12c-.152 0-.426.178-.489.319L2.5 19l15.811.007c.152 0 .426-.177.489-.318L21.765 12H5.997z'
                                    clipRule='evenodd'
                                  ></path>
                                </symbol>
                              </defs>
                              <use
                                xlinkHref='#open-folder'
                                fill='#626262'
                              ></use>
                            </svg>
                            <span className='flex flex-col'>
                              <span className='title v-bs-list200 pb-4px'>
                                Digital signage
                              </span>
                              <span className='caption v-bs-label200'></span>
                            </span>
                          </a>
                        </span>
                      </div>
                    </Tooltip>
                  </div>
                </div>
                <div className='flex flex-grow overflow-hidden'>
                  <div className='flex gap-2 items-stretch mt-10 w-full overflow-hidden'>
                    <div className='scrollbar-thumb-rounded-lg scrollbar scrollbar-border scrollbar-thumb-bg700 scrollbar-thin scrollbar-track-transparent w-full w-full lib-4'>
                      <div className='lib-2'>
                        <div className='lib-3'>
                          <div
                            data-index='0'
                            data-known-size='24'
                            data-item-index='0'
                          >
                            <button
                              onClick={() => {
                                dispatch(getLibraryVideosAvailable({}));

                                setCategory(0);
                              }}
                              className={`${
                                category === 0
                                  ? 'category-active'
                                  : 'category-disabled'
                              } category  category-active categories-filter-CategoryListItem_cls2__2RNpn categories-filter-CategoryListItem_cls1__1QsQB categories-filter-CategoryListItem_isActive__3roBx"`}
                            >
                              All categories
                            </button>
                          </div>
                          {state.videos?.categories.length && (
                            <>
                              {state.videos.categories.map((c) => {
                                return (
                                  <>
                                    <div
                                      data-index='1'
                                      data-known-size='24'
                                      data-item-index='1'
                                    >
                                      <button
                                        onClick={() => {
                                          dispatch(
                                            getLibraryVideosAvailable({
                                              category_id: parseInt(
                                                c.category_id
                                              ),
                                            })
                                          );

                                          setCategory(parseInt(c.category_id));
                                        }}
                                        className={`${
                                          category === c.category_id
                                            ? 'category-active'
                                            : 'category-disabled'
                                        } category categories-filter-CategoryListItem_cls2__2RNpn categories-filter-CategoryListItem_cls1__1QsQB`}
                                      >
                                        {c.category_name}
                                      </button>
                                    </div>
                                  </>
                                );
                              })}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex flex-col w-full'>
                <div className='scrollbar-thumb-rounded-lg scrollbar scrollbar-border scrollbar-thumb-bg700 scrollbar-thin scrollbar-track-transparent w-full library-grid lib-4'>
                  <div className='lib-2'>
                    <div className='lib-3'>
                      <div>
                        <div className='library-libraryGrid'>
                          {tabs.all && (
                            <>
                              {state.videos?.loading === true &&
                              state.videos?.event ===
                                ActionType.GET_VIDEOS_AVAILABLE ? (
                                <>
                                  <div className='image-loader'>
                                    <svg
                                      className=' text-center w-4 h-4 mr-3 -ml-1 text-black animate-spin img-center'
                                      xmlns='http://www.w3.org/2000/svg'
                                      fill='none'
                                      viewBox='0 0 24 24'
                                    >
                                      <circle
                                        className='opacity-25'
                                        cx='12'
                                        cy='12'
                                        r='10'
                                        stroke='currentColor'
                                        stroke-width='4'
                                      ></circle>
                                      <path
                                        className='opacity-75'
                                        fill='currentColor'
                                        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                                      ></path>
                                    </svg>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {state.videos?.available_videos.length > 0 ? (
                                    <>
                                      {state.videos.available_videos.map(
                                        (video) => {
                                          const needs_customization =
                                            state?.videos?.needs_customization.some(
                                              (v) =>
                                                v.video_id === video.video_id
                                            );
                                          const in_production =
                                            state?.videos?.in_production.some(
                                              (v) =>
                                                v.video_id === video.video_id
                                            );

                                          const is_now_playing =
                                            state?.videos?.now_playing.some(
                                              (e) =>
                                                e.video_id === video.video_id
                                            );
                                          return (
                                            <>
                                              <div className='library-librarygrid-Item'>
                                                <div className='library-cardbody'>
                                                  <div className='relative block'>
                                                    <div className='aspect-w-16 aspect-h-9 cursor-pointer'>
                                                      <img
                                                        alt="Heard you've been flossing"
                                                        className='rounded-2xl'
                                                        src={
                                                          video.thumbnail_file
                                                        }
                                                      />
                                                    </div>

                                                    {is_now_playing && (
                                                      <>
                                                        <div className='absolute left-1 top-1'>
                                                          <div className='badge-player index-StyledStatus_cls2__1lS-k index-StyledStatus_cls1__2Isj9 index-StyledStatus_hasChildren__yjGII index-StyledStatus_status-success__1rnZQ'>
                                                            Now Playing
                                                          </div>
                                                        </div>
                                                      </>
                                                    )}

                                                    {needs_customization && (
                                                      <>
                                                        <div className='absolute left-1 top-1 '>
                                                          <div className='badge-player-1 index-StyledStatus_cls2__1lS-k index-StyledStatus_cls1__2Isj9 index-StyledStatus_hasChildren__yjGII index-StyledStatus_status-success__1rnZQ'>
                                                            Needing
                                                            customization
                                                          </div>
                                                        </div>
                                                      </>
                                                    )}

                                                    {in_production && (
                                                      <>
                                                        <div className='absolute left-1 top-1'>
                                                          <div className='badge-player-2 index-StyledStatus_cls2__1lS-k index-StyledStatus_cls1__2Isj9 index-StyledStatus_hasChildren__yjGII index-StyledStatus_status-success__1rnZQ'>
                                                            In production
                                                          </div>
                                                        </div>
                                                      </>
                                                    )}
                                                  </div>
                                                  <div className='flex flex-col'>
                                                    <span className='v-bs-label300 text-text500'>
                                                      {video.category_name}
                                                    </span>
                                                    <span className='library-video-title'>
                                                      <div className='FadeText_cls2__3sjc6 FadeText_cls1__1SDB3'>
                                                        {video.title}
                                                      </div>
                                                    </span>
                                                  </div>
                                                  <div className='library-cardactionbox'>
                                                    <div className='library-content-btn'>
                                                      {is_now_playing ||
                                                      needs_customization ||
                                                      in_production ? (
                                                        <>
                                                          <button
                                                            className='library-edit-btn'
                                                            onClick={() => {
                                                              const [
                                                                needs_customization_video,
                                                              ] =
                                                                state?.videos?.needs_customization.filter(
                                                                  (v) =>
                                                                    v.video_id ===
                                                                    video.video_id
                                                                );
                                                              const [
                                                                in_production_video,
                                                              ] =
                                                                state?.videos?.in_production.filter(
                                                                  (v) =>
                                                                    v.video_id ===
                                                                    video.video_id
                                                                );

                                                              const [
                                                                is_now_playing_video,
                                                              ] =
                                                                state?.videos?.now_playing.filter(
                                                                  (e) =>
                                                                    e.video_id ===
                                                                    video.video_id
                                                                );

                                                              if (
                                                                needs_customization
                                                              ) {
                                                                console.log(
                                                                  ' print '
                                                                );
                                                                console.log(
                                                                  needs_customization_video
                                                                );

                                                                setPracticeVideoId(
                                                                  needs_customization_video.video_id
                                                                );

                                                                setVideoModal(
                                                                  true
                                                                );

                                                                setEdit(true);

                                                                setCurrentModalVideo(
                                                                  needs_customization_video
                                                                );
                                                              }

                                                              if (
                                                                in_production
                                                              ) {
                                                                setPracticeVideoId(
                                                                  in_production_video.video_id
                                                                );

                                                                setVideoModal(
                                                                  true
                                                                );

                                                                setEdit(true);

                                                                setCurrentModalVideo(
                                                                  in_production_video
                                                                );
                                                              }

                                                              if (
                                                                is_now_playing
                                                              ) {
                                                                setPracticeVideoId(
                                                                  is_now_playing_video.video_id
                                                                );

                                                                setVideoModal(
                                                                  true
                                                                );

                                                                setEdit(true);

                                                                setCurrentModalVideo(
                                                                  is_now_playing_video
                                                                );
                                                              }
                                                            }}
                                                          >
                                                            <span className='flex items-center gap-4px'>
                                                              Edit
                                                            </span>
                                                          </button>
                                                          <button
                                                            className='library-edit-btn'
                                                            onClick={() => {
                                                              const [
                                                                needs_customization_video,
                                                              ] =
                                                                state?.videos?.needs_customization.filter(
                                                                  (v) =>
                                                                    v.video_id ===
                                                                    video.video_id
                                                                );
                                                              const [
                                                                in_production_video,
                                                              ] =
                                                                state?.videos?.in_production.filter(
                                                                  (v) =>
                                                                    v.video_id ===
                                                                    video.video_id
                                                                );

                                                              const [
                                                                is_now_playing_video,
                                                              ] =
                                                                state?.videos?.now_playing.filter(
                                                                  (e) =>
                                                                    e.video_id ===
                                                                    video.video_id
                                                                );

                                                              if (
                                                                needs_customization
                                                              ) {
                                                                setCurrentModalVideo(
                                                                  needs_customization_video
                                                                );
                                                                setIsRemoveVideo(
                                                                  true
                                                                );

                                                                setPromptModal(
                                                                  true
                                                                );
                                                              }

                                                              if (
                                                                in_production
                                                              ) {
                                                                setCurrentModalVideo(
                                                                  in_production_video
                                                                );
                                                                setIsRemoveVideo(
                                                                  true
                                                                );

                                                                setPromptModal(
                                                                  true
                                                                );
                                                              }

                                                              if (
                                                                is_now_playing
                                                              ) {
                                                                setCurrentModalVideo(
                                                                  is_now_playing_video
                                                                );
                                                                setIsRemoveVideo(
                                                                  true
                                                                );

                                                                setPromptModal(
                                                                  true
                                                                );
                                                              }
                                                            }}
                                                          >
                                                            <span className='flex items-center gap-4px'>
                                                              Remove
                                                            </span>
                                                          </button>
                                                        </>
                                                      ) : (
                                                        <>
                                                          <button
                                                            className='library-edit-btn'
                                                            onClick={async () => {
                                                              setSubmitting(
                                                                true
                                                              );
                                                              if (
                                                                video.customization_type ===
                                                                  null &&
                                                                video.total_questions ===
                                                                  0 &&
                                                                video.total_descriptions ===
                                                                  0
                                                              ) {
                                                                const res =
                                                                  await addToPlaylist(
                                                                    {
                                                                      questions:
                                                                        [],
                                                                      members:
                                                                        [],
                                                                      video:
                                                                        video,
                                                                      fromVideoModal:
                                                                        false,
                                                                    }
                                                                  );
                                                                dispatch(
                                                                  getLibraryStats()
                                                                );

                                                                dispatch(
                                                                  getPlaylist({
                                                                    type: 'NOW_PLAYING',
                                                                  })
                                                                );

                                                                dispatch(
                                                                  getLibraryVideosAvailable(
                                                                    {}
                                                                  )
                                                                );

                                                                toast.success(
                                                                  `Video "${video.title}" was added to playlist.`
                                                                );
                                                              } else {
                                                                setPracticeVideoId(
                                                                  video.video_id
                                                                );
                                                                setEdit(false);
                                                                setVideoModal(
                                                                  true
                                                                );

                                                                setCurrentModalVideo(
                                                                  video
                                                                );
                                                              }

                                                              setSubmitting(
                                                                false
                                                              );
                                                            }}
                                                          >
                                                            <span className='flex items-center gap-4px'>
                                                              {submitting
                                                                ? 'Wait...'
                                                                : 'Add to Playlist'}
                                                            </span>
                                                          </button>
                                                        </>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          );
                                        }
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <div className='not-found-div'>
                                        <img
                                          src={NotFoundSearch}
                                          alt=''
                                          className='not-found-search'
                                        />
                                      </div>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                          {tabs.available && (
                            <>
                              {state.videos?.loading === true &&
                              state.videos?.event ===
                                ActionType.GET_VIDEOS_AVAILABLE ? (
                                <>
                                  <div className='image-loader'>
                                    <svg
                                      className=' text-center w-4 h-4 mr-3 -ml-1 text-black animate-spin img-center'
                                      xmlns='http://www.w3.org/2000/svg'
                                      fill='none'
                                      viewBox='0 0 24 24'
                                    >
                                      <circle
                                        className='opacity-25'
                                        cx='12'
                                        cy='12'
                                        r='10'
                                        stroke='currentColor'
                                        stroke-width='4'
                                      ></circle>
                                      <path
                                        className='opacity-75'
                                        fill='currentColor'
                                        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                                      ></path>
                                    </svg>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {state.videos?.available_videos.length > 0 ? (
                                    <>
                                      {state.videos.available_videos.map(
                                        (video) => {
                                          const needs_customization =
                                            state?.videos?.needs_customization.some(
                                              (v) =>
                                                v.video_id === video.video_id
                                            );
                                          const in_production =
                                            state?.videos?.in_production.some(
                                              (v) =>
                                                v.video_id === video.video_id
                                            );

                                          const is_now_playing =
                                            state?.videos?.now_playing.some(
                                              (e) => {
                                                if (
                                                  e.template_id &&
                                                  e.template_id ===
                                                    video.video_id
                                                ) {
                                                  return true;
                                                } else if (
                                                  !e.template_id &&
                                                  e.video_id === video.video_id
                                                ) {
                                                  return true;
                                                } else {
                                                  return false;
                                                }
                                              }
                                            );
                                          return (
                                            <>
                                              {!is_now_playing &&
                                                !in_production &&
                                                !needs_customization && (
                                                  <>
                                                    <div className='library-librarygrid-Item'>
                                                      <div className='library-cardbody'>
                                                        <div className='relative block'>
                                                          <div
                                                            className='aspect-w-16 aspect-h-9 cursor-pointer'
                                                            onClick={() => {
                                                              setPracticeVideoId(
                                                                video.video_id
                                                              );
                                                              setEdit(false);
                                                              setVideoModal(
                                                                true
                                                              );
                                                              setCurrentModalVideo(
                                                                video
                                                              );
                                                            }}
                                                          >
                                                            <img
                                                              alt="Heard you've been flossing"
                                                              className='rounded-2xl'
                                                              src={
                                                                video.thumbnail_file
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                        <div className='flex flex-col'>
                                                          <span className='v-bs-label300 text-text500'>
                                                            {
                                                              video.category_name
                                                            }
                                                          </span>
                                                          <span className='library-video-title'>
                                                            <div className='FadeText_cls2__3sjc6 FadeText_cls1__1SDB3'>
                                                              {video.title}
                                                            </div>
                                                          </span>
                                                        </div>
                                                        <div className='library-cardactionbox'>
                                                          <div className='library-content-btn'>
                                                            {is_now_playing ? (
                                                              <>
                                                                <button
                                                                  className='library-edit-btn'
                                                                  onClick={() => {
                                                                    setPracticeVideoId(
                                                                      video.video_id
                                                                    );
                                                                    setEdit(
                                                                      true
                                                                    );
                                                                    setVideoModal(
                                                                      true
                                                                    );
                                                                    setCurrentModalVideo(
                                                                      video
                                                                    );
                                                                  }}
                                                                >
                                                                  <span className='flex items-center gap-4px'>
                                                                    Edit
                                                                  </span>
                                                                </button>
                                                                <button
                                                                  className='library-edit-btn'
                                                                  onClick={() => {
                                                                    setIsRemoveVideo(
                                                                      true
                                                                    );
                                                                    setCurrentModalVideo(
                                                                      video
                                                                    );
                                                                    setPromptModal(
                                                                      true
                                                                    );
                                                                  }}
                                                                >
                                                                  <span className='flex items-center gap-4px'>
                                                                    Remove
                                                                  </span>
                                                                </button>
                                                              </>
                                                            ) : (
                                                              <>
                                                                <button
                                                                  className='library-edit-btn'
                                                                  onClick={async () => {
                                                                    setSubmitting(
                                                                      true
                                                                    );
                                                                    if (
                                                                      video.customization_type ===
                                                                        null &&
                                                                      video.total_questions ===
                                                                        0 &&
                                                                      video.total_descriptions ===
                                                                        0
                                                                    ) {
                                                                      const res =
                                                                        await addToPlaylist(
                                                                          {
                                                                            questions:
                                                                              [],
                                                                            members:
                                                                              [],
                                                                            video:
                                                                              video,
                                                                            fromVideoModal:
                                                                              false,
                                                                          }
                                                                        );
                                                                      dispatch(
                                                                        getLibraryStats()
                                                                      );

                                                                      dispatch(
                                                                        getPlaylist(
                                                                          {
                                                                            type: 'NOW_PLAYING',
                                                                          }
                                                                        )
                                                                      );

                                                                      dispatch(
                                                                        getLibraryVideosAvailable(
                                                                          {}
                                                                        )
                                                                      );

                                                                      toast.success(
                                                                        `Video "${video.title}" was added to playlist.`
                                                                      );
                                                                    } else {
                                                                      setPracticeVideoId(
                                                                        video.video_id
                                                                      );
                                                                      setEdit(
                                                                        false
                                                                      );
                                                                      setVideoModal(
                                                                        true
                                                                      );

                                                                      setCurrentModalVideo(
                                                                        video
                                                                      );
                                                                    }
                                                                    setSubmitting(
                                                                      false
                                                                    );
                                                                  }}
                                                                >
                                                                  <span className='flex items-center gap-4px'>
                                                                    {submitting
                                                                      ? 'Wait...'
                                                                      : 'Add to Playlist'}
                                                                  </span>
                                                                </button>
                                                              </>
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </>
                                                )}
                                            </>
                                          );
                                        }
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <div className='not-found-div'>
                                        <img
                                          src={NotFoundSearch}
                                          alt=''
                                          className='not-found-search'
                                        />
                                      </div>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}

                          {tabs.playlist &&
                            state.videos.now_playing &&
                            state.videos.now_playing.map((video) => {
                              return (
                                <>
                                  <div className='library-librarygrid-Item'>
                                    <div className='library-cardbody'>
                                      <div className='relative block'>
                                        <div className='aspect-w-16 aspect-h-9'>
                                          <img
                                            alt="Heard you've been flossing"
                                            className='rounded-2xl'
                                            onClick={() => {
                                              setPracticeVideoId(
                                                video.video_id
                                              );
                                              setEdit(true);
                                              setVideoModal(true);
                                              setCurrentModalVideo(video);
                                            }}
                                            src={video.thumbnail_file}
                                          />
                                        </div>
                                        {state?.videos?.now_playing.some(
                                          (e) => e.video_id === video.video_id
                                        ) && (
                                          <>
                                            <div className='absolute left-1 top-1'>
                                              <div className='badge-player index-StyledStatus_cls2__1lS-k index-StyledStatus_cls1__2Isj9 index-StyledStatus_hasChildren__yjGII index-StyledStatus_status-success__1rnZQ'>
                                                Now Playing
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                      <div className='flex flex-col'>
                                        <span className='v-bs-label300 text-text500'>
                                          {video.category_name}
                                        </span>
                                        <span className='library-video-title'>
                                          <div className='FadeText_cls2__3sjc6 FadeText_cls1__1SDB3'>
                                            {video.title}
                                          </div>
                                        </span>
                                      </div>
                                      <div className='library-cardactionbox'>
                                        <div className='library-content-btn'>
                                          <button
                                            className='library-edit-btn'
                                            onClick={() => {
                                              setPracticeVideoId(
                                                video.video_id
                                              );

                                              setVideoModal(true);

                                              setEdit(true);

                                              setCurrentModalVideo(video);
                                            }}
                                          >
                                            <span className='flex items-center gap-4px'>
                                              Edit
                                            </span>
                                          </button>
                                          <button
                                            className='library-edit-btn'
                                            onClick={() => {
                                              setIsRemoveVideo(true);
                                              setCurrentModalVideo(video);
                                              setPromptModal(true);
                                            }}
                                          >
                                            <span className='flex items-center gap-4px'>
                                              Remove
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
        <ToastContainer
          position='bottom-center'
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
          theme='dark'
        />
      </PracticeTheme>

      <VideoModal
        edit={edit}
        video_id={practiceVideoId}
        videoModal={videoModal}
        setVideoModal={setVideoModal}
        video={currentModalVideo}
        members={state.practice.team_members}
      />

      {isRemoveVideo && (
        <PromptModal
          promptModal={promptModal}
          setPromptModal={setPromptModal}
          btnText='Remove'
          title={`Remove "${currentModalVideo.title}" from playlist?`}
          description=''
          onSuccess={removeVideoFromPlaylist}
        />
      )}
    </>
  );
};

export default Library;
