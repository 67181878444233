import { cn } from 'util/cn';

type PropsType = {
  children?: string;
  className?: string;
};

const Badge = ({ children, className }: PropsType) => {
  return (
    <div
      className={cn(
        'rounded-full px-[8px] py-[4px] font-karla font-bold text-[14px] leading-[16.37px] text-white bg-black w-fit',
        className
      )}
    >
      {children}
    </div>
  );
};

export default Badge;
