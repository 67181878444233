/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import PracticeSideBar from './sidebar';
import PracticeHeader from './header';
import cookie from 'js-cookie';
import { currentUser } from 'services/user';
import { useNavigate } from 'react-router-dom';
import {
  getOtherPhotos,
  getPracticeAddress,
  getPracticeDetail,
  getTeamMembers,
} from 'redux/actionCreators/practice';
import { useDispatch, useSelector } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import {
  getLibraryStats,
  getLibraryVideosAvailable,
  getPlaylist,
  getVideoCategories,
} from 'redux/actionCreators/videos';
import {
  fetchCurrentUser,
  getUserPaymentInfo,
  getUserTransactions,
} from 'redux/actionCreators/auth';

interface RootState {
  auth: any;
  practice: any;
  videos: any;
}

const PracticeTheme = (props: any) => {
  const state = useSelector((state: RootState) => state);

  const [user, setUser]: any = useState({
    first_name: '',
    last_name: '',
    email: '',
    role: '',
    customer_id: '',
    postal_address_id: 0,
    physical_address_id: 0,
  });

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const token = cookie.get('token');

  useEffect(() => {
    if (!token) {
      navigate('/');

      return;
    }
    const init = async () => {
      await getCurrentUser();
    };
    init();
  }, [navigate, token]);

  useEffect(() => {
    dispatch(fetchCurrentUser());

    dispatch(getPracticeDetail());

    dispatch(getTeamMembers());

    dispatch(getOtherPhotos());

    dispatch(getLibraryStats());
  }, [dispatch]);

  useEffect(() => {
    setIsLoading(true);

    if (state.auth?.user) {
      if (state.videos.categories.length === 0) {
        dispatch(getVideoCategories());
      }

      if (state.videos.available_videos.length === 0) {
        dispatch(getLibraryVideosAvailable({}));
      }

      // if(state.videos.now_playing.length === 0) {

      dispatch(
        getPlaylist({
          type: 'NOW_PLAYING',
        })
      );

      // }

      // if(state.videos.in_production.length === 0) {

      dispatch(
        getPlaylist({
          type: 'IN_PRODUCTION',
        })
      );

      // }

      // if(state.videos.needs_customization.length === 0) {

      dispatch(
        getPlaylist({
          type: 'NEEDS_CUSTOMIZATION',
        })
      );
      // }

      if (state?.practice?.detail?.StripeID) {
        dispatch(
          getUserPaymentInfo({
            customer_id: state?.practice?.detail?.StripeID,
            // customer_id:"cus_MojbgqwtraLYrf"
          })
        );

        dispatch(
          getUserTransactions({
            customer_id: state?.practice?.detail?.StripeID,
          })
        );
      }
    }
  }, [dispatch, user]);

  const getCurrentUser = async () => {
    const user = await currentUser();

    // updating current user in cookies

    console.log('--- Current User ---');

    cookie.set('first_name', user.data.FirstName);
    cookie.set('last_name', user.data.LastName);
    cookie.set('email', user.data.Email);
    cookie.set('user_id', user.data.Id);
    cookie.set('role', user.data.role);
    cookie.set('channel', user.data.PracticeId);
    cookie.set('physical_address_id', user.data.PhysicalAddressID);
    cookie.set('postal_address_id', user.data.PostalAddressID);

    // dispatch(
    //   getPracticeAddress( user.data.PhysicalAddressID, "postal")
    // );

    dispatch(getPracticeAddress(user.data.PostalAddressID, 'physical'));

    setUser({
      ...user,
      first_name: user.data.FirstName,
      last_name: user.data.LastName,
      email: user.data.email,
      user_id: user.data.Id,
      role: user.data.role,
      postal_address_id: user.data.PostalAddressID,
      physical_address_id: user.data.PhysicalAddressID,
    });
  };

  return (
    <>
      <div className='full-layout-wrap'>
        <main className='flex w-full h-screen max-h-screen'>
          <PracticeSideBar
            setEdit={props.setEdit}
            teams={props.teams}
            setTeams={props.setTeams}
            library={props.library}
            setLibrary={props.setLibrary}
            page={props.page}
          />
          <div className='flex flex-col ml-14 mr-4 w-full'>
            <PracticeHeader practice={props.practice} />
            <div
              className={`flex flex-auto pb-1 ${
                props.page === 'playlist' ? 'h-full overflow-hidden' : ''
              }`}
            >
              <div className='default-layout-container default-layout-ht max-h-[calc(100vh-96px)]'>
                {props.children}
              </div>
            </div>
          </div>
        </main>
      </div>
      {cookie.get('adminn') === '1' && (
        <>
          <button
            className='btn-a shadow-shadow200 absolute bottom-6 left-16 lt-item-icon-box  box-shadow-focus btn-layout-style btn-layout-border'
            onClick={() => {
              cookie.remove('token');
              cookie.remove('admin');
              window.location.href = 'https://admin.channeld.com/practices';
            }}
          >
            <span className=' flex items-center gap-4px'>
              <svg
                xmlnsXlink='http://www.w3.org/1999/xlink'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'
                focusable='false'
                role='presentation'
                className='index-StyledSvg_cls2__nHIIG index-StyledSvg_cls1__3YcLt'
                width='24'
                height='24'
              >
                <defs>
                  <symbol id='back' viewBox='0 0 24 24'>
                    <path d='M7.587 10.4h11.868c.853 0 1.545.71 1.545 1.6 0 .884-.688 1.6-1.545 1.6H7.587l3.752 3.669a1.575 1.575 0 0 1 0 2.262 1.663 1.663 0 0 1-2.314 0l-6.546-6.4a1.575 1.575 0 0 1 0-2.262l6.546-6.4a1.663 1.663 0 0 1 2.314 0 1.575 1.575 0 0 1 0 2.262L7.587 10.4z'></path>
                  </symbol>
                </defs>
                <use xlinkHref='#back' fill='#FFFFFF'></use>
              </svg>
              Go back as Admin{' '}
            </span>
          </button>
        </>
      )}
    </>
  );
};

export default PracticeTheme;
