/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import IPage from "interfaces/page";
import logging from "config/logging";
import logo from "assets/img/logo.svg";
import AccountImage from "assets/img/account-img.png";
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { login } from "redux/actionCreators/auth";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import cookie from "js-cookie";
import "assets/css/global.scss";
import { persistor } from "redux/store";
import { resetPassword } from "services/user";
import EmailIcon from "assets/img/email-icon.png";

interface RootState {
  auth: any;
}

const ResetPasswordPage: React.FunctionComponent<IPage> = props => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state);
  const [errors, setErrors] = useState(false);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState(null);

  const { id } = useParams();


  const initialValues = { email: "" };
  const [isSubmitting, setIsSubmitting] = useState(false);



  const onSubmit = async (values: any) => {
    setIsSubmitting(true);

    const res = await resetPassword({
       id,
       password: values.password
    });

    console.log(res.data);

    if (res.data.error) {

      setSuccess(false);
      setErrors(true);
    } else {

      setErrors(false)
      setSuccess(true);

    }

    setIsSubmitting(false);
  };

  const validationSchema = Yup.object({
    password: Yup.string().min(6).required("New password is required "),
    confirm_password: Yup.string().min(6)
      .required("Please confirm new password").label('password')
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const styles = {
    label: 'block text-gray-700 text-sm font-bold pt-2 pb-1',
    field:
      'bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none',
    button:
      'w-full disabled:cursor-not-allowed disabled:shadow-none disabled:text-white disabled:transform-none disabled:transition-none  cursor-pointer flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
    errorMsg: 'text-red-500 text-sm pb-2 font-bold',
  }
  return (
    <React.Fragment>
      <main className="d-flex flex-col pt-3 px-4 px-md-5 h-screen">
        <section className="d-flex align-items-center justify-content-between">
          <a href="/">
            <div className="brand-logo">
              <img src={logo} alt="Logo" />
            </div>
          </a>
          <a href="/" className="btn btn-sign-up">
            <span className="flex items-center gap-4px">Sign Up</span>
          </a>
        </section>
        <section className="grid gap-3 grid-cols-12 align-items-center pt-3 w-full h-full bg-right-bottom bg-no-repeat">
          <div className="col-span-12 lg:col-span-4 xl:col-start-2 sign-up-holder">
            <h1 className={`title-welcome`}>
              Reset Password  
            </h1>

            {success === false && (

              <p className="mb-4">
                Set a new password for practice.Password must be atleast 6 characters. it can be combination of letters, numbers, and symbols
              </p>
            )}


            {errors && (

              <div className="bg-red-100 border border-red-400 text-red-700 px-2 py-1 rounded relative mb-5" role="alert">
                <span className="block sm:inline">Sorry, email does not exists</span>
                <span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={() => {
                  setErrors(false);
                }}>
                </span>
              </div>
            )}

            {success ? (

              <>
                <p>

                  Password has been updated successfully. Pleaase login to continue

                </p>

              </>
            ) : (
              <>

                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  <Form className="grid gap-2">
                    <div className="field-wel">
                      <Field type='password' className="form-control" id='password' name='password' placeholder='New Password' />
                    </div>
                    <ErrorMessage component='a' className={styles.errorMsg} name='new_password' />

                    <div className="field-wel">
                      <Field type="password" className="form-control" id='confirm_password' name='confirm_password' placeholder='Confirm Password' />
                    </div>
                    <ErrorMessage component='a' className={styles.errorMsg} name='confirm_password' />


                    <button className="btn btn-block btn-sign-in button-bg-black"

                      type="submit"

                      disabled={isSubmitting}
                    >

                      {isSubmitting && (

                        <svg className=" text-center w-4 h-4 mr-3 -ml-1 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none"
                          viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                          <path className="opacity-75" fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                          </path>
                        </svg>
                      )}


                      {isSubmitting ? "" : "Reset password"}


                    </button>


                  </Form>
                </Formik>

              </>
            )}


            {success  && (

              <a href="/" className="pt-3 forgot-link justify-center text-center">Sign in</a>

            )}

          </div>
          <div className="d-none d-sm-block col-span-7 align-self-end lg:block">
            <div className="account-bg">
              <div className="account-bg-inner"></div>
              <img src={AccountImage} alt="" />
            </div>
          </div>
        </section>
      </main>
    </React.Fragment>
  );
};

export default ResetPasswordPage;
