import { ReactNode } from 'react';
import { cn } from 'util/cn';

type PropsType = {
  className?: string;
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  size?: 'sm' | 'md' | 'lg';
  children?: ReactNode | string;
};

const Headline = ({
  className,
  tag = 'h3',
  size = 'md',
  children,
}: PropsType) => {
  const Tag = tag;
  const sizeClass = {
    sm: 'font-bold text-[24px] leading-[28.06px]',
    lg: 'font-bold text-[32px] leading-[37.41px]',
  };

  return (
    <Tag
      className={cn(
        'text-tailgrids-black font-karla',
        sizeClass[size],
        className
      )}
    >
      {children}
    </Tag>
  );
};

export default Headline;
