import { cn } from 'util/cn';

type PropsType = {
  className?: string;
};

const Upload = ({ className }: PropsType) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={cn('text-black', className)}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.0013 12.041C3.46154 12.041 3.83464 12.4141 3.83464 12.8743V16.2077C3.83464 16.4287 3.92243 16.6407 4.07871 16.7969C4.23499 16.9532 4.44695 17.041 4.66797 17.041H16.3346C16.5556 17.041 16.7676 16.9532 16.9239 16.7969C17.0802 16.6407 17.168 16.4287 17.168 16.2077V12.8743C17.168 12.4141 17.5411 12.041 18.0013 12.041C18.4615 12.041 18.8346 12.4141 18.8346 12.8743V16.2077C18.8346 16.8707 18.5712 17.5066 18.1024 17.9754C17.6336 18.4443 16.9977 18.7077 16.3346 18.7077H4.66797C4.00493 18.7077 3.36904 18.4443 2.9002 17.9754C2.43136 17.5066 2.16797 16.8707 2.16797 16.2077V12.8743C2.16797 12.4141 2.54106 12.041 3.0013 12.041Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.91074 2.28705C10.2362 1.96161 10.7638 1.96161 11.0893 2.28705L15.2559 6.45371C15.5814 6.77915 15.5814 7.30679 15.2559 7.63223C14.9305 7.95766 14.4028 7.95766 14.0774 7.63223L10.5 4.05481L6.92259 7.63223C6.59715 7.95766 6.06951 7.95766 5.74408 7.63223C5.41864 7.30679 5.41864 6.77915 5.74408 6.45371L9.91074 2.28705Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.5013 2.04102C10.9615 2.04102 11.3346 2.41411 11.3346 2.87435V12.8744C11.3346 13.3346 10.9615 13.7077 10.5013 13.7077C10.0411 13.7077 9.66797 13.3346 9.66797 12.8744V2.87435C9.66797 2.41411 10.0411 2.04102 10.5013 2.04102Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default Upload;
