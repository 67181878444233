import { ImageSkeleton, Skeleton } from 'components';
import { cn } from 'util/cn';

type PropsType = {
  size?: 'md' | 'lg';
};

const AnnouncementSkeleton = ({ size = 'md' }: PropsType) => {
  return (
    <div
      className={cn(
        'flex gap-[20px] py-[24px] bg-[#FCFCFC] text-start w-full',
        size === 'md' ? 'px-[18px] rounded-[10px]' : 'px-[24px] rounded-2xl'
      )}
    >
      <ImageSkeleton className='w-[189px] min-w-[189px]' />
      <div className='w-full'>
        <Skeleton
          className={cn('h-[20px]', size === 'md' && 'max-w-[150px]')}
        />
        <Skeleton
          className={cn(
            'mt-[16px]',
            size === 'md' ? 'max-w-[250px]' : 'max-w-3xl'
          )}
        />
        <Skeleton className='mt-[10px] max-w-5xl' />
        <Skeleton
          className={cn(
            'mt-[16px]',
            size === 'md' ? 'max-w-[200px]' : 'max-w-xl'
          )}
        />
      </div>
    </div>
  );
};

export default AnnouncementSkeleton;
