import { ActionType } from '../actionTypes/index';

const initialState = {
  details: {},
  postal_address: {},
  physical_address: {},
  team_members: [],
};

const practiceReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case ActionType.GET_PRACTICE_DETAIL:
      return {
        ...state,
        detail: action.payload.data.results,
      };

    case ActionType.GET_PRACTICE_POSTAL_ADDRESS:
      return {
        ...state,
        postal_address: action.payload.data.results,
      };

    case ActionType.GET_PRACTICE_PHYSICAL_ADDRESS:
      return {
        ...state,
        physical_address: action.payload.data.results,
      };

    case ActionType.GET_TEAM_MEMBERS:
      return {
        ...state,
        team_members: action.payload.data.results,
      };

    case ActionType.GET_OTHER_PHOTOS:
      return {
        ...state,
        other_photos: action.payload.data.results,
      };

    default:
      return state;
  }
};

export default practiceReducer;
