/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { XCircleIcon } from "@heroicons/react/outline";
import DatePicker from "react-datepicker";
import PromptModal from "./prompt";
import { cancelSubscription, getUserPaymentInfo, updateSubscription } from "redux/actionCreators/auth";
import { toast } from "react-toastify";


interface RootState {
    auth: any,
    practice: any
}
const SuspendSubscription = (props: any) => {
    const state = useSelector((state: RootState) => state);

    const dispatch = useDispatch();


    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState(null);
    const [isCancel, setIsCancel] = useState(false);
    const [suspend, setSuspend] = useState(false);

    const [promptModal, setPromptModal] = useState(false);


    const [startDate, setStartDate] = useState(new Date());

    const [endDate, setEndDate] = useState(null);

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };


    useEffect(() => {
        const init = async () => {
            // check if video already included in playlist
        };

        init();
    }, [props]);

    const cancelSubscriptionRequest = async () => {

        console.log("==== Cancelling ====");

        setIsCancel(true);

        await cancelSubscription({
            subscription_id: state.auth.payment.subscription.id
        });

        setIsCancel(false);

        dispatch(
            getUserPaymentInfo({
                customer_id: state?.practice?.detail?.StripeID,
            })
        );


        setPromptModal(false);

        props.setSuspendSubscription(false)

        toast.success(`Subscription cancelled successfully.`);

    }

    const updateSubscriptionRequest = async () => {

        setSuspend(true);

        await updateSubscription({
            subscription_id: state.auth.payment.subscription.id,
            cancel_at: moment(startDate).format("x")
        })

        dispatch(
            getUserPaymentInfo({
                customer_id: state?.practice?.detail?.StripeID,
            })
        );

        setSuspend(false);

        props.setSuspendSubscription(false);

        toast.success(`Subscription updated successfully.`);



    }

    return (
        <React.Fragment>
            <>
                <Modal
                    isOpen={props.suspendSubscription}
                    onRequestClose={() => props.setSuspendSubscription(false)}
                    style={{
                        overlay: {
                            position: "relative",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: "rgba(255, 255, 255, 0.75)",
                            overflow: "hidden",
                        },
                    }}
                >
                    <div>
                        <div className="z-modal animate-fade-in bg-bg900 fixed left-0 top-0 flex flex-col items-stretch w-full h-screen bg-opacity-90 overflow-y-auto">
                            <div className="flex flex-grow items-center justify-center py-4 w-full">
                                <div
                                    role="dialog"
                                    tabIndex={-1}
                                    data-ismodal="true"
                                    className="focus:outline-none "
                                    style={{ width: 598 }}
                                >
                                    <div className="bg-bg100 rounded-4xl relative py-6 px-6">
                                        <button
                                            className="absolute z-30 right-3 top-3 lt-item-icon-box lt-item-box-sizing lt-item-box-bg  close-popup-practice"
                                            type="button"
                                            onClick={() => props.setSuspendSubscription(false)}
                                        >
                                            <span className="flex items-center gap-4px">
                                                <svg
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    aria-hidden="true"
                                                    focusable="false"
                                                    role="presentation"
                                                    className="index-StyledSvg_cls2__nHIIG index-StyledSvg_cls1__3YcLt"
                                                    style={{ width: 24, height: 24 }}
                                                    width={24}
                                                    height={24}
                                                >
                                                    <defs>
                                                        <symbol id="close" viewBox="0 0 24 24">
                                                            <path d="M19.707 5.707a1 1 0 0 0-1.414-1.414L12 10.586 5.707 4.293a1 1 0 1 0-1.414 1.414L10.586 12l-6.293 6.292a1 1 0 1 0 1.414 1.415L12 13.414l6.293 6.293a1 1 0 0 0 1.414-1.415L13.414 12l6.293-6.293z" />
                                                        </symbol>
                                                    </defs>
                                                    <use xlinkHref="#close" fill="#FFFFFF" />
                                                </svg>
                                            </span>
                                        </button>
                                        <div className="flex items-center justify-between w-full">
                                            <p className="v-bs-h600 undefined">Suspension of subscription</p>
                                        </div>

                                        <form className="flex flex-col gap-6">

                                            <p className="text-text800 v-bs-p300">
                                                Specify the date until which you want to suspend the subscription
                                            </p>

                                            {(error && message) && (
                                                <>
                                                    <div className="rounded-md bg-red-50 p-2 ">
                                                        <div className="flex">
                                                            <div className="flex-shrink-0">
                                                                <XCircleIcon className="h-3 w-4 text-red-400" aria-hidden="true" />
                                                            </div>
                                                            <div className="ml-3 mt-0.5">
                                                                <h3 className="text-sm font-medium text-red-800">{message}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                            <div className="">

                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={(date) => setStartDate(date)}
                                                    minDate={new Date()}
                                                    inline
                                                />


                                            </div>
                                            <button type="button" className="update-card-vs-bs-btn"

                                                disabled={suspend}
                                                onClick={updateSubscriptionRequest}

                                            >
                                                {suspend ? (
                                                    <>
                                                        <svg
                                                            className=" text-center w-3 h-3 mr-3 -ml-1 text-white animate-spin"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                        >
                                                            <circle
                                                                className="opacity-25"
                                                                cx="12"
                                                                cy="12"
                                                                r="10"
                                                                stroke="currentColor"
                                                                stroke-width="4"
                                                            ></circle>
                                                            <path
                                                                className="opacity-75"
                                                                fill="currentColor"
                                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                            ></path>
                                                        </svg>
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className=" flex items-center gap-4px">
                                                            Suspend subscription until {moment(
                                                                startDate
                                                            ).format("MMM, DD YYYY")}
                                                        </span>
                                                    </>
                                                )}
                                            </button>

                                            <button
                                                type="button"
                                                disabled={isCancel}
                                                onClick={() => setPromptModal(true)}

                                                className="  text-center w-full   remove-btn cancel-btn"
                                            >
                                                {isCancel ? (
                                                    <>
                                                        <svg
                                                            className=" text-center w-3 h-3 mr-3 -ml-1 text-black animate-spin"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                        >
                                                            <circle
                                                                className="opacity-25"
                                                                cx="12"
                                                                cy="12"
                                                                r="10"
                                                                stroke="currentColor"
                                                                stroke-width="4"
                                                            ></circle>
                                                            <path
                                                                className="opacity-75"
                                                                fill="currentColor"
                                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                            ></path>
                                                        </svg>
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className=" flex items-center gap-4px">
                                                            Cancel subscription
                                                        </span>
                                                    </>
                                                )}
                                            </button>

                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

                <PromptModal
                    promptModal={promptModal}
                    setPromptModal={setPromptModal}
                    btnText="Yes"
                    btnTextCancel="No"
                    title={`Cancel Subscription`}
                    description="Are you sure you want to cancel your subscription?"
                    onSuccess={cancelSubscriptionRequest}
                />
            </>


        </React.Fragment>
    );
};

export default SuspendSubscription;
