const isLocalhost = (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") ? true : false;
const isTesting   = window.location.hostname  === "v2-test.channeld.com" ? true : false;


const config = {
    defaults: {
        namespace: 'Application',
        api_url: isLocalhost ? 'http://localhost:8000': isTesting ? 'https://api-staging.channeld.com:3000' : 'https://api-staging.channeld.com' ,
    }
}

export default config;
