import React from "react";

const SideCaughtUp = () => {
  return (
    <div className="caughtUp_wrapper">
      <div className="caughtup_image"></div>
      <div className="caughtup_text">
        <h2>Everything is good!</h2>
        <p>You're all caught up</p>
      </div>
    </div>
  );
};

export default SideCaughtUp;
