import { Body, Headline, Title } from 'components';

type PropsType = {
  title: string;
  description?: string;
  src?: string;
};

const AnnouncementModal = ({ title, description, src }: PropsType) => {
  return (
    <div className='space-y-[20px]'>
      <Headline size='lg'>Announcement</Headline>
      {src && (
        <img
          src={src}
          alt='banner'
          className='w-full aspect-video object-cover rounded-[24px]'
        />
      )}
      <Title size='lg'>{title}</Title>
      <Body size='regular' className='text-tailgrids-black'>
        {description}
      </Body>
    </div>
  );
};

export default AnnouncementModal;
