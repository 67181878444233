/* eslint-disable react/style-prop-object */
import React, { useState } from 'react';
import ChannelDLogo from 'assets/img/channellg-logo.svg';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import cookie from 'js-cookie';
import Tooltip from 'react-simple-tooltip';
import { css } from 'styled-components';
import { useLocation } from 'react-router-dom';

const usePathname = () => {
  const location = useLocation();
  return location.pathname;
};

interface RootState {
  practice: any;
}

const PracticeSideBar = (props: any) => {
  const state = useSelector((state: RootState) => state);
  const [menu, setMenu] = useState(false);

  const navigate = useNavigate();

  const LogOut = () => {
    cookie.remove('token');
    cookie.remove('admin');
    navigate('/');
    return;
  };

  const pathname = usePathname();

  return (
    <React.Fragment>
      <div className='fixed z-10 h-full'>
        <div className='flex flex-col items-center pb-4 pt-4 w-14 h-screen'>
          <Link to='/'>
            <div className='channeld-d-logo'>
              <img src={ChannelDLogo} alt='' />
            </div>
          </Link>
          <div className='flex-1 mt-20'>
            <div className='flex flex-col gap-3'>
              <div
                className={`main-nav-lt-items ${
                  props.teams === true || pathname === '/announcements'
                    ? 'main-nav-lt-item-active'
                    : ''
                }`}
              >
                <Link
                  to='/dashboard'
                  className='lt-item-icon-box  lt-item-box-sizing lt-item-box-bg'
                >
                  <span className=' flex items-center gap-4px'>
                    <Tooltip
                      arrow={12}
                      background='#FFFFFF'
                      border='#FFFFFF'
                      color='black'
                      content='Practice'
                      fontFamily='inherit'
                      fontSize='12px'
                      padding={10}
                      placement='right'
                      radius={20}
                      zIndex={1}
                      customCss={css`
                        font-weight: bold;
                      `}
                    >
                      <svg
                        xmlnsXlink='http://www.w3.org/1999/xlink'
                        xmlns='http://www.w3.org/2000/svg'
                        aria-hidden='true'
                        focusable='false'
                        role='presentation'
                        className='svg-icon-24 index-StyledSvg_cls2__nHIIG index-StyledSvg_cls1__3YcLt'
                        width='24'
                        height='24'
                      >
                        <defs>
                          <symbol id='users' viewBox='0 0 24 24'>
                            <path
                              fillRule='evenodd'
                              d='M1.464 15.464A5 5 0 0 1 5 14h8a5 5 0 0 1 5 5v2a1 1 0 1 1-2 0v-2a3 3 0 0 0-3-3H5a3 3 0 0 0-3 3v2a1 1 0 1 1-2 0v-2a5 5 0 0 1 1.464-3.536zM9 4a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM4 7a5 5 0 1 1 10 0A5 5 0 0 1 4 7zM19.032 14.88a1 1 0 0 1 1.218-.718A5 5 0 0 1 24 18.999V21a1 1 0 1 1-2 0v-2s0 .001 0 0a3.001 3.001 0 0 0-2.25-2.902 1 1 0 0 1-.718-1.218zM15.031 2.882a1 1 0 0 1 1.217-.72 5 5 0 0 1 0 9.687 1 1 0 1 1-.496-1.938 3 3 0 0 0 0-5.812 1 1 0 0 1-.72-1.217z'
                              clipRule='evenodd'
                            ></path>
                          </symbol>
                        </defs>
                        <use xlinkHref='#users'></use>
                      </svg>
                    </Tooltip>
                  </span>
                </Link>
              </div>
              <div
                className={`main-nav-lt-items ${
                  props.library === true ? 'main-nav-lt-item-active' : ''
                }`}
              >
                <Link
                  to='/library/office'
                  className='lt-item-icon-box  lt-item-box-sizing lt-item-box-bg'
                >
                  <span className=' flex items-center gap-4px'>
                    <Tooltip
                      arrow={12}
                      background='#FFFFFF'
                      border='#FFFFFF'
                      color='black'
                      content='Library'
                      fontFamily='inherit'
                      fontSize='12px'
                      padding={10}
                      placement='right'
                      radius={20}
                      zIndex={1}
                      customCss={css`
                        font-weight: bold;
                      `}
                    >
                      <svg
                        xmlnsXlink='http://www.w3.org/1999/xlink'
                        xmlns='http://www.w3.org/2000/svg'
                        aria-hidden='true'
                        focusable='false'
                        role='presentation'
                        className='svg-icon-24 index-StyledSvg_cls2__nHIIG index-StyledSvg_cls1__3YcLt'
                        width='24'
                        height='24'
                      >
                        <defs>
                          <symbol id='library' viewBox='0 0 24 24'>
                            <path
                              fillRule='evenodd'
                              d='M4.18 3A1.18 1.18 0 0 0 3 4.18v15.64c0 .652.528 1.18 1.18 1.18h15.64A1.18 1.18 0 0 0 21 19.82V4.18A1.18 1.18 0 0 0 19.82 3H4.18zM1 4.18A3.18 3.18 0 0 1 4.18 1h15.64A3.18 3.18 0 0 1 23 4.18v15.64A3.18 3.18 0 0 1 19.82 23H4.18A3.18 3.18 0 0 1 1 19.82V4.18z'
                              clipRule='evenodd'
                            ></path>
                            <path
                              fillRule='evenodd'
                              d='M7 1a1 1 0 0 1 1 1v20a1 1 0 1 1-2 0V2a1 1 0 0 1 1-1zM17 1a1 1 0 0 1 1 1v20a1 1 0 1 1-2 0V2a1 1 0 0 1 1-1z'
                              clipRule='evenodd'
                            ></path>
                            <path
                              fillRule='evenodd'
                              d='M1 12a1 1 0 0 1 1-1h20a1 1 0 1 1 0 2H2a1 1 0 0 1-1-1zM1 7a1 1 0 0 1 1-1h5a1 1 0 0 1 0 2H2a1 1 0 0 1-1-1zM1 17a1 1 0 0 1 1-1h5a1 1 0 1 1 0 2H2a1 1 0 0 1-1-1zM16 17a1 1 0 0 1 1-1h5a1 1 0 1 1 0 2h-5a1 1 0 0 1-1-1zM16 7a1 1 0 0 1 1-1h5a1 1 0 1 1 0 2h-5a1 1 0 0 1-1-1z'
                              clipRule='evenodd'
                            ></path>
                          </symbol>
                        </defs>
                        <use xlinkHref='#library'></use>
                      </svg>
                    </Tooltip>
                  </span>
                </Link>
              </div>
              <div
                className={`main-nav-lt-items ${
                  props.page === 'playlist' ? 'main-nav-lt-item-active' : ''
                }`}
              >
                <Link
                  to='/playlist/office'
                  className='lt-item-icon-box  lt-item-box-sizing lt-item-box-bg'
                >
                  <span className=' flex items-center gap-4px'>
                    <Tooltip
                      arrow={12}
                      background='#FFFFFF'
                      border='#FFFFFF'
                      color='black'
                      content='Playlist'
                      fontFamily='inherit'
                      fontSize='12px'
                      padding={10}
                      placement='right'
                      radius={20}
                      zIndex={1}
                      customCss={css`
                        font-weight: bold;
                      `}
                    >
                      <svg
                        xmlnsXlink='http://www.w3.org/1999/xlink'
                        xmlns='http://www.w3.org/2000/svg'
                        aria-hidden='true'
                        focusable='false'
                        role='presentation'
                        className='svg-icon-24 index-StyledSvg_cls2__nHIIG index-StyledSvg_cls1__3YcLt'
                        width='24'
                        height='24'
                      >
                        <defs>
                          <symbol id='play-circle' viewBox='0 0 24 24'>
                            <path
                              fillRule='evenodd'
                              d='M12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18zM1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12z'
                              clipRule='evenodd'
                            ></path>
                            <path
                              fillRule='evenodd'
                              d='M9.528 7.118a1 1 0 0 1 1.027.05l6 4a1 1 0 0 1 0 1.664l-6 4A1 1 0 0 1 9 16V8a1 1 0 0 1 .528-.882zM11 9.868v4.264L14.197 12 11 9.869z'
                              clipRule='evenodd'
                            ></path>
                          </symbol>
                        </defs>
                        <use xlinkHref='#play-circle'></use>
                      </svg>
                    </Tooltip>
                  </span>
                </Link>
              </div>
              <div
                className={`main-nav-lt-items ${
                  props.page === 'tv' ? 'main-nav-lt-item-active' : ''
                }`}
              >
                <Link
                  to='/tv'
                  className='lt-item-icon-box  lt-item-box-sizing lt-item-box-bg'
                >
                  <span className=' flex items-center gap-4px'>
                    <Tooltip
                      arrow={12}
                      background='#FFFFFF'
                      border='#FFFFFF'
                      color='black'
                      content='TV Setup'
                      fontFamily='inherit'
                      fontSize='12px'
                      padding={10}
                      placement='right'
                      radius={20}
                      zIndex={1}
                      customCss={css`
                        font-weight: bold;
                      `}
                    >
                      <svg
                        xmlnsXlink='http://www.w3.org/1999/xlink'
                        xmlns='http://www.w3.org/2000/svg'
                        aria-hidden='true'
                        focusable='false'
                        role='presentation'
                        className='svg-icon-24 index-StyledSvg_cls2__nHIIG index-StyledSvg_cls1__3YcLt'
                        width='24'
                        height='24'
                      >
                        <defs>
                          <symbol id='tv' viewBox='0 0 24 24'>
                            <path
                              fillRule='evenodd'
                              d='M4 8a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1H4zM1 9a3 3 0 0 1 3-3h16a3 3 0 0 1 3 3v11a3 3 0 0 1-3 3H4a3 3 0 0 1-3-3V9z'
                              clipRule='evenodd'
                            ></path>
                            <path
                              fillRule='evenodd'
                              d='M6.293 1.293a1 1 0 0 1 1.414 0L12 5.586l4.293-4.293a1 1 0 1 1 1.414 1.414l-5 5a1 1 0 0 1-1.414 0l-5-5a1 1 0 0 1 0-1.414z'
                              clipRule='evenodd'
                            ></path>
                          </symbol>
                        </defs>
                        <use xlinkHref='#tv'></use>
                      </svg>
                    </Tooltip>
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div className='relative w-6 h-6'>
            <button
              onClick={() => setMenu(!menu)}
              className='bg-transparent border-0 focus:outline-none outline-none transition-all'
              type='button'
            >
              <div
                style={{
                  backgroundImage: `url(${state.practice?.detail?.FilePath})`,
                }}
                className='border-bg900 border practive-box-holder  notification-p-logo practice-box-holder-border'
              ></div>
            </button>

            {menu && (
              <>
                <div className='user-menu-StyledUserMenu_cls2__2HmiN user-menu-StyledUserMenu_cls1__3rd7Z'>
                  <div className='flex flex-col gap-1'>
                    <Link className='v-bs-list100' to='/practice/details'>
                      Practice settings
                    </Link>
                    <Link className='v-bs-list100' to='/practice/payments'>
                      Payments
                    </Link>
                    <Link className='v-bs-list100' to='/practice/account'>
                      Account
                    </Link>
                  </div>
                  <div>
                    <div className='mb-12px mt-2 Separator_footer'></div>
                    <div className='flex justify-between'>
                      <button
                        className='bg-transparent border-0 focus:outline-none outline-none'
                        type='button'
                      >
                        <div className='border-bg900 border index-StyledImageThumb_cls2__1_8E6 index-StyledImageThumb_cls1__1GuPE index-StyledImageThumb_size-300__Bx9Cs index-StyledImageThumb_variant-round__2AGk0'></div>
                      </button>
                      <button
                        onClick={LogOut}
                        className='px-0 index-StyledButton_cls2__1Drrf index-StyledButton_cls1__1qkPE index-StyledButton_isFocusable__aUCeS index-StyledButton_variant-link__1TBC0'
                      >
                        <span className=' flex items-center gap-4px'>
                          Log out
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PracticeSideBar;
