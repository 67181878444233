/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import IPage from "interfaces/page";
import { useLocation, useSearchParams } from "react-router-dom";
import { redirectUser } from "services/user";
import queryString from "query-string";

const ThankyouPage: React.FunctionComponent<IPage> = (props) => {
  const location = useLocation();

  const query = queryString.parse(location.search);

  useEffect(() => {
    const init = async () => {
      console.log("==== Query ====");

      console.log(query["thrivecart[customer][email]"]);

      const _res = await redirectUser({
        email: query["thrivecart[customer][email]"],
      });

      if (_res.data.url) {
        window.location.replace(_res.data.url);
      }
    };

    init();
  }, [query]);

  return (
    <React.Fragment>
      <div className="flex items-center justify-center h-screen">
        <div>
          <div className="flex flex-col items-center justify-center space-y-2">
            <svg
              width="180"
              height="180"
              viewBox="0 0 190 190"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M59.375 95.0006L83.125 118.751L130.625 71.2506M174.167 95.0006C174.167 138.723 138.723 174.167 95 174.167C51.2775 174.167 15.8334 138.723 15.8334 95.0006C15.8334 51.2781 51.2775 15.834 95 15.834C138.723 15.834 174.167 51.2781 174.167 95.0006Z"
                stroke="#039855"
                stroke-width="7"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <h1 className="text-4xl font-bold">Success!</h1>
            <p>Thank you for signing up with Channel D.</p>
            <p>You will be redirected to dashboard in few secs.</p>
            <button
              disabled={true}
              className="inline-flex items-center px-4 py-2 text-white "
            >
              <span className="text-sm font-medium">
                <svg
                  className=" text-center w-3 h-3 text-white animate-spin m-auto text-black"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ThankyouPage;
