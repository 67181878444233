/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { XCircleIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import { FileUploader } from "react-drag-drop-files";
import { getPracticeDetail, uploadPracticeLogo } from "redux/actionCreators/practice";


interface RootState {
    auth: any;
    practice: any;
    videos: any;
}
const UploadLogoModal = (props: any) => {
    const state = useSelector((state: RootState) => state);

    const dispatch = useDispatch();

    console.log("==== Props Upload Logo ====");
    console.log(props);

    const [logo, setLogo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState(null);

    const fileTypes = ["JPG", "PNG"];

    const [previewUrl, setPreviewUrl] = useState(null);



    useEffect(() => {
        const init = async () => {
            // check if video already included in playlist
        };

        init();
    }, [props]);


    const handleChange = async (file) => {
        setPreviewUrl(URL.createObjectURL(file));
        setLogo(file);
    };

    const handleSubmit = async () => {
        setLoading(true);
        await uploadPracticeLogo(logo);
        dispatch(getPracticeDetail());
        setLoading(false);
        setPreviewUrl(null);
        props.setUploadLogoModal(false);
    }


    return (
        <React.Fragment>
            <>
                <Modal
                    isOpen={props.openUploadLogoModal}
                    onRequestClose={() => props.setUploadLogoModal(false)}
                    style={{
                        overlay: {
                            position: "relative",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: "rgba(255, 255, 255, 0.75)",
                            overflow: "hidden",
                        },
                    }}
                >
                    <div className="z-confirm animate-fade-in bg-bg900 fixed left-0 top-0 flex flex-col items-center justify-center w-full h-screen bg-opacity-90 overflow-y-auto">
                        <div className="upload-logo-w confirm-modal-StyledConfirm_cls2__2TkMw confirm-modal-StyledConfirm_cls1__3hxBv">
                            <h5 className="v-bs-h500">{props.title}</h5>


                            <label>Upload logo</label>

                            <FileUploader
                                name="file"
                                handleChange={handleChange}
                                types={fileTypes}
                            >
                                <div className="gap-12px grid grid-cols-2">

                                    <div className="w-[333px] h-[250px] inline-flex justify-center flex-col items-center border-dashed border-2 border-[#000] rounded-[6px]">
                                        {previewUrl ? (

                                            <>
                                                <img
                                                    className="rounded-xl"
                                                    src={previewUrl}
                                                    alt=""
                                                    width={250}
                                                    height={150}
                                                />

                                                <p className="text-[12px] mt-3 font-[400] text-[#637381]">
                                                    Click here to change
                                                </p>
                                            </>
                                        ) : <>

                                            <input
                                                className="video w-[20px] leading-[10px] text-[30px] cursor-pointer before:content-['+']"
                                                type="file"
                                                accept="video/*"
                                            />
                                            <p className="text-[12px] font-[400] text-[#637381]">
                                                Upload
                                            </p>
                                        </>}

                                    </div>



                                </div>
                            </FileUploader>
                            <div className="gap-12px grid grid-cols-2">
                                <button className="cancel-btn-rm-vs"
                                    disabled={loading}
                                    onClick={() => props.setUploadLogoModal(false)}

                                >
                                    <span className="flex items-center gap-4px">{props.btnTextCancel ? props.btnTextCancel : "Cancel"}</span>
                                </button>
                                <button className="resume-btn-rm-vs"
                                    onClick={handleSubmit}
                                    disabled={loading}

                                >
                                    <span className="flex items-center gap-4px m-auto">


                                        {loading ? (
                                            <>
                                                <svg
                                                    className=" text-center w-3 h-3 text-white animate-spin m-auto"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle
                                                        className="opacity-25"
                                                        cx="12"
                                                        cy="12"
                                                        r="10"
                                                        stroke="currentColor"
                                                        stroke-width="4"
                                                    ></circle>
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    ></path>
                                                </svg>
                                            </>
                                        ) : (
                                            <>
                                                <span className=" flex items-center gap-4px">
                                                    Add logo
                                                </span>
                                            </>
                                        )}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        </React.Fragment>
    );
};

export default UploadLogoModal;
