/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import InputMask from "react-input-mask";
import valid from "card-validator";
import PaymentIcon from 'react-payment-icons';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { XCircleIcon } from "@heroicons/react/outline";
import { updateCard } from "redux/actionCreators/auth";
import { toast } from "react-toastify";


interface RootState {
  practice: any;
  videos: any;
}
const UpdateCardModal = (props: any) => {
  const state = useSelector((state: RootState) => state);

  const [cardNumber, setCardNumber] = useState(null);
  const [exp_month, setExpMonth] = useState(null);
  const [exp_year, setExpYear] = useState(null);
  const [cvc, setCvc] = useState(null);
  const [cardType, setCardType] = useState(null);
  const [isCardNumberValid, setIsCardNumberValid] = useState(false);
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);



  useEffect(() => {
    const init = async () => {
      // check if video already included in playlist
    };

    init();
  }, [props]);


  const handleSubmit = async () => {

    setLoading(true);

    const card_data = {
      card_number: cardNumber,
      card_exp_month: parseInt(exp_month),
      card_exp_year: parseInt(exp_year),
      card_cvc: cvc
    }

    console.log(card_data)

    const update = await updateCard(card_data);

    if(update.data.error){

      setError(true);

      setMessage(update.data.message);

    } else {

      setError(false);

      setMessage(null);

      props.setUpdateCardModal(false);

      toast.success(`Payment card updated successfully.`);

    }

    console.log(update);

    setLoading(false);
  }


  return (
    <React.Fragment>
      <>
        <Modal
          isOpen={props.updateCardModal}
          onRequestClose={() => props.setUpdateCardModal(false)}
          style={{
            overlay: {
              position: "relative",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, 0.75)",
              overflow: "hidden",
            },
          }}
        >
          <div>
            <div className="z-modal animate-fade-in bg-bg900 fixed left-0 top-0 flex flex-col items-stretch w-full h-screen bg-opacity-90 overflow-y-auto">
              <div className="flex flex-grow items-center justify-center py-4 w-full">
                <div
                  role="dialog"
                  tabIndex={-1}
                  data-ismodal="true"
                  className="focus:outline-none "
                  style={{ width: 598 }}
                >
                  <div className="bg-bg100 rounded-4xl relative py-6 px-6">
                    <button
                      className="absolute z-30 right-3 top-3 lt-item-icon-box lt-item-box-sizing lt-item-box-bg  close-popup-practice"
                      type="button"
                      onClick={() => props.setUpdateCardModal(false)}
                    >
                      <span className="flex items-center gap-4px">
                        <svg
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          focusable="false"
                          role="presentation"
                          className="index-StyledSvg_cls2__nHIIG index-StyledSvg_cls1__3YcLt"
                          style={{ width: 24, height: 24 }}
                          width={24}
                          height={24}
                        >
                          <defs>
                            <symbol id="close" viewBox="0 0 24 24">
                              <path d="M19.707 5.707a1 1 0 0 0-1.414-1.414L12 10.586 5.707 4.293a1 1 0 1 0-1.414 1.414L10.586 12l-6.293 6.292a1 1 0 1 0 1.414 1.415L12 13.414l6.293 6.293a1 1 0 0 0 1.414-1.415L13.414 12l6.293-6.293z" />
                            </symbol>
                          </defs>
                          <use xlinkHref="#close" fill="#FFFFFF" />
                        </svg>
                      </span>
                    </button>
                    <div className="flex items-center justify-between w-full">
                      <p className="v-bs-h600 undefined">Update card</p>
                    </div>



                    <form className="flex flex-col gap-6">

                      <p className="text-text800 v-bs-p300">
                        Indicate the details of the bank card with which you
                        want to pay for Channel D services.
                      </p>

                      {(error && message) && (
                        <>
                          <div className="rounded-md bg-red-50 p-2 ">
                            <div className="flex">
                              <div className="flex-shrink-0">
                                <XCircleIcon className="h-3 w-4 text-red-400" aria-hidden="true" />
                              </div>
                              <div className="ml-3 mt-0.5">
                                <h3 className="text-sm font-medium text-red-800">{message}</h3>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      <div className="card-form-CardWrapper_update_f">
                        <div className="transform-preserve-3d transform-3d z-50 left-0 top-0 card-form-CardShape_update_f card-form-CardShape_cls1__enbPK">
                          <div>
                            <PaymentIcon
                              id={cardType || ""}
                              style={{ width: 50 }}
                              className="payment-icon"
                            />
                            <span className="v-bs-label200">Card number</span>
                            <span className={`card-form-StripeInput_update_card  v-bs-input-value200 px-2 py-1 h-6 rounded-lg border-2 border-outline900 transition-colors duration-300 StripeElement--empty`}>
                              <div className="__PrivateStripeElement c-style-1">
                                <InputMask
                                  className="__PrivateStripeElement-input c-style-3 "
                                  aria-hidden="true"
                                  aria-label=" "
                                  autoComplete="false"
                                  mask="9999-9999-9999-9999"
                                  onChange={(event) => {
                                    const value = event.target.value;

                                    setCardNumber(value);

                                    const number = valid.number(value);

                                    if (number.isPotentiallyValid) {
                                      setCardType(number.card.type);
                                      setIsCardNumberValid(true);
                                    } else {

                                      setCardType(null);
                                      setIsCardNumberValid(false)
                                    }
                                  }}
                                />
                              </div>
                            </span>
                          </div>
                          <div className="gap-4px w-22 flex items-center self-end">
                            <span className="v-bs-label200">Expiration</span>
                            <span className="card-form-StripeInput_update_card  v-bs-input-value200 px-2 py-1 h-6 rounded-lg border-2 border-outline900 transition-colors duration-300 StripeElement--empty">
                              <div className="__PrivateStripeElement c-style-4">
                                <InputMask
                                  className="__PrivateStripeElement-input c-style-6"
                                  aria-hidden="true"
                                  aria-label=" "
                                  autoComplete="false"
                                  placeholder="MM/YY"
                                  mask="99/99"
                                  onChange={(event) => {
                                    const [ month , year] = event.target.value.split("/");
                                    setExpMonth(month);
                                    setExpYear(year);
                                    
                                  }}

                                />
                              </div>
                            </span>
                          </div>
                        </div>
                        <div className="transform-preserve-3d transform-3d -translate-z-1px absolute z-0 bottom-3 right-3 top-12 card-form-CardShape_update_f card-form-CardShape_cls1__enbPK">
                          <div className="bg-bg900 absolute left-0 right-0 top-6 w-full h-9" />
                          <div className="gap-4px flex items-center self-end w-14">
                            <span className="v-bs-label200">CVC</span>
                            <span className="card-form-StripeInput_update_card  v-bs-input-value200 px-2 py-1 h-6 rounded-lg border-2 border-outline900 transition-colors duration-300 StripeElement--empty">
                              <div className="__PrivateStripeElement c-style-7">
                                <InputMask
                                  className="__PrivateStripeElement-input c-style-9"
                                  aria-hidden="true"
                                  aria-label=" "
                                  autoComplete="false"
                                  placeholder="CVC"
                                  mask="999"
                                  onChange={(event) => {
                                    const value = event.target.value;
                                    console.log(value);
                                    setCvc(value);
                                  }}
                                />
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                      <button type="button" className="update-card-vs-bs-btn"

                        disabled={loading}

                        onClick={handleSubmit}
                      >
                        {loading ? (
                          <>
                            <svg
                              className=" text-center w-3 h-3 mr-3 -ml-1 text-white animate-spin"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                stroke-width="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          </>
                        ) : (
                          <>
                            <span className=" flex items-center gap-4px">
                               Update card
                            </span>
                          </>
                        )}
                      </button>

                    </form>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    </React.Fragment>
  );
};

export default UpdateCardModal;
