import { useEffect, useState } from 'react';
import {
  Body,
  Button,
  Divider,
  Headline,
  ModalType,
  Skeleton,
  useModalActions,
} from 'components';
import {
  EditMember,
  MemberType,
  MemberVideo,
  VideoSkeleton,
  useMembers,
  useMembersAction,
} from 'features/team-members';
import { getTeamMemberVideos } from 'redux/actionCreators/practice';

type PropsType = {
  id?: number;
};

const MemberDetails = ({ id }: PropsType) => {
  const [member, setMember] = useState<MemberType>(null);
  const [isFetching, setIsFetching] = useState(false);

  const members = useMembers();
  const { get } = useMembersAction();
  const { trigger } = useModalActions();

  const editMemberModal: ModalType = {
    type: 'edit-member',
    component: (
      <EditMember
        id={id}
        src={member?.src}
        name={member?.name}
        role={member?.role}
        shortname={member?.shortname}
      />
    ),
    noPadding: true,
  };

  useEffect(() => setMember(get(id)), [members, get, id]);

  useEffect(() => {
    const getMemberVideos = async () => {
      setIsFetching(true);
      const res = await getTeamMemberVideos(id);
      if (res) {
        setIsFetching(false);
        member.videos = res.data.results.map(
          ({
            title,
            category_name,
            thumbnail_file,
            video_file,
            sample_file,
          }) => {
            return {
              title,
              category: category_name,
              thumbnail: thumbnail_file,
              video: video_file,
              sample: sample_file,
            };
          }
        );
        member.videosFetched = true;
      }
    };
    if (id && member && !member.videosFetched) getMemberVideos();
  }, [id, member]);

  return (
    <div className='text-center'>
      <img
        src={
          member?.src
            ? member?.src
            : require('../../assets/img/team-member-profile.png')
        }
        alt={`${member?.name} profile`}
        className='w-[180px] h-[180px] rounded-full object-cover mx-auto'
      />
      <Headline size='lg' className='mt-[32px]'>
        {member?.name}
      </Headline>
      <Body size='lg' className='text-[#666] mt-[8px]'>
        {member?.role}
      </Body>
      <Button
        className='mt-[19px]'
        size='sm'
        onClick={() => trigger(editMemberModal)}
      >
        Edit Team Member
      </Button>

      <Divider className='mt-[40px] w-[calc(100%+64px)] -ml-[32px]' />

      {isFetching ? (
        <>
          <Skeleton className='mt-[32px] mx-auto max-w-[200px]' />
          <VideoSkeleton />
        </>
      ) : (
        <>
          <Body size='lg' className='text-tailgrids-black mt-[32px]'>
            {member?.videos?.length > 0
              ? `Appears in ${member?.videos?.length} Video${
                  member?.videos?.length > 1 ? 's' : ''
                }`
              : `${member?.name} doesn’t appear in any of your playlist videos.`}
          </Body>
          <MemberVideo videos={member?.videos} />
        </>
      )}
    </div>
  );
};

export default MemberDetails;
