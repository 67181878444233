import { cn } from 'util/cn';

type PropsType = {
  className?: string;
};

const Divider = ({ className }: PropsType) => {
  return <div className={cn('bg-[#E7E7E7] h-[1px] w-full', className)} />;
};

export default Divider;
