import { create } from 'zustand';

export type ModalType = {
  type:
    | 'modal'
    | 'member'
    | 'preview'
    | 'announcement'
    | 'edit-member'
    | 'prompt';
  component: JSX.Element;
  noPadding?: boolean;
};

type Action = {
  trigger: (modal: ModalType) => void;
  close: () => void;
  clear: () => void;
};

type ModalStore = {
  modals: ModalType[];
  actions: Action;
};

const useModalStore = create<ModalStore>((set) => ({
  modals: [],
  actions: {
    trigger: (modal: ModalType) =>
      set((state) => ({ modals: [modal, ...state.modals] })),
    close: () =>
      set((state) => ({
        modals: [...state.modals.filter((_, index) => index !== 0)],
      })),
    clear: () => set({ modals: [] }),
  },
}));

export const useModals = () => useModalStore((state) => state.modals);

export const useModalActions = () => useModalStore((state) => state.actions);
