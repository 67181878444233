/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";

const AnnouncementModal = (props: any) => {
  return (
    <React.Fragment>
      <>
        <Modal
          isOpen={props.openAnnouncementModal}
          onRequestClose={() => props.setAnnouncementModal(false)}
          style={{
            overlay: {
              position: "relative",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, 0.75)",
              overflow: "hidden",
            },
          }}
        >
          <div>
            <div className="z-modal animate-fade-in bg-bg900 fixed left-0 top-0 flex flex-col items-stretch w-full h-screen bg-opacity-90 overflow-y-auto">
              <div className="flex flex-grow items-center justify-center py-4 w-full">
                <div
                  role="dialog"
                  tabIndex={-1}
                  data-ismodal="true"
                  className="focus:outline-none "
                  style={{ width: 598 }}
                >
                  <div className="bg-bg100 rounded-4xl relative py-6 px-6">
                    <button
                      className="absolute z-30 right-3 top-3 lt-item-icon-box lt-item-box-sizing lt-item-box-bg  close-popup-practice"
                      type="button"
                      onClick={() => props.setAnnouncementModal(false)}
                    >
                      <span className="flex items-center gap-4px">
                        <svg
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          focusable="false"
                          role="presentation"
                          className="index-StyledSvg_cls2__nHIIG index-StyledSvg_cls1__3YcLt"
                          style={{ width: 24, height: 24 }}
                          width={24}
                          height={24}
                        >
                          <defs>
                            <symbol id="close" viewBox="0 0 24 24">
                              <path d="M19.707 5.707a1 1 0 0 0-1.414-1.414L12 10.586 5.707 4.293a1 1 0 1 0-1.414 1.414L10.586 12l-6.293 6.292a1 1 0 1 0 1.414 1.415L12 13.414l6.293 6.293a1 1 0 0 0 1.414-1.415L13.414 12l6.293-6.293z" />
                            </symbol>
                          </defs>
                          <use xlinkHref="#close" fill="#FFFFFF" />
                        </svg>
                      </span>
                    </button>
                    <div className="flex items-center justify-between w-full">
                      <p className="text-[#000] text-[20px] pb-[40px] inline-block w-[500px] font-[600]">
                        Announcement
                      </p>
                    </div>

                    <div className="">
                      <div className="">
                        {props.announcement.image && (
                          <>
                            <div className="rounded-[30px] w-full">
                              <img
                                src={props.announcement.image}
                                alt=""
                                className="w-full h-[300px] rounded-[10px]"
                              />
                            </div>
                          </>
                        )}

                        <div className="mt-2 mb-2">
                          <strong> {props.announcement.title} </strong>
                        </div>

                        <p className="mt-4 overflow-auto max-h-[200px]">
                          {props.announcement.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    </React.Fragment>
  );
};

export default AnnouncementModal;
