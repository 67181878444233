import { useState } from 'react';
import ReactPlayer from 'react-player';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { Badge, Body, Headline, Title } from 'components';
import { VideoType } from 'features/team-members';
import { cn } from 'util/cn';

type PropsType = {
  videos: VideoType[];
  index?: number;
};

const MemberVideoPreview = ({ videos, index }: PropsType) => {
  const [active, setActive] = useState(index);

  const next = () => {
    if (active + 1 < videos?.length) {
      setActive(active + 1);
    }
  };
  const prev = () => {
    if (active + 1 > 1) {
      setActive(active - 1);
    }
  };

  return (
    <>
      <Headline className='mb-[24px]' size='lg'>
        Preview Playlist
      </Headline>
      {videos?.length > 0 && (
        <>
          <div className='aspect-video rounded-3xl overflow-hidden'>
            <ReactPlayer
              playing={true}
              controls={true}
              url={
                videos[active].video
                  ? videos[active].video
                  : videos[active].sample
              }
              config={{
                file: {
                  attributes: {
                    controlsList: 'nodownload',
                  },
                },
              }}
              onEnded={() => {
                if (videos?.length === active + 1) {
                  setActive(0);
                } else {
                  setActive(active + 1);
                }
              }}
              width='100%'
              height='100%'
            />
          </div>
          <div className='grid grid-cols-3 items-center mt-[16px]'>
            <Title size='lg'>{videos[active].title}</Title>
            <div className='flex items-center justify-self-center'>
              <button
                className='p-[8px] outline-none focus:outline-none'
                onClick={prev}
              >
                <ChevronLeftIcon
                  className={cn(
                    'w-[20px] h-[20px] text-tailgrids-blue',
                    active + 1 === 1
                      ? 'text-tailgrids-gray cursor-not-allowed'
                      : 'text-tailgrids-blue'
                  )}
                />
              </button>
              <Body>
                {active + 1} of {videos?.length}
              </Body>
              <button
                className='p-[8px] outline-none focus:outline-none'
                onClick={next}
              >
                <ChevronRightIcon
                  className={cn(
                    'w-[20px] h-[20px]',
                    active + 1 === videos?.length
                      ? 'text-tailgrids-gray cursor-not-allowed'
                      : 'text-tailgrids-blue'
                  )}
                />
              </button>
            </div>
            <Badge className='justify-self-end'>
              {videos[active].category}
            </Badge>
          </div>
        </>
      )}
    </>
  );
};

export default MemberVideoPreview;
