/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { FaCheck } from "react-icons/fa";
import moment from "moment";
import { createInvoice } from "redux/actionCreators/practice";


interface RootState {
    practice: any;
    videos: any;
}
const InvoiceModal = (props: any) => {
    const state = useSelector((state: RootState) => state);

    const [loading,setLoading] = useState(false);

    console.log("=== Invoice modal ===");

    console.log(props);

    useEffect(() => {
        const init = async () => {
            // check if video already included in playlist
        };

        init();
    }, [props]);

    return (
        <React.Fragment>
            <>
                <Modal
                    isOpen={props.invoice}
                    onRequestClose={() => props.setInvoice(false)}
                    style={{
                        overlay: {
                            position: "relative",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: "rgba(255, 255, 255, 0.75)",
                            overflow: "hidden",
                        },
                    }}
                >
                    <div className=" edit-team-member z-modal animate-fade-in bg-bg900 fixed left-0 top-0 flex flex-col items-stretch w-full h-screen bg-opacity-90 overflow-y-auto">
                        <div className="flex flex-grow items-center justify-center py-4 w-full">
                            <div className="focus:outline-none popup-invoice">
                                <div className="bg-bg200 rounded-t-4xl flex flex-col px-6 py-4 relative">
                                    <button
                                        onClick={() => props.setInvoice(false)}
                                        className="absolute z-30 right-3 top-3 lt-item-icon-box lt-item-box-sizing lt-item-box-bg  close-popup-practice"
                                        type="button"
                                    >
                                        <span className=" flex items-center gap-4px">
                                            <svg
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                xmlns="http://www.w3.org/2000/svg"
                                                aria-hidden="true"
                                                focusable="false"
                                                role="presentation"
                                                className="index-StyledSvg_cls2__nHIIG index-StyledSvg_cls1__3YcLt"
                                                width="24"
                                                height="24"
                                            >
                                                <defs>
                                                    <symbol id="close" viewBox="0 0 24 24">
                                                        <path d="M19.707 5.707a1 1 0 0 0-1.414-1.414L12 10.586 5.707 4.293a1 1 0 1 0-1.414 1.414L10.586 12l-6.293 6.292a1 1 0 1 0 1.414 1.415L12 13.414l6.293 6.293a1 1 0 0 0 1.414-1.415L13.414 12l6.293-6.293z"></path>
                                                    </symbol>
                                                </defs>
                                                <use xlinkHref="#close" fill="#FFFFFF"></use>
                                            </svg>
                                        </span>
                                    </button>

                                    <h4 className="v-bs-h500 ml-5">Tax invoice</h4>
                                    <h4 className="v-bs-label400 ml-5 text-text800">
                                        INVCD-14909
                                    </h4>
                                </div>

                                <div className="gap-12px flex flex-col items-center bg-white b-border">
                                    <div className="flex flex-col gap-3 content-width-invoice mt-3">
                                        <div className="flex flex-col">
                                            <p className="v-bs-input-label300 text-text500">From</p>
                                            <p className="v-bs-input-value200">
                                                Channel D Pty Ltd 84 Narelle Lane TOWRANG
                                                <br />
                                                NSW 2580 AUSTRALIA
                                            </p>
                                        </div>
                                        <div className="flex flex-col">
                                            <p className="v-bs-input-label300 text-text500">To</p>
                                            <p className="v-bs-input-value200"></p>
                                        </div>
                                        <div className="flex flex-col">
                                            <p className="v-bs-input-label300 text-text500">Issued</p>
                                            <p className="v-bs-input-value200">{moment(
                                                parseInt(
                                                    props?.data?.createdOn
                                                ) * 1000
                                            ).format("MMM, DD YYYY")}</p>
                                        </div>
                                        <div className="flex flex-col">
                                            <p className="v-bs-input-label300 text-text500">Due</p>
                                            <p className="v-bs-input-value200">{moment(
                                                parseInt(
                                                    props?.data?.createdOn
                                                ) * 1000
                                            ).format("MMM, DD YYYY")}</p>
                                        </div>
                                        <div className="flex flex-col">
                                            <p className="v-bs-input-label300 text-text500 font-bold">
                                                Status
                                            </p>
                                            <p className="v-bs-input-value200 font-bold">
                                                <FaCheck className="text-status100 index-StyledSvg_cls2__nHIIG index-StyledSvg_cls1__3YcLt mr-2" />
                                                Succeeded
                                            </p>
                                        </div>
                                    </div>

                                    <div className="border-outline200 flex flex-col gap-2 mx-6 py-3 border-b border-t items-invoice">
                                        <div className="flex items-center justify-between">
                                            <p className="text-text500 v-bs-label300">Amount</p>
                                            <p className="text-text300 v-bs-input-value200">
                                                {props?.data?.amount}
                                            </p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-text500 v-bs-label300">GST</p>
                                            <p className="text-text300 v-bs-input-value200">$0.00</p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <p className="text-text500 v-bs-label300">Total</p>
                                            <p className="text-text900 v-bs-input-value200">
                                                {props?.data?.amount}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="download-invoice">
                                        <button 
                                        disabled={loading}
                                        onClick={ async () => {

                                            setLoading(true);

                                            const invoice = await createInvoice({

                                                id:props.data.transaction_id,
                                                date: moment(parseInt(props?.data?.createdOn) * 1000).format("MMM, DD YYYY"),
                                                amount:parseInt(props.data.amount.replace("$",""))

                                            });

                                            setLoading(false);

                                            if(invoice.data.error === false ) {

                                                const link = document.createElement('a');
                                                link.href = invoice.data.results;
                                                link.target = '_blank';
                                                document.body.appendChild(link);
                                                link.click();
                                                document.body.removeChild(link);
                                            }

                                            console.log(invoice);
                                        }}
                                        
                                        className="text-center"> 
                                        
                                        
                                           { loading ? "Wait..." : "Download PDF"}
                                        
                                        
                                        
                                        </button>
                                    </div>

                                    
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        </React.Fragment>
    );
};

export default InvoiceModal;
