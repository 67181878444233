import { cn } from 'util/cn';

type PropsType = {
  className?: string;
};

const Search = ({ className }: PropsType) => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={cn('text-black', className)}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28.5231 26.637C29.0415 27.1554 29.0433 27.9941 28.5189 28.5185C27.9982 29.0392 27.1567 29.0419 26.6375 28.5226L19.399 21.2841C17.7166 22.5695 15.6142 23.333 13.3335 23.333C7.81065 23.333 3.3335 18.8559 3.3335 13.333C3.3335 7.81016 7.81065 3.33301 13.3335 3.33301C18.8563 3.33301 23.3335 7.81016 23.3335 13.333C23.3335 15.6137 22.57 17.7161 21.2846 19.3985L28.5231 26.637ZM13.3335 20.6663C17.3836 20.6663 20.6668 17.3831 20.6668 13.333C20.6668 9.28292 17.3836 5.99967 13.3335 5.99967C9.28341 5.99967 6.00016 9.28292 6.00016 13.333C6.00016 17.3831 9.28341 20.6663 13.3335 20.6663Z'
        fill='black'
      />
    </svg>
  );
};

export default Search;
