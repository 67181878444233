/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PracticeTheme from "components/theme";
import DongleIcon from "assets/img/dongle.jpg";
import SmartTvIcon from "assets/img/smart-tv.jpg";
import { useDispatch, useSelector } from "react-redux";
import ActionType from "redux/actionTypes";
import {
  getLibraryStats,
  getPlaylist,
  removeFromPlaylist,
} from "redux/actionCreators/videos";
import VideoModal from "components/modals/video";
import Tooltip from "react-simple-tooltip";
import { css } from "styled-components";
import SortableList, { SortableItem, SortableKnob } from "react-easy-sort";
import arrayMove from "array-move";
import { FaBars, FaList } from "react-icons/fa";
import PromptModal from "components/modals/prompt";
import { toast, ToastContainer } from "react-toastify";

interface RootState {
  practice: any;
  videos: any;
}

const PlaylistPage = () => {
  const state = useSelector((state: RootState) => state);

  console.log(state)

  const dispatch = useDispatch();

  const [items, setItems] = useState([]);

  const [knob, setKnob] = useState(false);

  const [videoModal, setVideoModal] = useState(false);

  const [currentModalVideo, setCurrentModalVideo]: any = useState({});

  const [practiceVideoId, setPracticeVideoId] = useState(0);

  const [isRemoveVideo, setIsRemoveVideo] = useState(false);

  const [promptModal, setPromptModal] = useState(false);

  const [isRemoving, setIsRemoving] = useState(false);

  const removeVideoFromPlaylist = async () => {

    console.log("==== remove from playlist ===");
    console.log(currentModalVideo);

    setPromptModal(false);

    setIsRemoving(true);

    await removeFromPlaylist(
      currentModalVideo.video_id,
      currentModalVideo.customization_id
    );

    dispatch(getLibraryStats());

    dispatch(
      getPlaylist({
        type: "NOW_PLAYING",
      })
    );

    dispatch(
      getPlaylist({
        type: "IN_PRODUCTION",
      })
    );

    dispatch(
      getPlaylist({
        type: "NEEDS_CUSTOMIZATION",
      })
    );

    setIsRemoving(false);

    toast.success(
      `Video "${currentModalVideo.title}" was removed from playlist.`
    );
  };

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    setItems((array) => arrayMove(array, oldIndex, newIndex));
  };

  useEffect(() => {
    console.log("===== State Videos =====");
    console.log(state.videos);
  }, [state]);

  useEffect(() => {
    const init = async () => {
      const data = [];

      let count = 1;

      state?.videos?.now_playing.forEach((x: any, i: any) => {
        data.push(i);

        if (count === state.videos.now_playing.length) {
          setItems(data);
        }

        count++;
      });
    };

    init();
  }, []);

  return (
    <>
      <PracticeTheme page="playlist" practice={state?.practice}>
        <div className="flex flex-col gap-3 w-full">
          <div className="flex gap-3 h-full">
            <div className="w-sidebar flex-col flex-shrink-0">
              <h1 className="w-sidebar v-bs-h500 mb-3 mr-3 pl-2">Playlist</h1>
              <div className="flex flex-col gap-2">
                <a href="/playlist/office" className="page-nav-item">
                  <svg
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    focusable="false"
                    role="presentation"
                    className="index-StyledSvg_cls2__nHIIG index-StyledSvg_cls1__3YcLt"
                    width="24"
                    height="24"
                  >
                    <defs>
                      <symbol id="play-circle" viewBox="0 0 24 24">
                        <path
                          fillRule="evenodd"
                          d="M12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18zM1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12z"
                          clipRule="evenodd"
                        ></path>
                        <path
                          fillRule="evenodd"
                          d="M9.528 7.118a1 1 0 0 1 1.027.05l6 4a1 1 0 0 1 0 1.664l-6 4A1 1 0 0 1 9 16V8a1 1 0 0 1 .528-.882zM11 9.868v4.264L14.197 12 11 9.869z"
                          clipRule="evenodd"
                        ></path>
                      </symbol>
                    </defs>
                    <use xlinkHref="#play-circle"></use>
                  </svg>
                  <span className="flex flex-col">
                    <span className="title v-bs-list200 pb-4px">
                      In-office videos
                    </span>
                    <span className="caption v-bs-label200">
                      <span className="caption v-bs-label200">
                        {state?.videos?.available_total} available ,{" "}
                        {state?.videos?.playlist_total} in playlist
                      </span>
                    </span>
                  </span>
                </a>
                <Tooltip
                  arrow={12}
                  background="#FFFFFF"
                  border="white"
                  color="black"
                  content="Coming Soon."
                  fontFamily="inherit"
                  fontSize="14px"
                  padding={10}
                  placement="bottom"
                  radius={20}
                  zIndex={1}
                  customCss={css`
                    width: nowrap;
                  `}
                >
                  <div className="relative flex justify-start">
                    <span>
                      <a
                        href="/library/street"
                        className="page-nav-item page-nav-item-disable"
                      >
                        <svg
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          focusable="false"
                          role="presentation"
                          className="index-StyledSvg_cls2__nHIIG index-StyledSvg_cls1__3YcLt"
                          width="24"
                          height="24"
                          // onClick={() => setIsSearch(true)}
                        >
                          <defs>
                            <symbol id="open-folder" viewBox="0 0 24 24">
                              <path
                                fillRule="evenodd"
                                d="M22.311 10.007c1.275 0 2.022 1.156 1.505 2.32L20.627 19.5c-.383.864-1.375 1.507-2.317 1.506L1.997 21A1.997 1.997 0 0 1 0 19.005V4.995A2 2 0 0 1 1.995 3h6.043c.554 0 1.247.374 1.55.836L11.003 6h6.992A2 2 0 0 1 20 8v2.006l2.311.001zm-18.63 1.5C4.064 10.642 5.056 10 5.998 10L18 10.005V8h-6.996a2 2 0 0 1-1.674-.905L8 5.005 1.995 5 2 15.288l1.68-3.782zM5.997 12c-.152 0-.426.178-.489.319L2.5 19l15.811.007c.152 0 .426-.177.489-.318L21.765 12H5.997z"
                                clipRule="evenodd"
                              ></path>
                            </symbol>
                          </defs>
                          <use xlinkHref="#open-folder" fill="#626262"></use>
                        </svg>
                        <span className="flex flex-col">
                          <span className="title v-bs-list200 pb-4px">
                            Digital signage
                          </span>
                          <span className="caption v-bs-label200"></span>
                        </span>
                      </a>
                    </span>
                  </div>
                </Tooltip>
              </div>
            </div>
            <div className="inline-grid gap-3 grid-cols-12 w-full h-full">
              <div className="rounded-large bg-bg100 flex flex-col col-span-4 overflow-hidden">
                <div className="bg-bg100 rounded-large p-3">
                  <h4 className="v-bs-h400 text-text900 flex gap-1 items-center">
                    <div className="playlist-head-doted playlist-head-doted-color1"></div>
                    Now playing
                  </h4>
                </div>
                <div className="playlist-container-wrap">
                  <div className="scrollbar-thumb-rounded-lg scrollbar scrollbar-border scrollbar-thumb-bg700 scrollbar-thin scrollbar-track-transparent w-full undefined list-3">
                    <div className="playlist-wrapper-body">
                      <div className="pt-4px px-2">
                        <div className="playlist-wrapper-body-content">
                          <div
                            data-index="0"
                            data-known-size="127"
                            data-item-index="0"
                          >
                            <SortableList
                              onSortEnd={onSortEnd}
                              className="list"
                              draggedItemClassName="dragged"
                            >
                              {items.map((item) => (
                                <>
                                  {state.videos.now_playing[item] && (

                                    <>

                                <SortableItem key={item}>
                                  <div onMouseEnter={() => setKnob(true)}>
                                    <div className="pb-2">
                                      <div className="playlist-holder-box">
                                        <SortableKnob>
                                          <div className="absolute left-0 top-0 w-full h-full overflow-hidden">
                                            <button className="index-SortableItemHandle_cls2__1bWKI index-SortableItemHandle_cls1__Aa1li">
                                              <FaBars className="index-StyledSvg_cls2__nHIIG index-StyledSvg_cls1__3YcLt" />
                                            </button>
                                          </div>
                                        </SortableKnob>

                                        <div
                                          role="button"
                                          className="playlist-item-box"
                                          
                                        >
                                          <div className="playlist-item-box-img">
                                            <div className="aspect-w-16 aspect-h-9">
                                              <img
                                                src={
                                                  state?.videos?.now_playing[item]
                                                    .thumbnail_file
                                                }
                                                className="rounded-2xl"
                                                alt={
                                                  state?.videos?.now_playing[item]
                                                    .title
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="relative flex flex-col justify-between w-full overflow-hidden">
                                            <span>
                                              <span className="v-bs-label100 text-text500">
                                                <div className="playlist-video-title-box-inner">
                                                  {
                                                    state.videos.now_playing[
                                                      item
                                                    ].category_name
                                                  }
                                                </div>
                                              </span>
                                              <span className="playlist-video-title-box">
                                                <div className="playlist-video-title-box-inner">
                                                  {
                                                    state.videos.now_playing[
                                                      item
                                                    ].title
                                                  }
                                                </div>
                                              </span>
                                            </span>
                                            <div className="gap-4px flex"></div>
                                          </div>

                                          <span className="playlist-item-content-edit">
                                            <div className="playlist-item-content-btns-wrap">
                                              <button className="play-list-edit-remove-btns"
                                              
                                              onClick={() => {
                                                setPracticeVideoId(
                                                  state.videos.now_playing[item]
                                                    .template_id
                                                );
                                                setVideoModal(true);
                                                setCurrentModalVideo(
                                                  state.videos.now_playing[item]
                                                );
                                              }}
                                              
                                              >
                                                <span className=" flex items-center gap-4px">
                                                  Edit
                                                </span>
                                              </button>
                                              <div className="Separator_cls2__89WVX Separator_cls1__g22QC Separator_noMargin__3zi12 Separator_orientation-vertical__16IVi"></div>
                                              <button
                                                className="play-list-edit-remove-btns"
                                                onClick={() => {
                                                  setIsRemoveVideo(true);
                                                  setCurrentModalVideo(
                                                    state.videos.now_playing[
                                                      item
                                                    ]
                                                  );
                                                  setPromptModal(true);
                                                }}
                                              >
                                                <span className=" flex items-center gap-4px">
                                                  Remove
                                                </span>
                                              </button>
                                            </div>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </SortableItem>

                                      
                                    </>
                                  )}

                                </>
                                
                              ))}
                            </SortableList>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rounded-large bg-bg100 flex flex-col col-span-4 overflow-hidden">
                <div className="bg-bg100 rounded-large p-3">
                  <h4 className="v-bs-h400 text-text900 flex gap-1 items-center">
                    <div className="playlist-head-doted playlist-head-doted-color2"></div>
                    In production
                  </h4>
                </div>
                <div className="playlist-container-wrap">
                  <div className="scrollbar-thumb-rounded-lg scrollbar scrollbar-border scrollbar-thumb-bg700 scrollbar-thin scrollbar-track-transparent w-full undefined list-3">
                    <div className="list-1">
                      <div className="pt-4px px-2">
                        <div className="list-2">
                          <div className="pt-4px px-2">
                            <div className="playlist-wrapper-body-content">
                              <div
                                data-index="0"
                                data-known-size="127"
                                data-item-index="0"
                              >
                                {state?.videos?.in_production.map((v) => {

                                  return (
                                    <>
                                      
                                              <div
                                                className="pb-2"
                                                role="button"
                                                aria-roledescription="sortable"
                                                aria-describedby="DndDescribedBy-0"

                                               >
                                                <div className="playlist-holder-box">
                        
                                                  <div
                                                    role="button"
                                                    className="playlist-item-box"
                                                  >
                                                    <div className="playlist-item-box-img">
                                                      <div className="aspect-w-16 aspect-h-9">
                                                        <img
                                                          src={v.thumbnail_file}
                                                          className="rounded-2xl"
                                                          alt={v.title}
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="relative flex flex-col justify-between w-full overflow-hidden">
                                                      <span>
                                                        <span className="v-bs-label100 text-text500">
                                                          <div className="playlist-video-title-box-inner">
                                                            {v.category_name}
                                                          </div>
                                                        </span>
                                                        <span className="playlist-video-title-box">
                                                          <div className="playlist-video-title-box-inner">
                                                            {v.title}
                                                          </div>
                                                        </span>
                                                      </span>
                                                      <div className="gap-4px flex"></div>
                                                    </div>
                                                    <span className="playlist-item-content-edit">
                                                      <div className="playlist-item-content-btns-wrap">
                                                        <button className="play-list-edit-remove-btns"
                                                        
                                                          onClick={() => {
                                                            setPracticeVideoId(v.template_id);
                                                            setVideoModal(true);
                                                            setCurrentModalVideo(v);
                                                          
                                                          }}
                                                        
                                                        >
                                                          <span className=" flex items-center gap-4px">
                                                            Edit
                                                          </span>
                                                        </button>
                                                        <div className="Separator_cls2__89WVX Separator_cls1__g22QC Separator_noMargin__3zi12 Separator_orientation-vertical__16IVi"></div>
                                                        <button
                                                          className="play-list-edit-remove-btns"
                                                          onClick={() => {
                                                            // alert("== Print == ")
                                                            // console.log(v)

                                                            setIsRemoveVideo(true);
                                                            setCurrentModalVideo(v);
                                                            setPromptModal(true);
                                                          }}
                                                        >
                                                          <span className=" flex items-center gap-4px">
                                                            Remove
                                                          </span>
                                                        </button>
                                                      </div>
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>

                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rounded-large bg-bg100 flex flex-col col-span-4 overflow-hidden">
                <div className="bg-bg100 rounded-large p-3">
                  <h4 className="v-bs-h400 text-text900 flex gap-1 items-center">
                    <div className="playlist-head-doted playlist-head-doted-color3"></div>
                    Needing customization
                  </h4>
                </div>
                <div className="playlist-container-wrap">
                  <div className="scrollbar-thumb-rounded-lg scrollbar scrollbar-border scrollbar-thumb-bg700 scrollbar-thin scrollbar-track-transparent w-full undefined list-3">
                    <div className="list-1">
                      <div className="pt-4px px-2">
                        <div className="list-2">
                          <div className="pt-4px px-2">
                            <div className="playlist-wrapper-body-content">
                              <div
                                data-index="0"
                                data-known-size="127"
                                data-item-index="0"
                              >
                                {state?.videos?.needs_customization.map((v) => {
                                  return (
                                    <>
                                      <div
                                        className="pb-2"
                                        role="button"
                                        aria-roledescription="sortable"
                                        aria-describedby="DndDescribedBy-0"
                                      >
                                        <div className="playlist-holder-box">
                                          
                                          <div
                                            role="button"
                                            className="playlist-item-box"
                                          >
                                            <div className="playlist-item-box-img">
                                              <div className="aspect-w-16 aspect-h-9">
                                                <img
                                                  src={v.thumbnail_file}
                                                  className="rounded-2xl"
                                                  alt={v.title}
                                                />
                                              </div>
                                            </div>
                                            <div className="relative flex flex-col justify-between w-full overflow-hidden">
                                              <span>
                                                <span className="v-bs-label100 text-text500">
                                                  <div className="playlist-video-title-box-inner">
                                                    {v.category_name}
                                                  </div>
                                                </span>
                                                <span className="playlist-video-title-box">
                                                  <div className="playlist-video-title-box-inner">
                                                    {v.title}
                                                  </div>
                                                </span>
                                              </span>
                                              <div className="gap-4px flex"></div>
                                            </div>
                                            <span className="playlist-item-content-edit">
                                              <div className="playlist-item-content-btns-wrap">
                                                <button className="play-list-edit-remove-btns"
                                                onClick={() => {
                                                  setPracticeVideoId(v.video_id);
                                                  setVideoModal(true);
                                                  setCurrentModalVideo(v);
                                                }}
                                                
                                                >
                                                  <span className=" flex items-center gap-4px">
                                                    Edit
                                                  </span>
                                                </button>
                                                <div className="Separator_cls2__89WVX Separator_cls1__g22QC Separator_noMargin__3zi12 Separator_orientation-vertical__16IVi"></div>
                                                <button
                                                  className="play-list-edit-remove-btns"
                                                  onClick={() => {
                                                    setIsRemoveVideo(true);
                                                    setCurrentModalVideo(v);
                                                    setPromptModal(true);
                                                  }}
                                                >
                                                  <span className=" flex items-center gap-4px">
                                                    Remove
                                                  </span>
                                                </button>
                                              </div>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <VideoModal
          video_id={practiceVideoId}
          videoModal={videoModal}
          setVideoModal={setVideoModal}
          video={currentModalVideo}
          members={state.practice.team_members}
          edit={true}
        />
      </PracticeTheme>

      {isRemoveVideo && (
        <PromptModal
          promptModal={promptModal}
          setPromptModal={setPromptModal}
          btnText="Remove"
          title={`Remove "${currentModalVideo.title}" from playlist?`}
          description=""
          onSuccess={removeVideoFromPlaylist}
        />
      )}

<ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
          theme="dark"
      />
    </>
  );
};

export default PlaylistPage;
