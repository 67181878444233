import { Body, ModalType, useModalActions } from 'components';
import { PlayCircle } from 'icons';
import { MemberVideoPreview, VideoType } from 'features/team-members';

type PropsType = {
  videos: VideoType[];
};

const MemberVideo = ({ videos }: PropsType) => {
  const { trigger } = useModalActions();
  const previewModal: ModalType = {
    type: 'preview',
    component: <MemberVideoPreview videos={videos} />,
  };

  if (videos?.length > 0)
    return (
      <ul className='mt-[32px] space-y-[24px]'>
        {videos?.map(({ thumbnail, title, category }, index) => (
          <li
            key={index}
            className='bg-white rounded-[10px] px-[18px] py-[24px] shadow-video flex items-center gap-[20px]'
          >
            <img
              src={thumbnail}
              alt={title}
              className='h-[103px] w-[189px] rounded-[10px] object-cover'
            />
            <div className='flex-grow self-start text-start space-y-[7px]'>
              <Body size='lg' className='text-tailgrids-black'>
                {category}
              </Body>
              <Body size='lg' className='text-black'>
                {title}
              </Body>
            </div>
            <button
              className='p-[4px] pr-[0px] focus:outline-none'
              onClick={() => {
                previewModal.component = (
                  <MemberVideoPreview videos={videos} index={index} />
                );
                trigger(previewModal);
              }}
            >
              <PlayCircle />
            </button>
          </li>
        ))}
      </ul>
    );
};

export default MemberVideo;
