import { create } from 'zustand';

export type VideoType = {
  title: string;
  category: string;
  thumbnail: string;
  video: string;
  sample: string;
};

export type MemberType = {
  id: number;
  src: string;
  name: string;
  shortname: string;
  role: string;
  videos?: VideoType[];
  videosFetched?: boolean;
};

type Action = {
  set: (members: MemberType[]) => void;
  get: (id: number) => MemberType;
};

type MemberStore = {
  members: MemberType[];
  actions: Action;
};

const useMemberStore = create<MemberStore>((set, get) => ({
  members: [],
  actions: {
    set: (members: MemberType[]) => set(() => ({ members: [...members] })),
    get: (id: number) => get().members.find((m) => id === m.id),
  },
}));

export const useMembers = () => useMemberStore((state) => state.members);

export const useMembersAction = () => useMemberStore((state) => state.actions);
