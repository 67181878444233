import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Body, Title } from 'components';
import { AnnouncementItem, AnnouncementSkeleton } from 'features/announcements';
import '../../assets/css/dashboard.scss';

const Announcements = (props) => {
  const [announcements, setAnnouncement] = useState(props?.announcements);

  useEffect(() => {
    setAnnouncement(props?.announcements);
  }, [props]);

  return (
    <div className='PaymentAnnouncements'>
      <div className='w-full flex justify-between'>
        <Title size='lg'>Announcements</Title>
        <Link
          to='/announcements'
          className='font-karla text-base font-medium leading-[20px] text-tailgrids-black'
        >
          View All
        </Link>
      </div>

      <div className='mt-[20px] space-y-[20px]'>
        {props?.loadingAnnouncements ? (
          Array.from({ length: 4 }, (_, index) => (
            <AnnouncementSkeleton key={index} />
          ))
        ) : announcements.length > 0 ? (
          announcements?.map(({ title, description, image }, index) => (
            <AnnouncementItem
              key={index}
              title={title}
              description={description}
              src={image}
            />
          ))
        ) : (
          <Body>No Announcements found.</Body>
        )}
      </div>
    </div>
  );
};

export default Announcements;
