import { ReactNode } from 'react';
import { cn } from 'util/cn';

type PropsType = {
  children?: ReactNode;
  className?: string;
  htmlFor?: string;
};

const Label = ({ children, className, htmlFor }: PropsType) => {
  return (
    <label
      htmlFor={htmlFor}
      className={cn(
        'font-karla font-bold text-[16px] leading-[18.7px] text-tailgrids-black',
        className
      )}
    >
      {children}
    </label>
  );
};

export default Label;
