/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import DefaultThumbnail from 'assets/img/default-thumbnail.jpg';
import Modal from 'react-modal';
import Select from 'react-select';
import _ from 'lodash';
import ReactPlayer from 'react-player';
import {
  getVideoDescriptions,
  getVideoQuestions,
  getVideoCustomizationQuestions,
  getVideoCustomizationMembers,
  removeFromPlaylist,
  getPlaylist,
  getLibraryStats,
  getLibraryVideosAvailable,
  updateVideo,
} from 'redux/actionCreators/videos';
import { useDispatch, useSelector } from 'react-redux';
import { FileUploader } from 'react-drag-drop-files';
import { addToPlaylist } from 'redux/actionCreators/videos';
import LoadingOverlay from 'react-loading-overlay';
import PromptModal from './prompt';
import { toast } from 'react-toastify';
import { exists } from 'fs';

const fileTypes = ['JPG', 'PNG'];

interface RootState {
  practice: any;
  videos: any;
}
const VideoModal = (props: any) => {
  const state = useSelector((state: RootState) => state);

  const [file, setFile] = useState(null);

  const [submitting, setSubmitting] = useState(false);

  const [isRemoveVideo, setIsRemoveVideo] = useState(false);

  const [promptModal, setPromptModal] = useState(false);

  const dispatch = useDispatch();

  const [isRemoving, setIsRemoving] = useState(false);

  const [isUpdate, setIsUpdate] = useState(false);

  const [previewUrl, setPreviewUrl] = useState('');

  const [imageData, setImageData] = useState([]);

  const [updating, setUpdating] = useState(false);

  const [teamMemberError, setTeamMemberError] = useState(null);

  const removeVideoFromPlaylist = async () => {
    setPromptModal(false);

    setIsRemoving(true);

    const is_now_playing = state?.videos?.now_playing.find(
      (e) => e.video_id === props.video.video_id
    );

    const needs_customization = state?.videos?.needs_customization.find(
      (e) => e.video_id === props.video.video_id
    );

    const in_production = state?.videos?.in_production.find(
      (e) => e.video_id === props.video.video_id
    );

    if (is_now_playing) {
      await removeFromPlaylist(
        is_now_playing.practice_video_id,
        is_now_playing.customization_id
      );
    } else if (needs_customization) {
      await removeFromPlaylist(
        needs_customization.video_id,
        needs_customization.customization_id
      );
    } else if (in_production) {
      await removeFromPlaylist(
        in_production.video_id,
        in_production.customization_id
      );
    }

    dispatch(getLibraryStats());

    dispatch(
      getPlaylist({
        type: 'NOW_PLAYING',
      })
    );

    dispatch(
      getPlaylist({
        type: 'IN_PRODUCTION',
      })
    );

    dispatch(
      getPlaylist({
        type: 'NEEDS_CUSTOMIZATION',
      })
    );

    setIsRemoving(false);

    props.setVideoModal(false);

    toast.success(`Video "${props.video.title}" was removed from playlist.`);
  };

  const getPreviewUrl = (question: any) => {
    return (
      imageData.filter((x) => x.ID === question.ID)[0]?.preview_image ||
      question.file_path
    );
  };

  const handleChange = (file, question) => {
    setFile(file);

    question.type = 'photo';
    question.image_name = file.name;
    question.image_type = file.type;

    const reader = new FileReader();

    reader.readAsDataURL(file);

    setPreviewUrl(URL.createObjectURL(file));

    const image_data_exists = imageData.find((m) => m.ID === question.ID);

    if (!image_data_exists) {
      question.preview_image = URL.createObjectURL(file);

      setImageData([...imageData, question]);
    } else {
      question.preview_image = URL.createObjectURL(file);

      Object.assign(image_data_exists, question);

      setQuestionsData(image_data_exists);
    }

    reader.onload = () => {
      const exists = questionsData.find((m) => m.ID === question.ID);

      if (!exists) {
        question.image = reader.result;

        setQuestionsData([...questionsData, question]);
      } else {
        question.image = reader.result;

        Object.assign(exists, question);

        setQuestionsData(questionsData);
      }
    };
  };

  const handleUpdate = async () => {
    setUpdating(true);

    const payload = {
      questions: questionsData,
      members: teamMembersData,
      video: props.video,
      fromVideoModal: true,
      original_questions: questions,
    };

    await updateVideo(payload);

    setUpdating(false);

    dispatch(getLibraryStats());

    dispatch(
      getPlaylist({
        type: 'NOW_PLAYING',
      })
    );

    dispatch(
      getPlaylist({
        type: 'IN_PRODUCTION',
      })
    );

    dispatch(
      getPlaylist({
        type: 'NEEDS_CUSTOMIZATION',
      })
    );

    dispatch(getLibraryVideosAvailable({}));

    props.setVideoModal(false);

    setPreviewUrl('');

    toast.success(`Video "${props.video.title}" was updated.`);
  };

  const handleSubmit = async () => {
    if (
      props.video.total_descriptions > 0 ||
      props.video.no_of_required_team_members > 0
    ) {
      if (teamMembersData.length === 0) {
        setTeamMemberError(true);
        return;
      }
    }

    setSubmitting(true);
    const res = await addToPlaylist({
      questions: questionsData,
      members: teamMembersData,
      video: props.video,
      fromVideoModal: true,
      original_questions: questions,
    });

    setSubmitting(false);

    dispatch(getLibraryStats());

    dispatch(
      getPlaylist({
        type: 'NOW_PLAYING',
      })
    );

    dispatch(
      getPlaylist({
        type: 'IN_PRODUCTION',
      })
    );

    dispatch(
      getPlaylist({
        type: 'NEEDS_CUSTOMIZATION',
      })
    );

    dispatch(getLibraryVideosAvailable({}));

    props.setVideoModal(false);

    setPreviewUrl('');

    toast.success(`Video "${props.video.title}" was added to playlist.`);
  };

  const [members, setMembers] = useState([]);

  const [loading, setLoading] = useState(false);

  const [selectedMember, setSelectedMember] = useState([]);

  const [questions, setQuestions] = useState([]);

  const [questionsData, setQuestionsData] = useState([]);

  const [teamMembersData, setTeamMembersData]: any = useState([]);

  const [customizationQuestions, setCustomizationQuestions] = useState([]);

  const [customizationMembers, setCustomizationMembers] = useState([]);

  const [descriptions, setDescriptions] = useState([]);

  const Styles = {
    option: (provided) => ({
      ...provided,
      cursor: 'pointer',
    }),
    control: (provided) => ({
      ...provided,
      border: '2px solid black',
      borderRadius: '8px',
      padding: '8px',
    }),

    menu: (provided, state) => ({
      ...provided,
      border: '2px solid black',
    }),

    valueContainer: (provided, state) => ({
      ...provided,
    }),

    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '30px',
    }),

    dropdownIndicator: (base) => ({
      ...base,
      color: 'black',
      marginTop: 'auto',
    }),
  };

  useEffect(() => {
    if (props.members && props.videoModal) {
      let data = [];

      props.members.forEach((member) => {
        const item: any = {};

        item.label = member.MemberName;
        item.value = member?.team_member_id;
        item.image = member.photo_thumbnail;
        item.profile_picture_id = member.profile_picture_id;

        data.push(item);
      });

      setMembers(_.uniq(data));
    }

    const init = async () => {
      setSelectedMember([]);

      setLoading(true);

      if (props.video.video_id) {
        const questions = await getVideoQuestions(props.video.video_id);

        setQuestions(questions.data.results);

        const descriptions = await getVideoDescriptions(props.video.video_id);

        setDescriptions(descriptions.data.results);

        if (props.video.customization_id) {
          const cquestions = await getVideoCustomizationQuestions(
            props.video.customization_id
          );

          const cmembers = await getVideoCustomizationMembers(
            props.video.customization_id
          );

          setCustomizationQuestions(cquestions.data.results);
          setCustomizationMembers(cmembers.data.results);

          // setMembers([]);
          // setQuestionsData([]);
        }

        setTimeout(() => {
          setLoading(false);
        }, 4000);
      }
    };

    init();
  }, [props]);

  const [filteredMembers, setFilteredMembers] = useState([]);
  useEffect(() => {
    const filtered = members.filter(
      (member) => !teamMembersData.find((team) => team.value === member.value)
    );
    setFilteredMembers(filtered);
  }, [teamMembersData, members]);

  useEffect(() => {
    const teamsData = members.filter((member) =>
      customizationMembers.find((m) => m.team_member_id === member.value)
    );
    setTeamMembersData(teamsData);
  }, [members, customizationMembers]);

  return (
    <React.Fragment>
      <>
        <Modal
          isOpen={props.videoModal}
          onRequestClose={() => {
            setQuestions([]);
            setDescriptions([]);
            setCustomizationQuestions([]);
            setCustomizationMembers([]);

            props.setVideoModal(false);
          }}
          style={{
            overlay: {
              position: 'relative',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(255, 255, 255, 0.75)',
              overflow: 'hidden',
            },
          }}
        >
          <LoadingOverlay active={false}>
            {props.video.no_of_required_team_members > 0 ||
            props.video.total_questions > 0 ||
            props.video.total_descriptions > 0 ? (
              <>
                <div className='z-modal animate-fade-in bg-bg900 fixed left-0 top-0 flex flex-col items-stretch w-full h-screen bg-opacity-90 overflow-y-auto'>
                  <div className='flex flex-grow items-center justify-center py-4 w-full'>
                    <div
                      role='dialog'
                      tabIndex={-1}
                      data-ismodal='true'
                      className='focus:outline-none'
                      style={{ width: '80%' }}
                    >
                      <div className='bg-bg100 rounded-4xl relative py-0 px-0'>
                        <button
                          className='absolute z-30 right-3 top-3 lt-item-icon-box lt-item-box-sizing lt-item-box-bg  close-popup-practice cursor-pointer'
                          type='button'
                          onClick={() => {
                            setQuestions([]);
                            setDescriptions([]);
                            setCustomizationQuestions([]);
                            setCustomizationMembers([]);
                            props.setVideoModal(false);
                          }}
                        >
                          <span className=' flex items-center gap-4px'>
                            <svg
                              xmlnsXlink='http://www.w3.org/1999/xlink'
                              xmlns='http://www.w3.org/2000/svg'
                              aria-hidden='true'
                              focusable='false'
                              role='presentation'
                              className='index-StyledSvg_cls2__nHIIG index-StyledSvg_cls1__3YcLt'
                              width='24'
                              height='24'
                            >
                              <defs>
                                <symbol id='close' viewBox='0 0 24 24'>
                                  <path d='M19.707 5.707a1 1 0 0 0-1.414-1.414L12 10.586 5.707 4.293a1 1 0 1 0-1.414 1.414L10.586 12l-6.293 6.292a1 1 0 1 0 1.414 1.415L12 13.414l6.293 6.293a1 1 0 0 0 1.414-1.415L13.414 12l6.293-6.293z'></path>
                                </symbol>
                              </defs>
                              <use xlinkHref='#close' fill='#FFFFFF'></use>
                            </svg>
                          </span>
                        </button>
                        <div className='grid gap-3 grid-cols-12 p-3'>
                          <div className='col-span-7'>
                            <ReactPlayer
                              config={{
                                file: {
                                  attributes: { controlsList: 'nodownload' },
                                },
                              }}
                              className='videoFrame'
                              url={
                                props.video.video_file
                                  ? props.video.video_file
                                  : props.video.sample_file
                              }
                              light={props?.video?.thumbnail_file}
                              playing
                              controls
                            />
                            <p className='v-bs-label500 text-text500 mt-2'>
                              {props?.video?.category_name}
                            </p>
                            <h6 className='v-bs-h600 text-text900'>
                              {props?.video?.title}
                            </h6>
                            <p className='v-bs-p200 text-text500'>
                              {props?.video?.description}
                            </p>
                          </div>

                          <div className='col-span-5 p-7'>
                            <h6 className='v-bs-h600 text-text900'>
                              Video customization
                            </h6>

                            {loading ? (
                              <>
                                <svg
                                  className='w-full mt-4 h-3 mr-3 -ml-1 text-black animate-spin text-center'
                                  xmlns='http://www.w3.org/2000/svg'
                                  fill='none'
                                  viewBox='0 0 24 24'
                                >
                                  <circle
                                    className='opacity-25'
                                    cx='12'
                                    cy='12'
                                    r='10'
                                    stroke='currentColor'
                                    strokeWidth='4'
                                  ></circle>
                                  <path
                                    className='opacity-75'
                                    fill='currentColor'
                                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                                  ></path>
                                </svg>
                              </>
                            ) : (
                              <>
                                {teamMemberError && (
                                  <>
                                    <div
                                      className='flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400'
                                      role='alert'
                                    >
                                      <svg
                                        className='flex-shrink-0 inline w-3 h-3 mr-3'
                                        aria-hidden='true'
                                        xmlns='http://www.w3.org/2000/svg'
                                        fill='currentColor'
                                        viewBox='0 0 20 20'
                                      >
                                        <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z' />
                                      </svg>
                                      <span className='sr-only'>Info</span>
                                      <div>
                                        This template requires team member(s).
                                      </div>
                                    </div>
                                  </>
                                )}

                                <p className='text-text500 v-bs-p200 mb-4'>
                                  Fill in the form below to customize your
                                  video.
                                </p>

                                <div className='flex flex-col gap-2 form'>
                                  {props.edit &&
                                  customizationMembers.length &&
                                  members.length ? (
                                    <>
                                      {[
                                        ...Array(
                                          props.video
                                            .no_of_required_team_members > 0
                                            ? props.video
                                                .no_of_required_team_members
                                            : props.video.total_descriptions
                                        ),
                                      ].map((x, i) => {
                                        return (
                                          <div key={i}>
                                            <label>
                                              {' '}
                                              {descriptions.length > 0
                                                ? descriptions[i]?.Description
                                                : 'Choose team member'}{' '}
                                            </label>

                                            <Select
                                              menuPlacement='auto'
                                              menuPosition='fixed'
                                              defaultValue={members.filter(
                                                (m) =>
                                                  parseInt(m.value) ===
                                                  customizationMembers[i]
                                                    ?.team_member_id
                                              )}
                                              isSearchable={false}
                                              placeholder='Choose team member'
                                              options={filteredMembers}
                                              styles={Styles}
                                              onChange={(value) => {
                                                value.index = i;

                                                setSelectedMember(value);

                                                const objectIndex =
                                                  teamMembersData.findIndex(
                                                    (obj) => obj.index === i
                                                  );

                                                if (objectIndex >= 0) {
                                                  teamMembersData[
                                                    objectIndex
                                                  ].value = value.value;
                                                  teamMembersData[
                                                    objectIndex
                                                  ].label = value.label;
                                                  teamMembersData[
                                                    objectIndex
                                                  ].image = value.image;
                                                  teamMembersData[
                                                    objectIndex
                                                  ].profile_picture_id =
                                                    value.profile_picture_id;
                                                } else {
                                                  setTeamMembersData([
                                                    ...teamMembersData,
                                                    value,
                                                  ]);
                                                }
                                              }}
                                              components={{
                                                IndicatorSeparator: () => null,
                                              }}
                                              formatOptionLabel={(
                                                member: any
                                              ) => {
                                                return (
                                                  <>
                                                    <div className='team-member'>
                                                      <div className='flex flex-wrap'>
                                                        <div className='px-3'>
                                                          <img
                                                            src={
                                                              member.image
                                                                ? member.image
                                                                : DefaultThumbnail
                                                            }
                                                            alt='team-member'
                                                            className='shadow-lg rounded-full max-w-full h-auto align-middle border-none object-cover'
                                                          />
                                                        </div>

                                                        <span>
                                                          {member.label}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </>
                                                );
                                              }}
                                            />
                                          </div>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    <>
                                      {/** Templates Team members */}
                                      {[
                                        ...Array(
                                          props.video
                                            .no_of_required_team_members > 0
                                            ? props.video
                                                .no_of_required_team_members
                                            : props.video.total_descriptions
                                        ),
                                      ].map((x, i) => (
                                        <div key={i}>
                                          <label>
                                            {' '}
                                            {descriptions.length > 0
                                              ? descriptions[i]?.Description
                                              : 'Choose team member'}{' '}
                                          </label>

                                          <Select
                                            menuPlacement='auto'
                                            menuPosition='fixed'
                                            defaultValue={selectedMember}
                                            isSearchable={false}
                                            placeholder='Choose team member'
                                            options={filteredMembers}
                                            styles={Styles}
                                            onChange={(value) => {
                                              setSelectedMember(value);

                                              value.index = i;

                                              setSelectedMember(value);

                                              const objectIndex =
                                                teamMembersData.findIndex(
                                                  (obj) => obj.index === i
                                                );

                                              if (objectIndex >= 0) {
                                                teamMembersData[
                                                  objectIndex
                                                ].value = value.value;
                                                teamMembersData[
                                                  objectIndex
                                                ].label = value.label;
                                                teamMembersData[
                                                  objectIndex
                                                ].image = value.image;
                                                teamMembersData[
                                                  objectIndex
                                                ].profile_picture_id =
                                                  value.profile_picture_id;
                                              } else {
                                                setTeamMembersData([
                                                  ...teamMembersData,
                                                  value,
                                                ]);
                                              }
                                            }}
                                            components={{
                                              IndicatorSeparator: () => null,
                                            }}
                                            formatOptionLabel={(
                                              member: any
                                            ) => {
                                              return (
                                                <>
                                                  <div className='team-member'>
                                                    <div className='flex flex-wrap'>
                                                      <div className='px-3'>
                                                        <img
                                                          src={
                                                            member.image
                                                              ? member.image
                                                              : DefaultThumbnail
                                                          }
                                                          alt='team-member'
                                                          className='shadow-lg rounded-full max-w-full h-auto align-middle border-none'
                                                        />
                                                      </div>

                                                      <span>
                                                        {member.label}
                                                      </span>
                                                    </div>
                                                  </div>
                                                </>
                                              );
                                            }}
                                          />
                                        </div>
                                      ))}
                                    </>
                                  )}

                                  <div className='grid gap-2 form'>
                                    {props.edit &&
                                    customizationQuestions.length ? (
                                      <>
                                        {customizationQuestions &&
                                          customizationQuestions.map(
                                            (question: any, index) => {
                                              return (
                                                <div key={index}>
                                                  {question.QuestionTypeID ===
                                                  1 ? (
                                                    <>
                                                      <label>
                                                        {question.Question}
                                                      </label>

                                                      <input
                                                        type='text'
                                                        defaultValue={
                                                          question.answer
                                                        }
                                                        className='form-control question'
                                                        onChange={(e: any) => {
                                                          question.type =
                                                            'text';
                                                          question.answer =
                                                            e.target.value;

                                                          const exists =
                                                            questionsData.find(
                                                              (m) =>
                                                                m.ID ===
                                                                question.ID
                                                            );

                                                          if (!exists) {
                                                            setQuestionsData([
                                                              ...questionsData,
                                                              question,
                                                            ]);
                                                          } else {
                                                            Object.assign(
                                                              exists,
                                                              question
                                                            );

                                                            setQuestionsData(
                                                              questionsData
                                                            );
                                                          }
                                                        }}
                                                      />
                                                    </>
                                                  ) : (
                                                    <>
                                                      <label>
                                                        {question.Question}
                                                      </label>

                                                      <div className='photo-input'>
                                                        <FileUploader
                                                          name='file'
                                                          handleChange={(
                                                            file
                                                          ) => {
                                                            handleChange(
                                                              file,
                                                              question
                                                            );
                                                          }}
                                                          types={fileTypes}
                                                          className='practive-box-holder practive-box-holder-size'
                                                        >
                                                          <div className='flex flex-col items-center'>
                                                            {props.edit &&
                                                            question.file_path ? (
                                                              <>
                                                                {previewUrl ? (
                                                                  <img
                                                                    className='rounded-xl  cursor-pointer'
                                                                    src={getPreviewUrl(
                                                                      question
                                                                    )}
                                                                    alt=''
                                                                    width={128}
                                                                    height={128}
                                                                  />
                                                                ) : (
                                                                  <img
                                                                    className='rounded-xl cursor-pointer'
                                                                    src={
                                                                      question.file_path
                                                                    }
                                                                    alt=''
                                                                    width={128}
                                                                    height={128}
                                                                  />
                                                                )}
                                                              </>
                                                            ) : (
                                                              <>
                                                                <svg
                                                                  xmlnsXlink='http://www.w3.org/1999/xlink'
                                                                  xmlns='http://www.w3.org/2000/svg'
                                                                  aria-hidden='true'
                                                                  focusable='false'
                                                                  role='presentation'
                                                                  className='index-StyledSvg_cls2__nHIIG index-StyledSvg_cls1__3YcLt cursor-pointer'
                                                                  width='24'
                                                                  height='24'
                                                                >
                                                                  <defs>
                                                                    <symbol
                                                                      id='upload'
                                                                      viewBox='0 0 24 24'
                                                                    >
                                                                      <path
                                                                        fillRule='evenodd'
                                                                        d='M23 11.5a4.5 4.5 0 0 1-4.495 4.5H16a1 1 0 1 1 0-2h2.505a2.5 2.5 0 0 0 0-5H17.07l-.14-.835a5.001 5.001 0 0 0-9.862 0L6.93 9H5.495a2.5 2.5 0 0 0 0 5H8a1 1 0 1 1 0 2H5.495a4.5 4.5 0 0 1-.188-8.996C5.763 4.672 8.568 2 12 2a7.004 7.004 0 0 1 6.72 5.033c2.205 0 4.28 1.985 4.28 4.467zM8.468 12.542c.39.39 1.026.388 1.418-.003l1.118-1.118v9.57a1 1 0 1 0 2 0v-9.57l1.118 1.118a1 1 0 1 0 1.414-1.414l-2.822-2.823a.997.997 0 0 0-.71-.294.987.987 0 0 0-.707.291L8.47 11.125a1 1 0 0 0-.003 1.417z'
                                                                        clipRule='evenodd'
                                                                      ></path>
                                                                    </symbol>
                                                                  </defs>
                                                                  <use xlinkHref='#upload'></use>
                                                                </svg>
                                                                <p className='v-bs-btn100 text-text900 mt-2 cursor-pointer'>
                                                                  {file
                                                                    ? ''
                                                                    : 'Drag and drop here or choose photo'}
                                                                </p>
                                                              </>
                                                            )}
                                                          </div>
                                                        </FileUploader>
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              );
                                            }
                                          )}
                                      </>
                                    ) : (
                                      <>
                                        {/** Templates With Questions  */}

                                        {questions &&
                                          questions.map((question, index) => {
                                            return (
                                              <div key={index}>
                                                {question.QuestionTypeID ===
                                                1 ? (
                                                  <>
                                                    <label>
                                                      {question.Question}
                                                    </label>

                                                    <input
                                                      type='text'
                                                      className='form-control question'
                                                      onChange={(e: any) => {
                                                        question.type = 'text';
                                                        question.answer =
                                                          e.target.value;

                                                        const exists =
                                                          questionsData.find(
                                                            (m) =>
                                                              m.ID ===
                                                              question.ID
                                                          );

                                                        if (!exists) {
                                                          setQuestionsData([
                                                            ...questionsData,
                                                            question,
                                                          ]);
                                                        } else {
                                                          Object.assign(
                                                            exists,
                                                            question
                                                          );

                                                          setQuestionsData(
                                                            questionsData
                                                          );
                                                        }
                                                      }}
                                                    />
                                                  </>
                                                ) : (
                                                  <>
                                                    <label>
                                                      {question.Question}
                                                    </label>

                                                    <div className='photo-input'>
                                                      <FileUploader
                                                        name='file'
                                                        handleChange={(file) =>
                                                          handleChange(
                                                            file,
                                                            question
                                                          )
                                                        }
                                                        types={fileTypes}
                                                        className='practive-box-holder practive-box-holder-size'
                                                      >
                                                        <div className='flex flex-col items-center'>
                                                          {props.edit ? (
                                                            <>
                                                              <img
                                                                className='rounded-xl'
                                                                src={
                                                                  props?.member
                                                                    ?.photo_thumbnail
                                                                }
                                                                alt=''
                                                                width={128}
                                                                height={128}
                                                              />
                                                            </>
                                                          ) : (
                                                            <>
                                                              <svg
                                                                xmlnsXlink='http://www.w3.org/1999/xlink'
                                                                xmlns='http://www.w3.org/2000/svg'
                                                                aria-hidden='true'
                                                                focusable='false'
                                                                role='presentation'
                                                                className='index-StyledSvg_cls2__nHIIG index-StyledSvg_cls1__3YcLt cursor-pointer'
                                                                width='24'
                                                                height='24'
                                                              >
                                                                <defs>
                                                                  <symbol
                                                                    id='upload'
                                                                    viewBox='0 0 24 24'
                                                                  >
                                                                    <path
                                                                      fillRule='evenodd'
                                                                      d='M23 11.5a4.5 4.5 0 0 1-4.495 4.5H16a1 1 0 1 1 0-2h2.505a2.5 2.5 0 0 0 0-5H17.07l-.14-.835a5.001 5.001 0 0 0-9.862 0L6.93 9H5.495a2.5 2.5 0 0 0 0 5H8a1 1 0 1 1 0 2H5.495a4.5 4.5 0 0 1-.188-8.996C5.763 4.672 8.568 2 12 2a7.004 7.004 0 0 1 6.72 5.033c2.205 0 4.28 1.985 4.28 4.467zM8.468 12.542c.39.39 1.026.388 1.418-.003l1.118-1.118v9.57a1 1 0 1 0 2 0v-9.57l1.118 1.118a1 1 0 1 0 1.414-1.414l-2.822-2.823a.997.997 0 0 0-.71-.294.987.987 0 0 0-.707.291L8.47 11.125a1 1 0 0 0-.003 1.417z'
                                                                      clipRule='evenodd'
                                                                    ></path>
                                                                  </symbol>
                                                                </defs>
                                                                <use xlinkHref='#upload'></use>
                                                              </svg>
                                                              <p className='v-bs-btn100 text-text900 mt-2 cursor-pointer'>
                                                                {questionsData.length >
                                                                0
                                                                  ? questionsData
                                                                      .filter(
                                                                        (m) =>
                                                                          m.ID ===
                                                                          question.ID
                                                                      )
                                                                      .shift()
                                                                      ?.image_name ||
                                                                    'Drag and drop here or choose photo'
                                                                  : 'Drag and drop here or choose photo'}
                                                              </p>
                                                            </>
                                                          )}
                                                        </div>
                                                      </FileUploader>
                                                    </div>
                                                  </>
                                                )}
                                              </div>
                                            );
                                          })}
                                      </>
                                    )}
                                  </div>

                                  {props.edit ? (
                                    <>
                                      <button
                                        disabled={updating}
                                        onClick={() => {
                                          setIsUpdate(true);
                                          handleUpdate();
                                        }}
                                        className='lt-item-icon-box  box-shadow-focus btn-default-layout btn-layout-style btn-layout-border'
                                      >
                                        <span className=' flex items-center gap-4px'>
                                          {updating ? (
                                            <>
                                              <svg
                                                className=' text-center w-full  h-3 mr-3 -ml-1 text-white animate-spin'
                                                xmlns='http://www.w3.org/2000/svg'
                                                fill='none'
                                                viewBox='0 0 24 24'
                                              >
                                                <circle
                                                  className='opacity-25'
                                                  cx='12'
                                                  cy='12'
                                                  r='10'
                                                  stroke='currentColor'
                                                  strokeWidth='4'
                                                ></circle>
                                                <path
                                                  className='opacity-75'
                                                  fill='currentColor'
                                                  d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                                                ></path>
                                              </svg>
                                            </>
                                          ) : (
                                            <>Update customization</>
                                          )}
                                        </span>
                                      </button>

                                      <button
                                        onClick={() => {
                                          setIsRemoveVideo(true);
                                          setPromptModal(true);
                                        }}
                                        className='mt-12px w-full lt-item-icon-box  box-shadow-focus btn-default-layout btn-layout-border edit-practice-btn-only-border'
                                      >
                                        <span className=' flex items-center gap-4px'>
                                          {isRemoving ? (
                                            <>
                                              <svg
                                                className=' text-center w-full  h-3 mr-3 -ml-1 text-black animate-spin text-center'
                                                xmlns='http://www.w3.org/2000/svg'
                                                fill='none'
                                                viewBox='0 0 24 24'
                                              >
                                                <circle
                                                  className='opacity-25'
                                                  cx='12'
                                                  cy='12'
                                                  r='10'
                                                  stroke='currentColor'
                                                  strokeWidth='4'
                                                ></circle>
                                                <path
                                                  className='opacity-75'
                                                  fill='currentColor'
                                                  d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                                                ></path>
                                              </svg>
                                            </>
                                          ) : (
                                            <>Remove from playlist</>
                                          )}
                                        </span>
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        disabled={
                                          props.members.length === 0 &&
                                          (props.video
                                            .no_of_required_team_members
                                            .length > 0 ||
                                            props.video.total_descriptions > 0)
                                            ? true
                                            : false
                                        }
                                        onClick={handleSubmit}
                                        className='lt-item-icon-box  box-shadow-focus btn-default-layout btn-layout-style btn-layout-border'
                                      >
                                        <span className=' flex items-center gap-4px'>
                                          {submitting ? (
                                            <>
                                              <svg
                                                className=' text-center w-full  h-3 mr-3 -ml-1 text-white animate-spin'
                                                xmlns='http://www.w3.org/2000/svg'
                                                fill='none'
                                                viewBox='0 0 24 24'
                                              >
                                                <circle
                                                  className='opacity-25'
                                                  cx='12'
                                                  cy='12'
                                                  r='10'
                                                  stroke='currentColor'
                                                  strokeWidth='4'
                                                ></circle>
                                                <path
                                                  className='opacity-75'
                                                  fill='currentColor'
                                                  d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                                                ></path>
                                              </svg>
                                            </>
                                          ) : (
                                            <>Add to playlist</>
                                          )}
                                        </span>
                                      </button>
                                    </>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className='z-modal animate-fade-in bg-bg900 fixed left-0 top-0 flex flex-col items-stretch w-full h-screen bg-opacity-90 overflow-y-auto'>
                  <div className='flex flex-grow items-center justify-center py-4 w-full'>
                    <div
                      role='dialog'
                      tabIndex={-1}
                      data-ismodal='true'
                      className='focus:outline-none'
                      style={{ width: '45%' }}
                    >
                      <div className='bg-bg100 rounded-4xl relative py-0 px-0'>
                        <button
                          className='absolute z-30 right-3 top-3 lt-item-icon-box lt-item-box-sizing lt-item-box-bg  close-popup-practice cursor-pointer'
                          type='button'
                          onClick={() => {
                            props.setVideoModal(false);
                          }}
                        >
                          <span className=' flex items-center gap-4px'>
                            <svg
                              xmlnsXlink='http://www.w3.org/1999/xlink'
                              xmlns='http://www.w3.org/2000/svg'
                              aria-hidden='true'
                              focusable='false'
                              role='presentation'
                              className='index-StyledSvg_cls2__nHIIG index-StyledSvg_cls1__3YcLt'
                              width='24'
                              height='24'
                            >
                              <defs>
                                <symbol id='close' viewBox='0 0 24 24'>
                                  <path d='M19.707 5.707a1 1 0 0 0-1.414-1.414L12 10.586 5.707 4.293a1 1 0 1 0-1.414 1.414L10.586 12l-6.293 6.292a1 1 0 1 0 1.414 1.415L12 13.414l6.293 6.293a1 1 0 0 0 1.414-1.415L13.414 12l6.293-6.293z'></path>
                                </symbol>
                              </defs>
                              <use xlinkHref='#close' fill='#FFFFFF'></use>
                            </svg>
                          </span>
                        </button>
                        <div className='grid gap-3 grid-cols-12 p-3'>
                          <div className='col-span-12'>
                            <ReactPlayer
                              config={{
                                file: {
                                  attributes: { controlsList: 'nodownload' },
                                },
                              }}
                              className='videoFrame'
                              url={
                                props.video.video_file
                                  ? props.video.video_file
                                  : props.video.sample_file
                              }
                              light={props?.video?.thumbnail_file}
                              playing
                              controls
                            />
                            <p className='v-bs-label500 text-text500 mt-2'>
                              {props?.video?.category_name}
                            </p>
                            <h6 className='v-bs-h600 text-text900'>
                              {props?.video?.title}
                            </h6>
                            <p className='v-bs-p200 text-text500'>
                              {props?.video?.description}
                            </p>

                            <div className='mt-3'>
                              {props.edit ? (
                                <>
                                  {props.video.no_of_required_team_members >
                                    0 && (
                                    <button
                                      disabled={updating}
                                      onClick={() => {
                                        setIsUpdate(true);
                                        handleUpdate();
                                      }}
                                      className='lt-item-icon-box  box-shadow-focus btn-default-layout btn-layout-style btn-layout-border'
                                    >
                                      <span className=' flex items-center gap-4px'>
                                        {updating ? (
                                          <>
                                            <svg
                                              className=' text-center w-full  h-3 mr-3 -ml-1 text-white animate-spin'
                                              xmlns='http://www.w3.org/2000/svg'
                                              fill='none'
                                              viewBox='0 0 24 24'
                                            >
                                              <circle
                                                className='opacity-25'
                                                cx='12'
                                                cy='12'
                                                r='10'
                                                stroke='currentColor'
                                                strokeWidth='4'
                                              ></circle>
                                              <path
                                                className='opacity-75'
                                                fill='currentColor'
                                                d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                                              ></path>
                                            </svg>
                                          </>
                                        ) : (
                                          <>Update customization</>
                                        )}
                                      </span>
                                    </button>
                                  )}

                                  <button
                                    onClick={() => {
                                      setIsRemoveVideo(true);
                                      setPromptModal(true);
                                    }}
                                    className='lt-item-icon-box  box-shadow-focus btn-default-layout btn-layout-border edit-practice-btn-only-border'
                                  >
                                    <span className=' flex items-center gap-4px'>
                                      {isRemoving ? (
                                        <>
                                          <svg
                                            className=' text-center w-full  h-3 mr-3 -ml-1 text-black animate-spin text-center'
                                            xmlns='http://www.w3.org/2000/svg'
                                            fill='none'
                                            viewBox='0 0 24 24'
                                          >
                                            <circle
                                              className='opacity-25'
                                              cx='12'
                                              cy='12'
                                              r='10'
                                              stroke='currentColor'
                                              strokeWidth='4'
                                            ></circle>
                                            <path
                                              className='opacity-75'
                                              fill='currentColor'
                                              d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                                            ></path>
                                          </svg>
                                        </>
                                      ) : (
                                        <>Remove from playlist</>
                                      )}
                                    </span>
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    onClick={handleSubmit}
                                    className='lt-item-icon-box  box-shadow-focus btn-default-layout btn-layout-style btn-layout-border'
                                  >
                                    <span className=' flex items-center gap-4px'>
                                      {submitting ? (
                                        <>
                                          <svg
                                            className=' text-center w-full  h-3 mr-3 -ml-1 text-white animate-spin'
                                            xmlns='http://www.w3.org/2000/svg'
                                            fill='none'
                                            viewBox='0 0 24 24'
                                          >
                                            <circle
                                              className='opacity-25'
                                              cx='12'
                                              cy='12'
                                              r='10'
                                              stroke='currentColor'
                                              strokeWidth='4'
                                            ></circle>
                                            <path
                                              className='opacity-75'
                                              fill='currentColor'
                                              d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                                            ></path>
                                          </svg>
                                        </>
                                      ) : (
                                        <>Add to playlist</>
                                      )}
                                    </span>
                                  </button>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </LoadingOverlay>
        </Modal>
      </>

      {isRemoveVideo && (
        <PromptModal
          promptModal={promptModal}
          setPromptModal={setPromptModal}
          btnText='Remove'
          title={`Remove "${props.video.title}" from playlist?`}
          description=''
          onSuccess={removeVideoFromPlaylist}
        />
      )}
    </React.Fragment>
  );
};

export default VideoModal;
