import { useState } from 'react';
import { toast } from 'react-toastify';
import { Body, Button, Headline, useModalActions } from 'components';
import {
  deletePracticeTeamMember,
  getTeamMembers,
} from 'redux/actionCreators/practice';
import { useDispatch } from 'react-redux';

type PropsType = {
  id: number;
  name: string;
};

const DeleteMember = ({ id, name }: PropsType) => {
  const { close, clear } = useModalActions();
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const deleteMember = async () => {
    setIsLoading(true);
    await deletePracticeTeamMember(id);
    dispatch(getTeamMembers());
    setIsLoading(false);
    clear();
    toast.success(`Member was removed from the team.`);
  };

  return (
    <>
      <Headline size='lg'>Delete {name}</Headline>
      <Body
        size='regular'
        className='text-base text text-tailgrids-black mt-[16px]'
      >
        This action will permanently remove the member from the team. The videos
        assigned to the member will remain in your playlist but will be
        unassigned. Are you sure you want to proceed?
      </Body>
      <Button
        className='mt-[40px] w-full'
        isLoading={isLoading}
        onClick={deleteMember}
      >
        Delete
      </Button>
      <Button className='mt-[16px] w-full' variant='secondary' onClick={close}>
        Cancel
      </Button>
    </>
  );
};

export default DeleteMember;
