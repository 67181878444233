import { cn } from 'util/cn';

type PropsType = {
  className?: string;
};

const PlayCircle = ({ className }: PropsType) => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={cn('text-black', className)}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.9999 4.00065C9.3725 4.00065 3.99992 9.37323 3.99992 16.0007C3.99992 22.6281 9.3725 28.0006 15.9999 28.0006C22.6273 28.0006 27.9999 22.6281 27.9999 16.0007C27.9999 9.37323 22.6273 4.00065 15.9999 4.00065ZM1.33325 16.0007C1.33325 7.90047 7.89974 1.33398 15.9999 1.33398C24.1001 1.33398 30.6666 7.90047 30.6666 16.0007C30.6666 24.1008 24.1001 30.6673 15.9999 30.6673C7.89974 30.6673 1.33325 24.1008 1.33325 16.0007Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.7042 9.49175C13.1377 9.25973 13.6638 9.28516 14.0729 9.55792L22.0729 14.8913C22.4439 15.1385 22.6667 15.5548 22.6667 16.0007C22.6667 16.4465 22.4439 16.8628 22.0729 17.1101L14.0729 22.4434C13.6638 22.7161 13.1377 22.7416 12.7042 22.5096C12.2706 22.2775 12 21.8257 12 21.334V10.6673C12 10.1756 12.2706 9.72378 12.7042 9.49175ZM14.6667 13.1587V18.8426L18.9296 16.0007L14.6667 13.1587Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default PlayCircle;
