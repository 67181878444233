import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PracticeTheme from 'components/theme';
import { Body, Headline } from 'components';
import { XClose } from 'icons';
import {
  AnnouncementItem,
  AnnouncementSearch,
  AnnouncementSkeleton,
} from 'features/announcements';
import { getAnncouncements } from 'services/user';

const AnnouncementsPage = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [filteredAnnouncements, setFilteredAnnouncements] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentPage] = useState(1);
  const [per_page] = useState(20);

  const fetchAnnouncements = async () => {
    const {
      data: { results },
    } = await getAnncouncements({
      page: currentPage,
      per_page,
    });

    setAnnouncements(results);
    setFilteredAnnouncements(results);
  };

  const searchAnnouncement = (search: string) => {
    if (search) {
      const filtered = announcements.filter(
        (a) =>
          a.title.toLowerCase().includes(search.toLowerCase()) ||
          a.description.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredAnnouncements(filtered);
    } else {
      setFilteredAnnouncements(announcements);
    }
  };

  useEffect(() => {
    setLoading(true);

    const pagination = async () => {
      await fetchAnnouncements();
      setLoading(false);
      return;
    };
    pagination();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => searchAnnouncement(search), [search]);

  return (
    <PracticeTheme page='announcements'>
      <div className='px-[4px] py-[6px] w-full mb-[30px]'>
        <div className='flex items-center justify-between'>
          <div className='flex items-center gap-[12px]'>
            <Headline tag='h2' size='lg'>
              Announcements
            </Headline>
            <AnnouncementSearch setSearch={setSearch} />
          </div>
          <Link to='/dashboard'>
            <XClose className='w-[32px] h-[32px]' />
          </Link>
        </div>

        <div className='mt-[40px] space-y-[20px]'>
          {loading ? (
            Array.from({ length: 4 }, (_, index) => (
              <AnnouncementSkeleton key={index} size='lg' />
            ))
          ) : filteredAnnouncements.length > 0 ? (
            filteredAnnouncements.map(
              ({ title, description, image }, index) => (
                <div
                  className={index + 1 === announcements.length && 'pb-[24px]'}
                >
                  <AnnouncementItem
                    key={index}
                    title={title}
                    description={description}
                    src={image}
                    size='lg'
                  />
                </div>
              )
            )
          ) : (
            <Body>No Announcements found.</Body>
          )}
        </div>
      </div>
    </PracticeTheme>
  );
};

export default AnnouncementsPage;
