import { AnimatePresence, motion } from 'framer-motion';
import { XClose } from 'icons';
import { cn } from 'util/cn';
import { useModalActions, useModals } from './stores';

const Modal = () => {
  const modals = useModals();
  const { close } = useModalActions();

  const maxWidth = {
    modal: 'max-w-[462px]',
    member: 'max-w-[611px]',
    announcement: 'max-w-[739px]',
    preview: 'max-w-[816px]',
    'edit-member': 'max-w-[950px]',
    prompt: 'max-w-[611px]',
  };

  return (
    <AnimatePresence>
      {modals.length > 0 && (
        <motion.div
          className='overflow-hidden fixed inset-0 z-50 bg-black/95 md:flex items-center justify-center p-[16px] pt-[80px] md:pt-[16px] text-white'
          key={modals.length}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          onClick={close}
        >
          <div
            className={cn('w-fill', maxWidth[modals[0].type])}
            onClick={(e) => e.stopPropagation()}
          >
            <motion.div
              className={cn(
                'bg-white max-h-[calc(100vh-32px)] md:max-h-[calc(100vh-98px)] w-full overflow-y-auto rounded-[24px] text-[#212B36] relative',
                !modals[0].noPadding && 'p-[16px] md:p-[32px]'
              )}
              key={modals.length + 1}
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              transition={{ duration: 0.2, type: 'spring' }}
            >
              <button
                className='absolute top-[8px] right-[8px] md:top-[24px] md:right-[24px] p-[8px]'
                style={{ outline: 'none' }}
                onClick={close}
              >
                <XClose />
              </button>
              {modals[0].component}
            </motion.div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Modal;
