import { Search, XClose } from 'icons';
import { Dispatch, SetStateAction, useState } from 'react';

type PropsType = {
  setSearch: Dispatch<SetStateAction<string>>;
};

const AnnouncementSearch = ({ setSearch }: PropsType) => {
  const [searching, setSearching] = useState(false);

  const closeSearch = () => {
    setSearch('');
    setSearching(false);
  };
  if (searching)
    return (
      <div className='relative'>
        <div className='absolute top-1/2 -translate-y-1/2'>
          <div className='relative'>
            <input
              type='text'
              placeholder='Search'
              className='text-tailgrids-black font-karla text-lg font-bold leading-[23.76px] placeholder:text-[#979FA1] w-[400px] h-[64px] p-[16px] pr-[58px] rounded-lg border-2 border-black focus:ring-0 focus:border-black'
              onChange={(e) => setSearch(e.target.value)}
            />
            <button
              className='focus:outline-none absolute right-[16px] top-1/2 -translate-y-1/2'
              onClick={closeSearch}
            >
              <XClose className='w-[32px] h-[32px]' />
            </button>
          </div>
        </div>
      </div>
    );

  return (
    <button className='focus:outline-none' onClick={() => setSearching(true)}>
      <Search />
    </button>
  );
};

export default AnnouncementSearch;
