import React, { useEffect } from "react";
import Modal from "react-modal";
import Icon from "../../assets/img/checkCircleIcon.png";

const SubmittedModal = (props: any) => {
  useEffect(() => {
    const init = async () => {
      // check if video already included in playlist
    };

    init();
  }, [props]);

  const handlePop = async () => {
    props?.setConfirmPop(false);
  };

  return (
    <React.Fragment>
      <>
        <Modal
          isOpen={props.confirmPop}
          onRequestClose={() => props.setConfirmPop(false)}
          style={{
            overlay: {
              position: "relative",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, 0.75)",
              overflow: "hidden",
            },
          }}
        >
          <div>
            <div className="z-modal animate-fade-in bg-bg900 fixed left-0 top-0 flex flex-col items-stretch w-full h-screen bg-opacity-90 overflow-y-auto">
              <div className="flex flex-grow items-center justify-center py-4 w-full h-contact">
                <div
                  role="dialog"
                  tabIndex={-1}
                  data-ismodal="true"
                  className="focus:outline-none"
                  style={{ width: 420 }}
                >
                  <div className="bg-bg100 rounded-4xl relative py-3 px-3 flex flex-col ">
                    <div className="self-center mb-4 mt-3 ">
                      <img src={Icon} className="iconImg" alt=""/>
                    </div>
                    <div className="flex items-center justify-between w-full pb-2">
                      <p className="v-bs-h300 newColor">Request sent!</p>
                    </div>
                    <p className="text-text800 text-sm " style={{lineHeight: '20px',color: '#475467'}}>
                       Thanks for your enquiry. Our support team have been notified of this request and will be in touch soon to assist you. Please check your email for more information.
                    </p>
                    {/* <p className="text-text800 text-sm mt-2" style={{lineHeight: '20px',color: '#475467'}}>
                      Shortly, you will receive an email confirming the receipt
                      of your support ticket. This email will include a unique
                      ticket number for your reference.
                    </p> */}
                    <button
                      type="button"
                      className="update-card-vs-bs-btn self-center w-full mt-3"
                      onClick={handlePop}
                    >
                      <span className=" flex items-center gap-4px">
                        Continue
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    </React.Fragment>
  );
};

export default SubmittedModal;
