import { ActionType } from "../actionTypes/index";

const initialState = {
  now_playing: [],
  in_production: [],
  needs_customization: [],
  playlist_total: 0,
  available_total: 0,
  available_videos: [],
  categories: [],
  loading:false,
  event:null
};

const videosReducer = (state = initialState, action: any): any => {
  console.log("=== Reducer Videos ===");
  console.log(action);

  switch (action.type) {
    case ActionType.GET_VIDEOS_NOW_PLAYING:
      return {
        ...state,
        now_playing: action.payload.data.results,
        loading: false,
        event: action.type,
      };
    case ActionType.GET_VIDEOS_IN_PRODUCTION:

      return {
        ...state,
        in_production: action.payload.data.results.filter((v) => { return v.missing_questions_images === 0 && v.missing_questions_answers === 0 && v.total_customization_members === (v.no_of_required_team_members ? v.no_of_required_team_members : v.total_descriptions) } ),
        loading: false,
        event: action.type,
      };

    case ActionType.GET_VIDEOS_NEEDS_CUSTOMIZATION:
      return {
        ...state,
        needs_customization: action.payload.data.results.filter((v) => { return v.missing_questions_images > 0 || v.missing_questions_answers > 0 || v.total_customization_members !== (v.no_of_required_team_members ? v.no_of_required_team_members : v.total_descriptions) } ),
        loading: false,
        event: action.type,
      };

    case ActionType.GET_PLAYLIST_COUNTS:
      return {
        ...state,
        available_total: action.payload.data.results.available_total,
        playlist_total: action.payload.data.results.playlist_total,
        loading: false,
        event: action.type,
      };

    case ActionType.GET_VIDEOS_AVAILABLE:
      return {
        ...state,
        available_videos: action.payload.data.results,
        loading: false,
        event: action.type,
      };

    case ActionType.GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload.data.results,
        loading: false,
        event: action.type,
      };

    case ActionType.VIDEOS_REQUEST:
      return {
        ...state,
        loading:action.loading,
        event:action.event
      };

    default:
      return state;
  }
};

export default videosReducer;
