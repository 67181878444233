import axios from "util/axios";
import { ActionType } from "redux/actionTypes/index";
import { Dispatch } from "redux";
import cookie from "js-cookie";
import config from "config/config";

export function login(params: any) {
  return async function (dispatch: Dispatch) {
    try {
      const data = {
        email: params.email,
        password: params.password,
      };

      await axios
        .post(`${config.defaults.api_url}/users/login`, data, {
          method: "POST",
        })
        .then((user: any) => {
          console.log("Error: ", user.data);

          if (user.data.error) {
            dispatch({
              type: ActionType.USER_LOGIN_FAIL,
              payload: {
                message: "The Email Address or Password entered is invalid",
              },
            });
          } else {
            cookie.set("token", user.data?.results.accessToken);

            dispatch({
              type: ActionType.USER_LOGIN_SUCCESS,
              payload: user,
            });
          }
        });
    } catch (error) {
      console.log(error);

      dispatch({
        type: ActionType.USER_LOGIN_FAIL,
        payload: {
          message:
            "The Email Address or Password entered is invalid. Please try again.",
        },
      });
    }
  };
}

export function fetchCurrentUser() {
  return async function (dispatch: Dispatch) {
    try {
      await axios
        .get(`${config.defaults.api_url}/users/me`)
        .then((user: any) => {
          dispatch({
            type: ActionType.CURRENT_USER,
            payload: user,
          });
        });
    } catch (error) {
      console.log(error);

      dispatch({
        type: ActionType.CURRENT_USER_FAILED,
        payload: {
          message: "Unable to get current logged in user details",
        },
      });
    }
  };
}

export function getUserPaymentInfo(params) {
  return async function (dispatch: Dispatch) {
    try {
      await axios
        .get(`${config.defaults.api_url}/users/payment/info`, { params })
        .then((res: any) => {
          dispatch({
            type: ActionType.USER_PAYMENT_INFO,
            payload: res,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };
}

export function getUserTransactions(params) {
  return async function (dispatch: Dispatch) {
    try {
      await axios
        .get(`${config.defaults.api_url}/users/transactions`, { params })
        .then((res: any) => {
          dispatch({
            type: ActionType.USER_TRANSACTIONS,
            payload: res,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };
}

export async function changePassword(params) {
  return await axios.post(
    `${config.defaults.api_url}/users/change-password`,
    params
  );
}

export async function updateCard(params) {
  return await axios.post(
    `${config.defaults.api_url}/users/update/card`,
    params
  );
}

export async function updateSubscription(params) {
  return await axios.post(
    `${config.defaults.api_url}/users/subscription/update`,
    params
  );
}

export async function cancelSubscription(params) {
  return await axios.post(
    `${config.defaults.api_url}/users/subscription/cancel`,
    params
  );
}

export async function resumeSubscription(params) {
  return await axios.post(
    `${config.defaults.api_url}/users/subscription/resume`,
    params
  );
}

export async function sendSupportRequest(params) {
  return await axios.post(
    `${config.defaults.api_url}/users/contact/support`,
    params
  );
}

export async function subApplyCoupon(params) {
  return await axios.post(
    `${config.defaults.api_url}/users/subscription/apply/coupon`,
    params
  );
}
