import { ReactNode } from 'react';
import { cn } from 'util/cn';

type PropsType = {
  className?: string;
  size?: 'regular' | 'md' | 'lg';
  children?: ReactNode | string;
};

const Body = ({ className, size = 'md', children }: PropsType) => {
  const sizeClass = {
    regular: 'text-sm leading-[24px]',
    md: 'font-bold text-sm leading-[16.37px]',
    lg: 'font-bold text-base leading-[24px]',
  };

  return (
    <p
      className={cn(
        'font-karla text-tailgrids-gray',
        sizeClass[size],
        className
      )}
    >
      {children}
    </p>
  );
};

export default Body;
