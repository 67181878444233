import ReactPlayer from 'react-player';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { Badge, Body, Headline, Title } from 'components';
import { Spinner } from 'icons';
import { useEffect, useState } from 'react';
import { cn } from 'util/cn';

const Preview = (props) => {
  const [fetching, setFetching] = useState(false);
  const [videos, setVideos] = useState([]);
  const [playIndex, setPlayIndex] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const init = async () => {
      setFetching(true);
      if (props.state.videos.now_playing.length) {
        setFetching(true);
        const videos = props.state?.videos?.now_playing.filter((x: any) => {
          if (x.video_file != null || x.sample_file != null) {
            return x;
          }
          return [];
        });
        setVideos(videos);
        setFetching(false);
      }
      const total_videos = videos.length - 1;
      setTotal(total_videos);
      setTimeout(() => {
        setFetching(false);
      }, 5000);
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const next = () => {
    if (playIndex + 1 < videos.length) {
      setPlayIndex(playIndex + 1);
    }
  };
  const prev = () => {
    if (playIndex + 1 > 1) {
      setPlayIndex(playIndex - 1);
    }
  };
  return (
    <>
      <Headline className='mb-[24px]' size='lg'>
        Preview Playlist
      </Headline>
      {fetching ? (
        <div className='w-full h-[469px] flex items-center justify-center'>
          <Spinner />
        </div>
      ) : (
        videos.length > 0 && (
          <>
            <div className='aspect-video rounded-3xl overflow-hidden'>
              <ReactPlayer
                playing={true}
                controls={true}
                url={
                  videos[playIndex].video_file
                    ? videos[playIndex].video_file
                    : videos[playIndex].sample_file
                }
                config={{
                  file: {
                    attributes: {
                      controlsList: 'nodownload',
                    },
                  },
                }}
                onEnded={() => {
                  if (total === playIndex) {
                    setPlayIndex(0);
                  } else {
                    setPlayIndex(playIndex + 1);
                  }
                }}
                width='100%'
                height='100%'
              />
            </div>
            <div className='grid grid-cols-3 items-center mt-[16px]'>
              <Title size='lg'>{videos[playIndex].title}</Title>
              <div className='flex items-center justify-self-center'>
                <button
                  className='p-[8px] outline-none focus:outline-none'
                  onClick={prev}
                >
                  <ChevronLeftIcon
                    className={cn(
                      'w-[20px] h-[20px] text-tailgrids-blue',
                      playIndex + 1 === 1
                        ? 'text-tailgrids-gray cursor-not-allowed'
                        : 'text-tailgrids-blue'
                    )}
                  />
                </button>
                <Body>
                  {playIndex + 1} of {videos.length}
                </Body>
                <button
                  className='p-[8px] outline-none focus:outline-none'
                  onClick={next}
                >
                  <ChevronRightIcon
                    className={cn(
                      'w-[20px] h-[20px]',
                      playIndex + 1 === videos.length
                        ? 'text-tailgrids-gray cursor-not-allowed'
                        : 'text-tailgrids-blue'
                    )}
                  />
                </button>
              </div>
              <Badge className='justify-self-end'>
                {videos[playIndex].category_name}
              </Badge>
            </div>
          </>
        )
      )}
    </>
  );
};

export default Preview;
