import { useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useDispatch, useSelector } from 'react-redux';
import { Upload } from 'icons';
import {
  getOtherPhotos,
  getPracticeDetail,
  uploadPracticeLogo,
} from 'redux/actionCreators/practice';
import { RootState } from 'redux/reducers/combine';

const UploadLogo = () => {
  const state = useSelector((state: RootState) => state);
  const [uploadedLogo, setUploadedLogo] = useState(null);

  const fileTypes = ['JPG', 'PNG'];
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = async (file) => {
    setIsLoading(true);
    await uploadPracticeLogo(file);
    dispatch(getPracticeDetail());
    dispatch(getOtherPhotos());
    setIsLoading(false);
  };

  useEffect(() => {
    setUploadedLogo(
      state?.practice?.other_photos?.find(({ type }) => type === 'logo')
    );
  }, [state]);

  if (!uploadedLogo)
    return (
      <FileUploader name='file' handleChange={handleChange} types={fileTypes}>
        <div className='w-[97px] aspect-square rounded-full bg-round-dashed focus:outline-none cursor-pointer flex flex-col items-center'>
          <Upload className='mt-[25px]' />
          <span className='w-[65px] inline-block text-xs leading-[14.03px] text-center font-karla text-tailgrids-gray mt-[10.87px]'>
            {isLoading ? 'Uploading...' : 'Upload logo here'}
          </span>
        </div>
      </FileUploader>
    );
};

export default UploadLogo;
