/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PracticeTheme from "components/theme";
import DongleIcon from "assets/img/dongle.jpg";
import SmartTvIcon from "assets/img/smart-tv.jpg";
import { useSelector } from "react-redux";
import ActionType from "redux/actionTypes";
import cookie from "js-cookie";


interface RootState {
  practice: any;
}


const TvPage = () => {

  const state = useSelector((state: RootState) => state);



  return (
    <>
      <PracticeTheme page="tv"      
      > 


               <div className="tv-page-grid-wrap">
                <div className="col-span-8 col-start-3">
                  <h1 className="v-bs-h950 col-span-12 mt-5">Get Channel D on your TV</h1>
                  <p className="v-bs-h300 text-text800 col-span-12">Channel D is available as an app on Android TV and Amazon Fire TV.
                    <br/>Download the app on your device, enter your Practice ID, and press play.</p>
                </div>
                <h2 className="v-bs-h600 col-span-8 col-start-3 mb-3 mt-6">Devices</h2>
                <div className="flex flex-col col-span-4 col-start-3 items-center p-0 tv-smat-column">
                  <div className="w-full">
                    <div className="device-tv-screen">
                      <div className="device-tv-screen-content">
                      <img className="device-tv-screen-display" alt=""/></div>
                      <img alt="Smart TV" src={SmartTvIcon} className="rounded-t-large"/></div>
                  </div>
                  <h4 className="v-bs-h400 mb-1 text-center">Smart TV</h4>
                  <p className="v-bs-p200 text-text800 mb-2 px-11 text-center">You can download and play Channel D directly on any Amazon Smart TV, or Android Smart TV with Google Play.</p>
                  <div className="mb-3"><a target="_blank" href="/" className="shadow-shadow200 lt-item-icon-box  box-shadow-focus btn-layout-style btn-layout-border btn-h-36">
                    <span className=" flex items-center gap-4px">Get a Smart TV</span></a>
                  </div>
                </div>
                <div className="flex flex-col col-span-4 items-center p-0 tv-smat-column">
                  <div className="w-full">
                    <div className="device-tv-screen">
                      <div className="device-tv-screen-content">
                        <img alt="" className="device-tv-screen-display"/></div>
                        <img alt="chromecast" src={DongleIcon} className="rounded-t-large"/></div>
                  </div>
                  <h4 className="v-bs-h400 mb-1 text-center">Dongle</h4>
                  <p className="v-bs-p200 text-text800 mb-2 px-11 text-center">A dongle will work on any The Amazon Fire TV Stick or the Google Chromecast with Google TV.</p>
                  <div className="mb-3">
                    <button className="shadow-shadow200 lt-item-icon-box  box-shadow-focus btn-layout-style btn-layout-border btn-h-36">
                      <span className=" flex items-center gap-4px">See dongle options</span>
                    </button>
                  </div>
                </div>
                <h2 className="v-bs-h600 col-span-8 col-start-3 mb-3 mt-6">Instructions</h2>
                <div className="flex flex-col col-span-8 col-start-3 items-center p-3 tv-smat-column">
                  <div className="gap-12px flex flex-col w-full">
                    <div className="bg-bg200 v-bs-label500 p-2 rounded-lg">1. Make sure your device is connected to your Wifi</div>
                    <div className="bg-bg200 v-bs-label500 p-2 rounded-lg">2. In the Google Play Store/Amazon App Store, search "Channel D"</div>
                    <div className="bg-bg200 v-bs-label500 p-2 rounded-lg">3. Download and open the app, and enter your Practice ID: <span className="text-status100">{cookie.get('channel')}</span></div>
                    <div className="bg-bg200 v-bs-label500 p-2 rounded-lg">4. Press Play</div>
                  </div>
                </div>
              </div>


      </PracticeTheme>
       


    </>
  );
};

export default TvPage;
