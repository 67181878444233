export const ActionType: any = {
  // User Actions

  CURRENT_USER: 'CURRENT_USER',
  CURRENT_USER_FAILED: 'CURRENT_USER_FAILED',
  USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  USER_LOGIN_FAIL: 'USER_LOGIN_FAIL',
  USER_PAYMENT_INFO: 'USER_PAYMENT_INFO',
  USER_TRANSACTIONS: 'USER_TRANSACTIONS',

  // Practice

  GET_PRACTICE_DETAIL: 'GET_PRACTICE_DETAIL',

  GET_PRACTICE_DETAIL_ERROR: 'GET_PRACTICE_DETAIL_ERROR',

  GET_PRACTICE_PHYSICAL_ADDRESS: 'GET_PRACTICE_PHYSICAL_ADDRESS',

  GET_PRACTICE_PHYSICAL_ADDRESS_ERROR: 'GET_PRACTICE_PHYSICAL_ADDRESS_ERROR',

  GET_PRACTICE_POSTAL_ADDRESS: 'GET_PRACTICE_POSTAL_ADDRESS',

  GET_PRACTICE_POSTAL_ADDRESS_ERROR: 'GET_PRACTICE_POSTAL_ADDRESS_ERROR',

  GET_TEAM_MEMBERS: 'GET_TEAM_MEMBERS',

  GET_TEAM_MEMBERS_ERROR: 'GET_TEAM_MEMBERS_ERROR',

  GET_OTHER_PHOTOS: 'GET_OTHER_PHOTOS',

  // Playlist Videos

  GET_PLAYLIST_COUNTS: 'GET_PLAYLIST_COUNTS',

  GET_PLAYLIST_COUNTS_ERROR: 'GET_PLAYLIST_COUNTS_ERROR',

  GET_VIDEOS_NOW_PLAYING: 'GET_VIDEOS_NOW_PLAYING',

  GET_VIDEOS_NOW_PLAYING_ERROR: 'GET_VIDEOS_NOW_PLAYING_ERROR',

  GET_VIDEOS_NEEDS_CUSTOMIZATION: 'GET_VIDEOS_NEEDS_CUSTOMIZATION',

  GET_VIDEOS_NEEDS_CUSTOMIZATION_ERROR: 'GET_VIDEOS_NEEDS_CUSTOMIZATION_ERROR',

  GET_VIDEOS_IN_PRODUCTION: 'GET_VIDEOS_IN_PRODUCTION',

  GET_VIDEOS_IN_PRODUCTION_ERROR: 'GET_VIDEOS_IN_PRODUCTION_ERROR',

  GET_VIDEOS_AVAILABLE: 'GET_VIDEOS_AVAILABLE',

  GET_CATEGORIES: 'GET_CATEGORIES',

  VIDEOS_REQUEST: 'VIDEOS_REQUEST',
};

export default ActionType;
