import  axios from 'util/axios';
import config from "config/config";
import { Dispatch } from "redux";
import ActionType from "redux/actionTypes";


export function getLibraryStats() {   
  return async function (dispatch: Dispatch) {
    try {
      await axios
        axios.get(`${config.defaults.api_url}/videos/library/stats`)
       .then((res: any) => {
          console.log(res);
            dispatch({
              type: ActionType.GET_PLAYLIST_COUNTS,
              payload: res,
            });

        });
    } catch (error) {
      console.log(error);

        dispatch({
          type: ActionType.GET_PLAYLIST_COUNTS_ERROR,
          payload: {
            message: "unable to get playlist counts",
          },
        });
      
    }
  };
}

export function getLibraryVideosAvailable(params:any) {
  
  return async function (dispatch: Dispatch) {
    try {

      dispatch({
        type:ActionType.VIDEOS_REQUEST,
        loading: true,
        event: ActionType.GET_VIDEOS_AVAILABLE,
      });

      await axios
        axios.get(`${config.defaults.api_url}/videos/library/videos/available`,{ params })
       .then((res: any) => {
          console.log(res);
            dispatch({
              type: ActionType.GET_VIDEOS_AVAILABLE,
              payload: res,
            });

        });
    } catch (error) {
      console.log(error);
    }
  };
}

export function getVideoCategories() {
  return async function (dispatch: Dispatch) {
    try {
      await axios
        axios.get(`${config.defaults.api_url}/videos/categories`)
       .then((res: any) => {
          console.log(res);
            dispatch({
              type: ActionType.GET_CATEGORIES,
              payload: res,
            });

        });
    } catch (error) {
      console.log(error);
    }
  };
}

export async function getVideoQuestions(id) {

  return await axios.get(`${config.defaults.api_url}/videos/${id}/questions`);
};

export async function getVideoCustomizationQuestions(id) {

  return await axios.get(`${config.defaults.api_url}/videos/customization/questions/${id}`);
};

export async function getVideoCustomizationMembers(id) {
  
  return await axios.get(`${config.defaults.api_url}/videos/customization/members/${id}`);
};

export async function getVideoDescriptions(id) {

  return await axios.get(`${config.defaults.api_url}/videos/${id}/descriptions`);
};

export function getPlaylist(params) {
  return async function (dispatch: Dispatch) {
    try {
      await axios
        axios.get(`${config.defaults.api_url}/videos/playlist`, { params })
       .then((res: any) => {
          console.log(res);

          if (params.type === 'NOW_PLAYING') {
            
             dispatch({
              type: ActionType.GET_VIDEOS_NOW_PLAYING,
              payload: res,
            });

          } else if (params.type === 'IN_PRODUCTION') {

            dispatch({
              type: ActionType.GET_VIDEOS_IN_PRODUCTION,
              payload: res,
            });

          } else if (params.type === 'NEEDS_CUSTOMIZATION') {

            dispatch({
              type: ActionType.GET_VIDEOS_NEEDS_CUSTOMIZATION,
              payload: res,
            });
          }

        });
    } catch (error) {
      console.log(error);

      if (params.type === 'NOW_PLAYING') {
        dispatch({
          type: ActionType.GET_VIDEOS_NOW_PLAYING_ERROR,
          payload: {
            message: "Unable to get now playing videos",
          },
        });
      } else if (params.type === 'IN_PRODUCTION') {
        dispatch({
          type: ActionType.GET_VIDEOS_IN_PRODUCTION_ERROR,
          payload: {
            message: "Unable to videos in production",
          },
        });

      } else if (params.type === 'NEEDS_CUSTOMIZATION') {
        dispatch({
          type: ActionType.GET_VIDEOS_NEEDS_CUSTOMIZATION_ERROR,
          payload: {
            message: "unable to get videos needs customization",
          },
        });
      }
    }
  };
}
  


export async function addToPlaylist(params) {

  return axios.post(`${config.defaults.api_url}/videos/add/to/playlist`,params);

}

export async function updateVideo(params) {

  return axios.post(`${config.defaults.api_url}/videos/update/video`,params);
}

export async function removeFromPlaylist(id,customization_id) {

  return axios.post(`${config.defaults.api_url}/videos/remove/playlist/${id}/${customization_id}`);

}

