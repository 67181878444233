import { Field } from 'formik';
import { cn } from 'util/cn';

type PropsType = {
  id?: string;
  name?: string;
  placeholder?: string;
  className?: string;
};

const Input = ({
  id,
  name,
  placeholder = 'Type here',
  className,
}: PropsType) => {
  return (
    <Field
      className={cn(
        'font-karla text-[14px] font-bold text-black placeholder:text-tailgrids-gray flex items-center px-[16px] h-[48px] rounded-lg border-2 border-black w-full',
        className
      )}
      id={id}
      name={name}
      placeholder={placeholder}
    />
  );
};

export default Input;
