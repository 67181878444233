import card1 from "assets/img/support/card1.png";
import card2 from "assets/img/support/card2.png";
import card3 from "assets/img/support/card3.png";
import card4 from "assets/img/support/card4.png";
import card5 from "assets/img/support/card5.png";
import card6 from "assets/img/support/card6.png";
import card7 from "assets/img/support/card7.png";
import card8 from "assets/img/support/card8.png";

export const supportImages = [
  {
    title: "How to upload your logo",
    img: card1,
    link:'https://help.channeld.com/b/upload-logo',
  },

  {
    title: "How to add team members",
    img: card2,
    link: 'https://help.channeld.com/b/add-members'
  },

  {
    title: "How to create a playlist",
    img: card3,
    link: 'https://help.channeld.com/b/Create-playlist'
  },

  {
    title: "How to connect a device",
    img: card4,
    link: 'https://help.channeld.com/b/connect-device',
  },

  {
    title: "How custom videos work",
    img: card5,
    link: 'https://help.channeld.com/b/how-custom-videos-work',
  },

  {
    title: "Streaming on Google Chromecast",
    img: card6,
    link: 'https://help.channeld.com/b/streaming-chromecast',
  },

  {
    title: "Streaming on Amazon Firestick",
    img: card7,
    link: 'https://help.channeld.com/b/streaming-firestick',
  },

  {
    title: "Streaming on URL",
    img: card8,
    link:'https://help.channeld.com/b/streaming-firestick-2076',
  },

  {
    title: "See more",
    link:null,
  },
];
