import { combineReducers } from 'redux';
import authReducer from './auth';
import practiceReducer from './practice';
import videosReducer from './videos';

const reducers = combineReducers({
    auth: authReducer,
    practice:practiceReducer,
    videos:videosReducer
});

export default reducers;
export type RootState = ReturnType<typeof reducers>;